import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { useStateContext } from '../contexts/ContextProvider';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { ToastContainer, Bounce, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { ReactComponent as BackIcon } from '../assets/icons/icon-back.svg'
import { ReactComponent as CircleStarIcon } from '../assets/icons/icon-circle-star.svg'
import { ReactComponent as CircleStarDisabledIcon } from '../assets/icons/icon-circle-star-disabled.svg'
import { ReactComponent as PencilBlackIcon } from '../assets/icons/icon-pencil-black.svg'
import { ReactComponent as DeleteIcon } from '../assets/icons/icon-delete.svg'

const MatchType = () => {

    let navigate = useNavigate();

    const { authToken } = useStateContext();
    if(!authToken) navigate('/login'); 
    const apiUrl = process.env.REACT_APP_API_URL;
    const user = jwtDecode(authToken);

    const [myMatchTypes, setMyMatchTypes] = useState([]); 
    const [pinnedList, setPinnedList] = useState([]); 
    const [unpinnedList, setUnpinnedList] = useState([]); 

    useEffect(() => {
        
        if(authToken) {
            axios.defaults.headers.common["Authorization"] = "Bearer " + authToken;
        } else {
            delete axios.defaults.headers.common["Authorization"];
        }

        axios.get(apiUrl + '/match_types_mine/'+user.id)
        .then((response) => {
            if(response.data && response.data.success == true && response.data.data.length > 0) {
                setMyMatchTypes(response.data.data);

                var pinned = response.data.data.filter((x) => x.is_pinned == true);
                setPinnedList(pinned);

                var unpinned = response.data.data.filter((x) => x.is_pinned == false);
                setUnpinnedList(unpinned);
            }
        })
		
	}, []);

    const handlePinning = (id, status) => {

        axios.put(apiUrl + '/match_types/'+id, {is_pinned: status })
        .then((response) => {
            if(response.data) {
                toast(response.data.msg, {
                    position: "top-right",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce
                });

                if(response.data.success == true) {
                    var handleItem = myMatchTypes.find((x) => x.id == id);
                    handleItem.is_pinned = status;
                    if (status == true) {
                        var pinnedListTemp = pinnedList;
                        pinnedListTemp.push(handleItem);
                        setPinnedList(pinnedListTemp);
                        var unpinnedFilter = unpinnedList.filter((x) => x.id != id);
                        setUnpinnedList(unpinnedFilter);
                    } else {
                        var unpinnedListTemp = unpinnedList;
                        unpinnedListTemp.push(handleItem);
                        setUnpinnedList(unpinnedListTemp);
                        var pinnedFilter = pinnedList.filter((x) => x.id != id);
                        setPinnedList(pinnedFilter);
                    }
                }
            }
        })
        .catch((error) => console.log('API error - ', error));

    }

    const handleDelete = (id) => {
        if(confirm("Are you sure you want to delete this match type?") == true) {
            axios.delete(apiUrl + '/match_types/'+id)
            .then((response) => {
                if(response.data) {
                    toast(response.data.msg, {
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        transition: Bounce
                    });
                }

                if(response.data.success == true) {
                    axios.get(apiUrl + '/match_types_mine/'+user.id)
                    .then((response) => {
                        if(response.data && response.data.success == true) {
                            setMyMatchTypes(response.data.data);

                            var pinned = response.data.data.filter((x) => x.is_pinned == true);
                            setPinnedList(pinned);

                            var unpinned = response.data.data.filter((x) => x.is_pinned == false);
                            setUnpinnedList(unpinned);
                        }
                    })
                }
            })
            .catch((error) => console.log('API error - ', error));
        }
    }

    return (
        <div className='m-2 mt-20 md:m-8'>
            <div className='max-w-lg m-auto text-white'>
                <button onClick={()=>navigate(-1)} className='mb-3'>
                    <BackIcon />
                </button>
                <div className="font-bold text-3xl">Custom Match Types</div>
                <div className='mt-6'>
                    <div className='relative'>
                        <div className="flex items-center gap-3">
                            <Link
                            to='/new-match-type'
                            className="bg-white text-sm text-black p-3 w-full rounded-md border-2 border-white uppercase hover:drop-shadow-xl text-center"
                            >
                                <div className='font-bold'>Create New</div>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className='mt-6'>
                    <div className='font-bold'>Pinned</div>
                    <div className='relative mt-2'>
                        <div className="grid grid-cols-2 gap-3 mt-3">
                            { 
                                pinnedList && pinnedList.length > 0 &&
                                pinnedList.map((item, index) => (
                                    <div key={`pinned-${index}`} className='relative w-full bg-white font-bold text-sm text-black p-3 rounded-md uppercase hover:drop-shadow-xl text-center'>
                                        <div className='flex items-center justify-between'>
                                            <CircleStarIcon className='cursor-pointer' onClick={() => handlePinning(item.id, false)} />
                                            <Link
                                                to={`/match-type/`+item.id}
                                                className='cursor-pointer'
                                            >
                                                <PencilBlackIcon />
                                            </Link>
                                        </div>
                                        <div className=''>{item.title}</div>
                                        <div className='flex items-center justify-end'>
                                            <DeleteIcon className='cursor-pointer' onClick={() => handleDelete(item.id)} />
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
                <div className='mt-6'>
                    <div className='font-bold'>Other</div>
                    <div className='relative mt-2'>
                        <div className="grid grid-cols-2 gap-3 mt-3">
                            { 
                                unpinnedList && unpinnedList.length > 0 &&
                                unpinnedList.map((item, index) => (
                                    <div key={`unpinned-${index}`} className='relative w-full bg-white font-bold text-sm text-black p-3 rounded-md uppercase hover:drop-shadow-xl text-center'>
                                        <div className='flex items-center justify-between'>
                                            <CircleStarDisabledIcon className='cursor-pointer' onClick={() => handlePinning(item.id, true)} />
                                            <Link
                                                to={`/match-type/`+item.id}
                                                className='cursor-pointer'
                                            >
                                                <PencilBlackIcon />
                                            </Link>
                                        </div>
                                        <div className=''>{item.title}</div>
                                        <div className='flex items-center justify-end'>
                                            <DeleteIcon className='cursor-pointer' onClick={() => handleDelete(item.id)} />
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
                <ToastContainer />
            </div>
        </div>
    )
}

export default MatchType