import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { ReactComponent as BackIcon } from '../assets/icons/icon-back.svg'
import { ReactComponent as FacebookIcon } from '../assets/icons/icon-facebook.svg'
import { ReactComponent as GoogleIcon } from '../assets/icons/icon-google.svg'
import { ReactComponent as AppleIcon } from '../assets/icons/icon-apple.svg'
import { ReactComponent as LineIcon } from '../assets/icons/icon-line.svg'
import { ReactComponent as EyeIcon } from '../assets/icons/icon-eye.svg'
import { useStateContext } from '../contexts/ContextProvider'
import axios from 'axios';

const Login = () => {

    const { setAuthToken } = useStateContext();
    const apiUrl = process.env.REACT_APP_API_URL;

    let navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState(''); 
    const [passwordView, setPasswordView] = useState(false); 

    const [emailError, setEmailError] = useState(false);
    const [passwordError, setPasswordError] = useState(false); 
    const [loginError, setLoginError] = useState(''); 
    
    const handleLogin = () => {
        if (!email || email == '') {
            setEmailError(true);
            return false;
        } else {
            setEmailError(false);
        }

        if (!password || password == '') {
            setPasswordError(true);
            return false;
        } else {
            setPasswordError(false);
        }

        axios.post(apiUrl + '/login', {
            email: email.toLowerCase(),
            password: password,
            device_token: ""
        })
        .then((response) => {
            if(response.data && response.data.success == true) {
                setLoginError('');
                setAuthToken(response.data.data.token);
            } else {
                setLoginError(response.data.msg);
            }
        })
        .catch((error) => console.log('Login error - ', error))

    }

    return (
        <div className='m-2 mt-8 md:m-8'>
            <div className='max-w-lg m-auto text-white'>
                <button onClick={()=>navigate(-1)} className='mb-3'>
                    <BackIcon />
                </button>
                <div className="font-bold text-3xl">LOGIN</div>
                <div className='mt-6'>
                    <div className='flex items-center justify-between'>
                        <div className='font-bold'>Email</div>
                    </div>
                    <div className='relative mt-2'>
                        <input className={`text-white rounded-md border border-neutral-400 h-14 w-full p-3 bg-transparent ${emailError?'border-red-600':''}`} placeholder='Your email' value={email} onChange={(event) => setEmail(event.target.value)} />
                        <div className={`text-red-600 text-sm mt-2 ${emailError?'':'hidden'}`}>This field is required.</div>
                    </div>
                </div>
                <div className='mt-6'>
                    <div className='flex items-center justify-between'>
                        <div className='font-bold'>Password</div>
                    </div>
                    <div className='relative mt-2'>
                        <input type={`${passwordView?'text':'password'}`} className={`text-white rounded-md border border-neutral-400 h-14 w-full p-3 bg-transparent ${passwordError?'border-red-600':''}`} placeholder='Password' value={password} onChange={(event) => setPassword(event.target.value)} />
                        <button 
                            className='absolute top-0 right-0 p-3 h-14 flex items-center'
                            onClick={() => setPasswordView(!passwordView)}
                        ><EyeIcon /></button>
                        <div className={`text-red-600 text-sm mt-2 ${passwordError?'':'hidden'}`}>This field is required.</div>
                    </div>
                </div>
                <div className={`text-red-600 text-sm mt-2 ${loginError?'':'hidden'}`}>{loginError}</div>
                <div className='mt-6'>
                    <button
                    onClick={() => handleLogin()}
                    className="flex items-center justify-center gap-3 bg-white text-black mb-3 px-5 py-3 w-full rounded-md border-2 border-white hover:drop-shadow-xl"
                    >
                        <div className='font-bold uppercase'>Login</div>
                    </button>
                    <div
                    className="flex items-center justify-center gap-3 text-white mt-3 mb-3"
                    >
                        <div className='text-neutral-300'>Forgot password?</div>
                        <Link to='/forgot-password'   className='font-bold uppercase'>Reset Password</Link>
                    </div>
                    <div className='flex items-center justify-center gap-3 text-white my-6'>
                        <LineIcon />
                        <div className='font-bold uppercase'>OR</div>
                        <LineIcon />
                    </div>
                    <button
                    className="flex items-center justify-center gap-3 bg-[#1877F2] text-white mb-3 px-5 py-3 w-full rounded-md border-2 border-[#1877F2] hover:drop-shadow-xl"
                    >
                        <FacebookIcon />
                        <div className='font-bold uppercase'>CONTINUE WITH FACEBOOK</div>
                    </button>
                    <button
                    className="flex items-center justify-center gap-3 bg-white text-black mb-3 px-5 py-3 w-full rounded-md border-2 border-white hover:drop-shadow-xl"
                    >
                        <GoogleIcon />
                        <div className='font-bold uppercase'>CONTINUE WITH GOOGLE</div>
                    </button>
                    <button
                    className="flex items-center justify-center gap-3 bg-white text-black mb-3 px-5 py-3 w-full rounded-md border-2 border-white hover:drop-shadow-xl"
                    >
                        <AppleIcon />
                        <div className='font-bold uppercase'>CONTINUE WITH APPLE</div>
                    </button>
                    <div
                    className="flex items-center justify-center gap-3 text-white mt-6 mb-3"
                    >
                        <div className='text-neutral-300'>Don't have an account?</div>
                        <Link to='/signup'   className='font-bold uppercase'>Sign up</Link>
                    </div>
                </div>
                <div className='mt-24 text-center'>
                    <div className='text-neutral-300 text-sm'>By Money Match Technology Company</div>
                </div>
            </div>
        </div>
    )
}

export default Login