import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { ReactComponent as BackIcon } from '../assets/icons/icon-back.svg'
import { ReactComponent as EyeIcon } from '../assets/icons/icon-eye.svg'
import { useStateContext } from '../contexts/ContextProvider'
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { ToastContainer, Bounce, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ForgotPassword = () => {

    let navigate = useNavigate();
    
    const apiUrl = process.env.REACT_APP_API_URL;
    
    const { authToken } = useStateContext();

    const [email, setEmail] = useState('');
    const [isResetPasswordEmailSent, setResetPasswordEmailSent] = useState(false);
    
    const [emailError, setEmailError] = useState(false);

    const handleSendEmail = () => {
        if (!email || email == '') {
            setEmailError(true);
            return false;
        } else {
            setEmailError(false);
        }

        axios.post(apiUrl + '/forgot', {
            email: email,
        })
        .then((response) => {
            if(response.data) {
                toast(response.data.msg, {
                    position: "top-right",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce
                });

                if(response.data.success == true) {
                    setEmail('');
                    setResetPasswordEmailSent(true);
                }
            }
        })
        .catch((error) => console.log('API error - ', error))

    }

    return (
        <div className='m-2 mt-8 md:m-8'>
            <div className='max-w-lg m-auto text-white'>
                <button onClick={()=>navigate(-1)} className='mb-3'>
                    <BackIcon />
                </button>
                {
                    isResetPasswordEmailSent == false && 
                    <>
                        <div className="font-bold text-3xl">RESET PASSWORD</div>
                        <div className='mt-6'>
                            <div className='flex items-center justify-between'>
                                <div className='font-bold'>Registered Email</div>
                            </div>
                            <div className='relative mt-2'>
                                <input className={`text-white rounded-md border border-neutral-400 h-14 w-full p-3 bg-transparent ${emailError?'border-red-600':''}`} placeholder='Email' value={email} onChange={(event) => setEmail(event.target.value)} />
                                <div className={`text-red-600 text-sm mt-2 ${emailError?'':'hidden'}`}>This field is required.</div>
                            </div>
                        </div>
                        <div className='mt-24'>
                            <button onClick={() => handleSendEmail()}
                            className="flex items-center justify-center gap-3 bg-white text-black mb-3 px-5 py-3 w-full rounded-md border-2 border-white hover:drop-shadow-xl"
                            >
                                <div className='font-bold uppercase'>Send recovery email</div>
                            </button>
                        </div>
                    </>
                }
                {
                    isResetPasswordEmailSent == true && 
                    <>
                        <div className="font-bold text-3xl mt-12">We've sent a recovery link to your email</div>
                        <div className='text-sm text-neutral-400 mt-4'>
                            If your email is registered with MoneyMatch, you will receive an email with a link to reset your password.
                        </div>
                        
                        <div className='mt-20'>
                            <Link to='/login'  
                            className="flex items-center justify-center gap-3 bg-white text-black mb-3 px-5 py-3 w-full rounded-md border-2 border-white hover:drop-shadow-xl"
                            >
                                <div className='font-bold uppercase'>ACCEPT</div>
                            </Link>
                        </div>
                    </>
                }
                <ToastContainer />
            </div>
        </div>
    )
}

export default ForgotPassword