import React, { useEffect} from 'react'
import { Footer, Navbar, Sidebar, ThemeSettings, PrivateRoute, AnonymousRoute } from './components'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { TooltipComponent } from '@syncfusion/ej2-react-popups'
import { useStateContext } from './contexts/ContextProvider'
import { 
  Matches, 
  MatchDetail,
  Friends, 
  FriendDetail, 
  Feeds, 
  Wallet, 
  Withdraw, 
  Deposit, 
  Notifications, 
  NewMatch, 
  MatchType, 
  NewMatchType,
  Settings,
  Profile,
  Password,
  Privacy,
  NotificationSetting,
  Faq,
  Contact,
  Intro,
  Login,
  Signup,
  EmailVerification,
  ForgotPassword,
  ResetPassword,
  EndMatch,
  Rate,
  Dispute,
  Propose,
  Terms,
  PrivacyPolicy,
  MatchTypeEdit
} from './pages'
import ScrollToTop from './components/ScrollToTop'

export const App = () => {
  const { currentColor, setCurrentColor, currentMode, setCurrentMode, activeMenu, themeSettings, setThemeSettings, authToken } = useStateContext()
  
  useEffect(() => {
    const currentThemeColor = localStorage.getItem('colorMode')
    const currentThemeMode = localStorage.getItem('themeMode')
    if( currentThemeColor, currentThemeMode) {
      setCurrentColor(currentThemeColor)
      setCurrentMode(currentThemeMode)
    }
  }, [])

  return (
    <div className={currentMode === 'Dark' ? 'dark' : ''}>
        <BrowserRouter>
          <ScrollToTop />
          <div className="flex relative dark:bg-main-dark-bg">
            { /*
            <div className="fixed right-4 bottom-4" style={{ zIndex:'' }}>
              <TooltipComponent
              content='Settings'
              position='Top'
              >
                <button
                type='button'
                onClick={() => setThemeSettings(true)}
                style={{ backgroundColor:currentColor, borderRadius:'50%'}}
                className='text-3xl text-white p-3 drop-shadow-xl hover:bg-light-gray'
                >
                  <FiSettings/>
                </button>
              </TooltipComponent>
            </div>
            */ }
            { authToken &&
              activeMenu ? (
                <div className="w-72 fixed sidebar dark:bg-main-bg bg-main-dark-bg z-30">
                  <Sidebar/>
                </div>
              ) : (
                <div className="w-0 dark:bg-white">
                  <Sidebar/>
                </div>
              )
            }
            <div
            className={
              activeMenu && authToken
              ? 'dark:bg-main-dark-bg bg-main-bg min-h-screen md:ml-72 w-full'
              : 'bg-main-bg dark:bg-main-dark-bg w-full min-h-screen flex-2'
            }
            > 
              { authToken &&
                <div className="fixed md:sticky top-0 bg-main-bg dark:bg-main-dark-bg navbar w-full z-20">
                  <Navbar/>
                </div>
              }
              <div>
                {themeSettings  && <ThemeSettings/>}

                <Routes>
                  {/* login/signup */}
                  <Route element={<AnonymousRoute />}>
                    <Route path='/' element={<Intro/>} />
                    <Route path='/login' element={<Login/>} />
                    <Route path='/signup' element={<Signup/>} />
                    <Route path='/email-verification' element={<EmailVerification/>} />
                    <Route path='/forgot-password' element={<ForgotPassword/>} />
                    <Route path='/reset-password/:token' element={<ResetPassword/>} />
                    <Route path='/terms' element={<Terms/>} />
                    <Route path='/privacy-policy' element={<PrivacyPolicy/>} />
                  </Route>
                  {/* dashboard */}
                  <Route element={<PrivateRoute />}>
                    <Route path='/matches' element={<Matches/>} />
                    <Route path='/matches/:id' element={<MatchDetail/>} />
                    <Route path='/matches/end/:id' element={<EndMatch/>} />
                    <Route path='/matches/rate/:id' element={<Rate/>} />
                    <Route path='/matches/dispute/:id' element={<Dispute/>} />
                    <Route path='/matches/propose/:id' element={<Propose/>} />
                    <Route path='/friends' element={<Friends/>} />
                    <Route path='/friends/:id' element={<FriendDetail/>} />
                    <Route path='/feed' element={<Feeds/>} />
                    <Route path='/wallet' element={<Wallet/>} />
                    <Route path='/wallet/withdraw' element={<Withdraw/>} />
                    <Route path='/wallet/deposit' element={<Deposit/>} />
                    <Route path='/new-match/:opponent_id?' element={<NewMatch/>} />
                    <Route path='/match-type' element={<MatchType/>} />
                    <Route path='/new-match-type' element={<NewMatchType/>} />
                    <Route path='/match-type/:id' element={<MatchTypeEdit/>} />
                    { /* <Route path='/notifications' element={<Notifications/>} /> */ }
                    <Route path='/settings' element={<Settings/>} />
                    <Route path='/settings/profile' element={<Profile/>} />
                    <Route path='/settings/password' element={<Password/>} />
                    <Route path='/settings/privacy' element={<Privacy/>} />
                    <Route path='/settings/notification' element={<NotificationSetting/>} />
                    <Route path='/settings/faq' element={<Faq/>} />
                    <Route path='/settings/contact' element={<Contact/>} />
                    
                  </Route>
                </Routes>
              </div>
              { /* <Footer/> */ }
            </div>
          </div>
        </BrowserRouter>
    </div>
  )
}
