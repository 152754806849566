import React from 'react';

const Pagination = ({ itemsPerPage, length, handlePagination, currentPage }) => {
    let paginationNumber = []

    for (let i = 1; i <= Math.ceil(length / itemsPerPage); i++) {
        paginationNumber.push(i);
    }

    return (
        <div className="flex justify-center my-8">
            {
                paginationNumber.map((pageNumber) => (
                    <button 
                        className={"min-w-8 min-h-8 rounded-full mx-1 border-[1px] border-white" + (currentPage === pageNumber ? ' bg-white text-black font-bold' : '')} 
                        key={pageNumber}
                        onClick={() => handlePagination(pageNumber)}
                    >{pageNumber}</button>
                ))
            }
        </div>
    )
}

export default Pagination;