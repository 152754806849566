import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import {Rating } from 'react-simple-star-rating'
import Popover from '@idui/react-popover'
import { useStateContext } from '../contexts/ContextProvider';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { ToastContainer, Bounce, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import { numberWithCommas, handle7DecimalsOnValue } from '../config/common'

import { ReactComponent as BackIcon } from '../assets/icons/icon-back.svg'
import { ReactComponent as StarIcon } from '../assets/icons/icon-star.svg'
import { ReactComponent as QuestionIcon } from '../assets/icons/icon-question.svg'
import { ReactComponent as AwardIcon } from '../assets/icons/icon-award.svg'
import { ReactComponent as ShareIcon } from '../assets/icons/icon-share.svg'
import { ReactComponent as UserIcon } from '../assets/icons/icon-user.svg'
import dashed from '../assets/images/dashed.png';

const MatchDetail = () => {

    let navigate = useNavigate();

    const { authToken } = useStateContext();
    if(!authToken) navigate('/login'); 
    const apiUrl = process.env.REACT_APP_API_URL;
    const user = jwtDecode(authToken);

    const [matchData, setMatchData] = useState({}); 
    const [status, setStatus] = useState(); 
    const [fromUser, setFromUser] = useState(0); 
    const [isFullDescription, setFullDescription] = useState(false); 
    const [isPermitted, setPermitted] = useState(false); 

    const params = useParams();

    useEffect(() => {
        
        if(authToken) {
            axios.defaults.headers.common["Authorization"] = "Bearer " + authToken;
        } else {
            delete axios.defaults.headers.common["Authorization"];
        }

        axios.get(apiUrl + '/matches/'+params['id'])
        .then((response) => {
            if(response.data && response.data.success == true) {
                setMatchData(response.data.data);
                setStatus(response.data.data.status);
                if (response.data.data.from_user_id == user.id) {
                    setFromUser(1);
                    setPermitted(true);
                } else if (response.data.data.to_user_id == user.id) {
                    setFromUser(2);
                    setPermitted(true);
                }
            }
        })
        .catch((error) => console.log('API error - ', error))
        
    }, [])

    const cancelMatchRequest = (matchId) => {
        if(confirm("Are you sure you want to cancel this match request?") == true) {
            axios.post(apiUrl + '/match_cancel/'+matchId, {user_id: user.id})
            .then((response) => {
                if(response.data) {
                    toast(response.data.msg, {
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        transition: Bounce
                    });

                    if(response.data.success == true) {
                        navigate('/matches')
                    }
                }
            })
            .catch((error) => console.log('API error - ', error));
        }
    }

    const declineMatchRequest = (matchId) => {
        if(confirm("Are you sure you want to decline this match request?") == true) {
            axios.post(apiUrl + '/match_reject/'+matchId, {user_id: user.id})
            .then((response) => {
                if(response.data) {
                    toast(response.data.msg, {
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        transition: Bounce
                    });

                    if(response.data.success == true) {
                        navigate('/matches')
                    }
                }
            })
            .catch((error) => console.log('API error - ', error));
        }
    }

    const acceptMatchRequest = (matchId) => {
        if(confirm("Are you sure you want to accept this match request?") == true) {
            axios.post(apiUrl + '/match_accept/'+matchId, {user_id: user.id})
            .then((response) => {
                if(response.data) {
                    toast(response.data.msg, {
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        transition: Bounce
                    });

                    if(response.data.success == true) {
                        navigate('/matches')
                    }
                }
            })
            .catch((error) => console.log('API error - ', error));
        }
    }

    const endMatch = (matchId) => {
        if(confirm("Are you sure you want to end this match?") == true) {
            axios.post(apiUrl + '/match_end/'+matchId, {user_id: user.id})
            .then((response) => {
                if(response.data) {
                    toast(response.data.msg, {
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        transition: Bounce
                    });

                    if(response.data.success == true) {
                        navigate('/matches/rate/'+matchData.id)
                    }
                }
            })
            .catch((error) => console.log('API error - ', error));
        }
    }

    const rateMatch = (matchId) => {
        navigate('/matches/rate/'+matchId);
    }

    return (
        <div className='m-2 mt-20 md:m-8'>
            <div className='max-w-lg m-auto text-white'>
                <div className='flex items-center justify-between'>
                    <button onClick={()=>navigate(-1)} className='mb-3'>
                        <BackIcon />
                    </button>
                    { isPermitted && <div className='text-neutral-400 uppercase text-sm'>{ matchData.is_public ? "Public" : "Private" }</div> }
                </div>
                {
                    !isPermitted &&
                    <div className='text-center'>
                        <div className="relative px-5 py-3 pt-5 text-white bg-neutral-800 rounded-xl">
                            <div className="font-bold text-lg text-center">You don't have permission to review the match details.</div>
                        </div>
                    </div>
                }
                {
                    isPermitted && status == 0 &&
                    <div className='text-center'>
                        <div className='flex gap-2 bg-white rounded-t-xl border-none justify-between'>
                            <div className="text-left text-lg leading-tight italic font-bold uppercase text-black px-4 pt-2 pb-1">{matchData.title}</div>
                            <div className="basis-24 max-w-24 flex-1 flex shrink-0 card-item-type">{ matchData.match_type && matchData.match_type.image && <img src={matchData.match_type.image} className='w-full object-cover rounded-tr-xl' /> }</div>
                        </div>
                        <div className="relative px-5 py-3 pb-5 text-white bg-neutral-800">
                            <div className="flex items-center justify-center">
                                <div className="w-1/3 flex flex-col items-center">
                                <div className="mb-4 relative flex justify-center">
                                    { matchData.from_user?.picture ? <img className="border-4 rounded-md object-cover w-20 h-20" src={matchData.from_user?.picture} /> : <UserIcon className="w-20 h-20" /> }
                                    <div className="bg-white text-black flex w-fit absolute rounded flex items-center m-auto bottom-0 p-1 gap-1 card-item-cred">
                                        <StarIcon />
                                        <div className="leading-none text-sm font-bold">{matchData.from_user_profile?.match_cred?matchData.from_user_profile?.match_cred:'N/A'}</div>
                                    </div>
                                </div>
                                <div className="font-bold">{matchData.from_user?.full_name}</div>
                                <div className="text-neutral-500 leading-none">{matchData.from_user?.username}</div>
                            </div>
                            <div className="w-1/3">
                                <div className="font-bold italic text-3xl text-center">VS</div>
                            </div>
                            <div className="w-1/3 flex flex-col items-center">
                                <div className="mb-4 relative flex justify-center">
                                    { matchData.to_user?.picture ? <img className="border-4 rounded-md object-cover w-20 h-20" src={matchData.to_user?.picture} /> : <UserIcon className="w-20 h-20" /> }
                                    <div className="bg-white text-black flex w-fit absolute rounded flex items-center m-auto bottom-0 p-1 gap-1 card-item-cred">
                                    <StarIcon />
                                    <div className="leading-none text-sm font-bold">{matchData.to_user_profile?.match_cred?matchData.to_user_profile?.match_cred:'N/A'}</div>
                                    </div>
                                </div>
                                <div className="font-bold">{matchData.to_user?.full_name}</div>
                                <div className="text-neutral-500 leading-none">{matchData.to_user?.username}</div>
                                </div>
                            </div>
                            <div className="flex items-center mt-3">
                                <div className="w-1/3 bg-black rounded p-1 text-center">
                                <div className="font-bold text-lg">{matchData.from_user_amount} ETH</div>
                                <div className="italic text-neutral-400 text-xs">EXPOSURE</div>
                                </div>
                                <div className="w-1/3 px-4">
                                <div className="bg-neutral-900 rounded p-1 text-center">
                                    <div className="font-bold text-lg">{matchData.multiplier}x</div>
                                    <div className="italic text-neutral-400 text-xs">MULTIPLIER</div>
                                </div>
                                </div>
                                <div className="w-1/3 bg-black rounded p-1 text-center">
                                <div className="font-bold text-lg ">{matchData.to_user_amount} ETH</div>
                                <div className="italic text-neutral-400 text-xs">EXPOSURE</div>
                                </div>
                            </div>
                            <div className='absolute bottom-[-10px] left-[-9px] flex items-center justify-between gap-1 w-[calc(100%+18px)] z-10'>
                                <div className='basis-5 w-5 h-5 rounded-full bg-black'></div>
                                <img className="w-10/12 lg:w-11/12" src={dashed} />
                                <div className='basis-5 w-5 h-5 rounded-full bg-black'></div>
                            </div>
                        </div>
                        <div className="relative px-5 py-3 pt-5 text-white bg-neutral-800 rounded-b-xl">
                            <div className="font-bold text-xl text-center uppercase">Match summary</div>
                            <div className='mt-5'>
                                <div className='flex items-center justify-between mb-2'>
                                    <div className='text-neutral-300'>Exposure</div>
                                    <div className='font-bold'>{matchData.from_user_amount} ETH</div>
                                </div>
                                <div className='flex items-center justify-between mb-2'>
                                    <div className='text-neutral-300'>Multiplier</div>
                                    <div className='font-bold'>{matchData.multiplier}x</div>
                                </div>
                                <div className='flex items-center justify-between mb-2'>
                                    <div className='text-neutral-300'>Match Fee</div>
                                    <div className='font-bold'>{matchData.from_user_amount * 0.03 + matchData.to_user_amount * 0.03} ETH</div>
                                </div>
                                <div className='flex items-center justify-between mb-2'>
                                    <div className='text-neutral-300'>Total Potential</div>
                                    <div className='font-bold'>{matchData.from_user_amount * 0.97 + matchData.to_user_amount * 0.97} ETH</div>
                                </div>
                            </div>
                            <div className="font-bold text-xl text-center uppercase my-5">Match description</div>
                            <div className={`text-left text-neutral-300 leading-5 ${isFullDescription?'':'h-24 overflow-hidden'}`}>
                                {matchData.message}
                            </div>
                            { !isFullDescription && <button className='uppercase text-xs font-bold my-3' onClick={() => {setFullDescription(true)}}>READ FULL DESCRIPTION</button>}
                            <div className='text-neutral-400 leading-5 text-sm my-8'>
                                Deposits will be held in secure escrow until confirmed termination of the match by both parties with the same outcome.
                            </div>
                            {
                                fromUser == 1 &&
                                <div className="flex items-center">
                                    <button onClick={() => cancelMatchRequest(matchData.id)}
                                    className="bg-white text-black font-bold p-3 w-full rounded-md border-2 border-white uppercase hover:drop-shadow-xl"
                                    >
                                        Cancel match request
                                    </button>
                                </div>
                            }
                            {
                                fromUser == 2 &&
                                <div className="flex items-center gap-2">
                                    <button onClick={() => declineMatchRequest(matchData.id)}
                                    className="text-white font-bold p-3 w-full rounded-md border-2 border-white uppercase hover:drop-shadow-xl hover:bg-black hover:border-black"
                                    >
                                        Decline
                                    </button>
                                    <button onClick={() => acceptMatchRequest(matchData.id)}
                                    className="bg-white text-black font-bold p-3 w-full rounded-md border-2 border-white uppercase hover:drop-shadow-xl hover:bg-black hover:text-white hover:border-black"
                                    >
                                        Accept
                                    </button>
                                </div>
                            }
                        </div>
                    </div>
                }
                {
                    isPermitted && status == 1 &&
                    <div className='text-center'>
                        <div className='flex gap-2 bg-white rounded-t-xl border-none justify-between'>
                            <div className="text-left text-lg leading-tight italic font-bold uppercase text-black px-4 pt-2 pb-1">{matchData.title}</div>
                            <div className="basis-24 max-w-24 flex-1 flex shrink-0 card-item-type">{ matchData.match_type && matchData.match_type.image && <img src={matchData.match_type.image} className='w-full object-cover rounded-tr-xl' /> }</div>
                        </div>
                        <div className="relative px-5 py-3 pb-5 text-white bg-neutral-800">
                            <div className="flex items-center justify-center">
                                <div className="w-1/3 flex flex-col items-center">
                                <div className="mb-4 relative flex justify-center">
                                    { matchData.from_user?.picture ? <img className="border-4 rounded-md object-cover w-20 h-20" src={matchData.from_user?.picture} /> : <UserIcon className="w-20 h-20" /> }
                                    <div className="bg-white text-black flex w-fit absolute rounded flex items-center m-auto bottom-0 p-1 gap-1 card-item-cred">
                                        <StarIcon />
                                        <div className="leading-none text-sm font-bold">{matchData.from_user_profile?.match_cred?matchData.from_user_profile?.match_cred:'N/A'}</div>
                                    </div>
                                </div>
                                <div className="font-bold">{matchData.from_user?.full_name}</div>
                                <div className="text-neutral-500 leading-none">{matchData.from_user?.username}</div>
                            </div>
                            <div className="w-1/3">
                                <div className="font-bold italic text-3xl text-center">VS</div>
                                { /*
                                <div className="flex items-center justify-center gap-2">
                                    <div className='w-2 h-2 rounded-full bg-[#FF4B00]'></div>
                                    <div className="font-medium">119:59:32</div>
                                </div>
                                */ }
                            </div>
                            <div className="w-1/3 flex flex-col items-center">
                                <div className="mb-4 relative flex justify-center">
                                    { matchData.to_user?.picture ? <img className="border-4 rounded-md object-cover w-20 h-20" src={matchData.to_user?.picture} /> : <UserIcon className="w-20 h-20" /> }
                                    <div className="bg-white text-black flex w-fit absolute rounded flex items-center m-auto bottom-0 p-1 gap-1 card-item-cred">
                                    <StarIcon />
                                    <div className="leading-none text-sm font-bold">{matchData.to_user_profile?.match_cred?matchData.to_user_profile?.match_cred:'N/A'}</div>
                                    </div>
                                </div>
                                <div className="font-bold">{matchData.to_user?.full_name}</div>
                                <div className="text-neutral-500 leading-none">{matchData.to_user?.username}</div>
                                </div>
                            </div>
                            <div className="flex items-center mt-3">
                                <div className="w-1/3 bg-black rounded p-1 text-center">
                                <div className="font-bold text-lg">{matchData.from_user_amount} ETH</div>
                                <div className="italic text-neutral-400 text-xs">EXPOSURE</div>
                                </div>
                                <div className="w-1/3 px-4">
                                <div className="bg-neutral-900 rounded p-1 text-center">
                                    <div className="font-bold text-lg">{matchData.multiplier}x</div>
                                    <div className="italic text-neutral-400 text-xs">MULTIPLIER</div>
                                </div>
                                </div>
                                <div className="w-1/3 bg-black rounded p-1 text-center">
                                <div className="font-bold text-lg ">{matchData.to_user_amount} ETH</div>
                                <div className="italic text-neutral-400 text-xs">EXPOSURE</div>
                                </div>
                            </div>
                            <div className='absolute bottom-[-10px] left-[-9px] flex items-center justify-between gap-1 w-[calc(100%+18px)] z-10'>
                                <div className='basis-5 w-5 h-5 rounded-full bg-black'></div>
                                <img className="w-10/12 lg:w-11/12" src={dashed} />
                                <div className='basis-5 w-5 h-5 rounded-full bg-black'></div>
                            </div>
                        </div>
                        <div className="relative px-5 py-3 pt-5 text-white bg-neutral-800 rounded-b-xl">
                            <div className="font-bold text-xl text-center uppercase">Match summary</div>
                            <div className='mt-5'>
                                <div className='flex items-center justify-between mb-2'>
                                    <div className='text-neutral-300'>Exposure</div>
                                    <div className='font-bold'>{matchData.from_user_amount} ETH</div>
                                </div>
                                <div className='flex items-center justify-between mb-2'>
                                    <div className='text-neutral-300'>Multiplier</div>
                                    <div className='font-bold'>{matchData.multiplier}x</div>
                                </div>
                                <div className='flex items-center justify-between mb-2'>
                                    <div className='text-neutral-300'>Match Fee</div>
                                    <div className='font-bold'>{matchData.from_user_amount * 0.03 + matchData.to_user_amount * 0.03} ETH</div>
                                </div>
                                <div className='flex items-center justify-between mb-2'>
                                    <div className='text-neutral-300'>Total Potential</div>
                                    <div className='font-bold'>{matchData.from_user_amount * 0.97 + matchData.to_user_amount * 0.97} ETH</div>
                                </div>
                            </div>
                            <div className="font-bold text-xl text-center uppercase my-5">Match description</div>
                            <div className={`text-left text-neutral-300 leading-5 ${isFullDescription?'':'h-24 overflow-hidden'}`}>
                                {matchData.message}
                            </div>
                            { !isFullDescription && <button className='uppercase text-xs font-bold my-3' onClick={() => {setFullDescription(true)}}>READ FULL DESCRIPTION</button>}
                            <div className='text-neutral-400 leading-5 text-sm my-8'>
                                Deposits will be held in secure escrow until confirmed termination of the match by both parties with the same outcome.
                            </div>
                    
                            <div className="flex items-center">
                                <Link
                                to={`/matches/end/`+matchData.id}
                                 
                                className="bg-white text-black font-bold p-3 w-full rounded-md border-2 border-white uppercase hover:drop-shadow-xl"
                                >
                                    END MATCH
                                </Link>
                            </div>
                        </div>
                    </div>
                }
                {
                    isPermitted && status == 5 &&
                    <div className='text-center bg-neutral-800 rounded-xl'>
                        <div className="pt-1 text-center font-bold text-white">
                            END MATCH REQUEST
                        </div>
                        <div className='flex gap-2 bg-white rounded-t-xl border-none justify-between'>
                            <div className="text-left text-lg leading-tight italic font-bold uppercase text-black px-4 pt-2 pb-1">{matchData.title}</div>
                            <div className="basis-24 max-w-24 flex-1 flex shrink-0 card-item-type">{ matchData.match_type && matchData.match_type.image && <img src={matchData.match_type.image} className='w-full object-cover' /> }</div>
                        </div>
                        <div className="relative px-5 py-3 pt-5 text-white">
                            <div className="font-bold text-xl text-center uppercase">MATCH OUTCOME</div>
                            { ((matchData.from_user_id == user.id && matchData.choice_to_user != null) || (matchData.to_user_id == user.id && matchData.choice_from_user != null)) &&
                                <div className={"font-bold text-[#6FFF33] text-center uppercase " + (matchData.from_user_id == user.id && matchData.choice_from_user == 1 ? 'text-[#FF1919]' : (matchData.to_user_id == user.id && matchData.choice_to_user == 0 ? 'text-[#FF1919]' : '' ))}>
                                    {
                                        matchData.from_user_id == user.id ? ( matchData.choice_to_user == 0 ? 'You Won' : ( matchData.choice_to_user == 1 ? (matchData.to_user?.full_name + ' Won') : (matchData.choice_to_user == 2 ? 'Tie' : '') ) ) : (matchData.choice_from_user == 0 ? (matchData.from_user?.full_name + ' Won') : ( matchData.choice_from_user == 1 ? 'You Won' : (matchData.choice_from_user == 2 ? 'Tie' : '' )))
                                    }
                                </div>
                            }
                            { ((matchData.from_user_id == user.id && matchData.choice_to_user == null) || (matchData.to_user_id == user.id && matchData.choice_from_user == null)) &&
                                <div className={"font-bold text-[#6FFF33] text-center uppercase " + (matchData.from_user_id == user.id && matchData.choice_from_user == 1 ? 'text-[#FF1919]' : (matchData.to_user_id == user.id && matchData.choice_to_user == 0 ? 'text-[#FF1919]' : '' ))}>
                                    You proposed: &nbsp;
                                    {
                                        matchData.from_user_id == user.id ? ( matchData.choice_from_user == 0 ? 'You Won' : ( matchData.choice_from_user == 1 ? (matchData.to_user?.full_name + ' Won') : (matchData.choice_from_user == 2 ? 'Tie' : '') ) ) : (matchData.choice_to_user == 0 ? (matchData.from_user?.full_name + ' Won') : ( matchData.choice_to_user == 1 ? 'You Won' : (matchData.choice_to_user == 2 ? 'Tie' : '' )))
                                    }
                                </div>
                            }
                            <div className='mt-5'>
                                <div className='flex items-center justify-between gap-3 mb-2'>
                                        <div className='text-neutral-300'>Initial Exposure</div>
                                        <div className='font-bold'>{ matchData.from_user_amount } ETH</div>
                                    </div>
                                    <div className='flex items-center justify-between gap-3 mb-2'>
                                        <div className='text-neutral-300'>Multiplier</div>
                                        <div className='font-bold'>{matchData.multiplier}x</div>
                                    </div>
                                    <div className='flex items-center justify-between gap-3 mb-2'>
                                        <div className='flex items-center gap-2'>
                                            <div className='text-neutral-300'>Transaction Fee</div>
                                            <Popover
                                                trigger='click'
                                                width="240px"
                                                placement='topLeft'
                                                closeOnScroll
                                                closeOnRemoteClick
                                                closeOnTab
                                                onChangeOpen={function noRefCheck(){}}
                                                onFocus={function noRefCheck(){}}
                                                content={() => (
                                                    <div className='text-sm'>
                                                       A transaction fee of <b>3.00% </b> will be assessed from the match winner. A 10.00% fee is assessed in case of disputes from both users.
                                                    </div>
                                                )}
                                                >
                                                <button className='relative'><QuestionIcon /></button>
                                            </Popover>
                                        </div>
                                        <div className='font-bold'>{ matchData.from_user_id == user.id ? Math.round(matchData.from_user_amount * 0.03 * 10000000)/10000000 : Math.round(matchData.to_user_amount * 0.03 * 10000000)/10000000 } ETH</div>
                                    </div>
                                    <div className='flex items-center justify-between gap-3 mb-2'>
                                        <div className='flex items-center gap-2'>
                                            <div className='text-neutral-300'>Potential</div>
                                            <Popover
                                                trigger='click'
                                                width="240px"
                                                placement='topLeft'
                                                closeOnScroll
                                                closeOnRemoteClick
                                                closeOnTab
                                                onChangeOpen={function noRefCheck(){}}
                                                onFocus={function noRefCheck(){}}
                                                content={() => (
                                                    <div className='text-sm'>
                                                        Potential refers to the total amount you will receive if you win the match.
                                                    </div>
                                                )}
                                                >
                                                <button className='relative'><QuestionIcon /></button>
                                            </Popover>
                                        </div>
                                        <div className='font-bold'>{matchData.from_user_amount * 0.97 + matchData.to_user_amount * 0.97} ETH</div>
                                    </div>
                                    { /*
                                    <div className='flex items-center justify-between gap-3'>
                                        <div className='flex items-center gap-2'>
                                            <div className='text-neutral-300'>Total Received</div>
                                            <Popover
                                                trigger='click'
                                                width="240px"
                                                placement='topLeft'
                                                closeOnScroll
                                                closeOnRemoteClick
                                                closeOnTab
                                                onChangeOpen={function noRefCheck(){}}
                                                onFocus={function noRefCheck(){}}
                                                content={() => (
                                                    <div className='text-sm'>
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                                    </div>
                                                )}
                                                >
                                                <button className='relative'><QuestionIcon /></button>
                                            </Popover>
                                        </div>
                                        { ((matchData.from_user_id == user.id && matchData.choice_to_user != null) || (matchData.to_user_id == user.id && matchData.choice_from_user != null)) &&
                                            <div className='font-bold'>
                                                { (((matchData.from_user_id == user.id && matchData.choice_to_user == 0) || (matchData.to_user_id == user.id && matchData.choice_from_user == 1)) && ((matchData.choice_from_user == matchData.choice_to_user) || (matchData.choice_from_user == null) || (matchData.choice_to_user == null))) ? <div className='font-bold'>{ Math.round((matchData.from_user_amount * 0.97 + matchData.to_user_amount * 0.97) * 10000000)/10000000 } ETH</div> : ""}
                                                { (((matchData.from_user_id == user.id && matchData.choice_to_user == 1) || (matchData.to_user_id == user.id && matchData.choice_from_user == 0)) && ((matchData.choice_from_user == matchData.choice_to_user) || (matchData.choice_from_user == null) || (matchData.choice_to_user == null))) ? <div className='font-bold'>0 ETH</div> : ""}
                                                { (matchData.from_user_id == user.id && matchData.choice_to_user == 2) ? <div className='font-bold'>{ Math.round((matchData.from_user_amount * 0.97) * 10000000)/10000000 } ETH</div> : ""}
                                                { (matchData.to_user_id == user.id && matchData.choice_from_user == 2) ? <div className='font-bold'>{ Math.round((matchData.to_user_amount * 0.97) * 10000000)/10000000 } ETH</div> : ""}
                                                { ((matchData.from_user_id == user.id) && (matchData.choice_from_user != null) && (matchData.choice_to_user != null) && (matchData.choice_from_user != matchData.choice_to_user)) ? <div className='font-bold'>{ Math.round((matchData.from_user_amount * 0.9) * 10000000)/10000000 } ETH</div> : ""}
                                                { ((matchData.to_user_id == user.id) && (matchData.choice_from_user != null) && (matchData.choice_to_user != null) && (matchData.choice_from_user != matchData.choice_to_user)) ? <div className='font-bold'>{ Math.round((matchData.to_user_amount * 0.9) * 10000000)/10000000 } ETH</div> : ""}
                                            </div>
                                        }
                                        { ((matchData.from_user_id == user.id && matchData.choice_to_user == null) || (matchData.to_user_id == user.id && matchData.choice_from_user == null)) &&
                                            <div className='font-bold'>
                                                { (((matchData.from_user_id == user.id && matchData.choice_from_user == 0) || (matchData.to_user_id == user.id && matchData.choice_to_user == 1)) && ((matchData.choice_from_user == matchData.choice_to_user) || (matchData.choice_from_user == null) || (matchData.choice_to_user == null))) ? <div className='font-bold'>{ Math.round((matchData.from_user_amount * 0.97 + matchData.to_user_amount * 0.97) * 10000000)/10000000 } ETH</div> : ""}
                                                { (((matchData.from_user_id == user.id && matchData.choice_from_user == 1) || (matchData.to_user_id == user.id && matchData.choice_to_user == 0)) && ((matchData.choice_from_user == matchData.choice_to_user) || (matchData.choice_from_user == null) || (matchData.choice_to_user == null))) ? <div className='font-bold'>0 ETH</div> : ""}
                                                { (matchData.from_user_id == user.id && matchData.choice_from_user == 2) ? <div className='font-bold'>{ Math.round((matchData.from_user_amount * 0.97) * 10000000)/10000000 } ETH</div> : ""}
                                                { (matchData.to_user_id == user.id && matchData.choice_to_user == 2) ? <div className='font-bold'>{ Math.round((matchData.to_user_amount * 0.97) * 10000000)/10000000 } ETH</div> : ""}
                                            </div>
                                        }
                                    </div>
                                    */ }
                                </div>
                                { 
                                    ( (matchData.from_user_id == user.id && matchData.choice_to_user != null) || (matchData.to_user_id == user.id && matchData.choice_from_user != null) ) &&
                                    <div className='mt-24'>
                                        <div className="flex items-center">
                                            <Link
                                            to={`/matches/dispute/`+matchData.id}
                                            
                                            className="bg-transparent text-white font-bold p-3 w-full rounded-md border-2 border-white uppercase hover:drop-shadow-xl"
                                            >
                                                DISPUTE OUTCOME
                                            </Link>
                                        </div>
                                        <div className="flex items-center mt-3">
                                            <Link
                                            to={`/matches/propose/`+matchData.id}
                                            
                                            className="bg-transparent text-white font-bold p-3 w-full rounded-md border-2 border-white uppercase hover:drop-shadow-xl"
                                            >
                                                PROPOSE DIFFERENT OUTCOME
                                            </Link>
                                        </div>
                                        <div className="flex items-center mt-3">
                                            <button onClick={() => endMatch(matchData.id)}
                                            className="bg-white text-black font-bold p-3 w-full rounded-md border-2 border-white uppercase hover:drop-shadow-xl"
                                            >
                                                Confirm & end match
                                            </button>
                                        </div>
                                    </div>
                                }
                        </div>
                    </div>
                }
                {
                    isPermitted && status == 6 &&
                    <div className='text-center'>
                        <div className="pt-1 text-center font-bold text-white rounded-t-xl bg-neutral-800">
                            COMPLETED {moment(matchData.ended).format('L')}
                        </div>
                        <div className='flex gap-2 bg-white border-none justify-between'>
                            <div className="text-left text-lg leading-tight italic font-bold uppercase text-black px-4 pt-2 pb-1">{matchData.title}</div>
                            <div className="basis-24 max-w-24 flex-1 flex shrink-0 card-item-type">{ matchData.match_type && matchData.match_type.image && <img src={matchData.match_type.image} className='w-full object-cover rounded-tr-xl' /> }</div>
                        </div>
                        <div className="relative px-5 py-3 pb-5 text-white bg-neutral-800">
                            <div className="flex items-center justify-center">
                                <div className="w-1/3 flex flex-col items-center">
                                    { user.id != matchData.from_user?.id ? 
                                        <Link to={`/friends/`+ matchData.from_user?.id}>
                                            <div className="mb-4 relative flex justify-center">
                                                { matchData.from_user?.picture ? <img className="border-4 rounded-md object-cover w-20 h-20" src={matchData.from_user?.picture} /> : <UserIcon className="w-20 h-20" /> }
                                                { matchData.choice_from_user == 0 && matchData.choice_to_user == 0 && 
                                                    <div className="text-black flex w-fit absolute rounded flex items-center m-auto bottom-0 p-1 gap-1 card-item-cred">
                                                        <AwardIcon />
                                                    </div>
                                                }
                                                <div className="bg-white text-black flex w-fit absolute rounded flex items-center m-auto bottom-0 p-1 gap-1 card-item-cred">
                                                    <StarIcon />
                                                    <div className="leading-none text-sm font-bold">{matchData.from_user_profile?.match_cred?matchData.from_user_profile?.match_cred:'N/A'}</div>
                                                </div>
                                            </div>
                                        </Link> 
                                        : 
                                        <div className="mb-4 relative flex justify-center">
                                            { matchData.from_user?.picture ? <img className="border-4 rounded-md object-cover w-20 h-20" src={matchData.from_user?.picture} /> : <UserIcon className="w-20 h-20" /> }
                                            { matchData.choice_from_user == 0 && matchData.choice_to_user == 0 && 
                                                <div className="text-black flex w-fit absolute rounded flex items-center m-auto bottom-0 p-1 gap-1 card-item-cred">
                                                    <AwardIcon />
                                                </div>
                                            }
                                            <div className="bg-white text-black flex w-fit absolute rounded flex items-center m-auto bottom-0 p-1 gap-1 card-item-cred">
                                                <StarIcon />
                                                <div className="leading-none text-sm font-bold">{matchData.from_user_profile?.match_cred?matchData.from_user_profile?.match_cred:'N/A'}</div>
                                            </div>
                                        </div>
                                    }
                                    <div className="font-bold">{matchData.from_user?.full_name}</div>
                                    <div className="text-neutral-500 leading-none">{matchData.from_user?.username}</div>
                                    { matchData.choice_from_user == 0 && matchData.choice_to_user == 0 && <div className="bg-white text-black uppercase leading-none py-1 px-3 rounded font-bold text-sm mt-1">Winner</div> }
                                    { matchData.choice_from_user == 1 && matchData.choice_to_user == 1 && <div className="bg-white text-black uppercase leading-none py-1 px-3 rounded font-bold text-sm mt-1 opacity-0">Losser</div> }
                                </div>
                                <div className="w-1/3">
                                    <div className="font-bold italic text-3xl text-center">VS</div>
                                    { matchData.choice_from_user == 2 && matchData.choice_to_user == 2 && <div className="bg-white text-black uppercase leading-none py-1 px-3 rounded font-bold text-sm mt-8">Tie</div> }
                                    { matchData.choice_from_user != matchData.choice_to_user && <div className="bg-white text-black uppercase leading-none py-1 px-3 rounded font-bold text-sm mt-8">Dispute</div> }
                                </div>
                                <div className="w-1/3 flex flex-col items-center">
                                    { user.id != matchData.to_user?.id ? 
                                        <Link to={`/friends/`+ matchData.to_user?.id}>
                                            <div className="mb-4 relative flex justify-center">
                                                { matchData.to_user?.picture ? <img className="border-4 rounded-md object-cover w-20 h-20" src={matchData.to_user?.picture} /> : <UserIcon className="w-20 h-20" /> }
                                                { matchData.choice_from_user == 1 && matchData.choice_to_user == 1 && 
                                                    <div className="text-black flex w-fit absolute rounded flex items-center m-auto bottom-0 p-1 gap-1 card-item-cred">
                                                        <AwardIcon />
                                                    </div>
                                                }
                                                <div className="bg-white text-black flex w-fit absolute rounded flex items-center m-auto bottom-0 p-1 gap-1 card-item-cred">
                                                    <StarIcon />
                                                    <div className="leading-none text-sm font-bold">{matchData.to_user_profile?.match_cred?matchData.to_user_profile?.match_cred:'N/A'}</div>
                                                </div>
                                            </div>
                                        </Link>
                                        :
                                        <div className="mb-4 relative flex justify-center">
                                            { matchData.to_user?.picture ? <img className="border-4 rounded-md object-cover w-20 h-20" src={matchData.to_user?.picture} /> : <UserIcon className="w-20 h-20" /> }
                                            { matchData.choice_from_user == 1 && matchData.choice_to_user == 1 && 
                                                <div className="text-black flex w-fit absolute rounded flex items-center m-auto bottom-0 p-1 gap-1 card-item-cred">
                                                    <AwardIcon />
                                                </div>
                                            }
                                            <div className="bg-white text-black flex w-fit absolute rounded flex items-center m-auto bottom-0 p-1 gap-1 card-item-cred">
                                                <StarIcon />
                                                <div className="leading-none text-sm font-bold">{matchData.to_user_profile?.match_cred?matchData.to_user_profile?.match_cred:'N/A'}</div>
                                            </div>
                                        </div>
                                    }
                                    <div className="font-bold">{matchData.to_user?.full_name}</div>
                                    <div className="text-neutral-500 leading-none">{matchData.to_user?.username}</div>
                                    { matchData.choice_from_user == 1 && matchData.choice_to_user == 1 && <div className="bg-white text-black uppercase leading-none py-1 px-3 rounded font-bold text-sm mt-1">Winner</div> }
                                    { matchData.choice_from_user == 0 && matchData.choice_to_user == 0 && <div className="bg-white text-black uppercase leading-none py-1 px-3 rounded font-bold text-sm mt-1 opacity-0">Losser</div> }
                                </div>
                            </div>
                            <div className='absolute bottom-[-10px] left-[-9px] flex items-center justify-between gap-1 w-[calc(100%+18px)] z-10'>
                                <div className='basis-5 w-5 h-5 rounded-full bg-black'></div>
                                <img className="w-10/12 lg:w-11/12" src={dashed} />
                                <div className='basis-5 w-5 h-5 rounded-full bg-black'></div>
                            </div>
                        </div>
                        <div className="relative px-5 py-3 pt-5 text-white bg-neutral-800 rounded-b-xl">
                            <div className="font-bold text-xl text-center uppercase">Completed Match summary</div>
                            <div className='mt-5'>
                                <div className='flex items-center justify-between mb-2'>
                                    <div className='text-neutral-300'>Exposure</div>
                                    <div className='font-bold'>{ matchData.from_user_amount } ETH</div>
                                </div>
                                <div className='flex items-center justify-between mb-2'>
                                    <div className='text-neutral-300'>Multiplier</div>
                                    <div className='font-bold'>{ matchData.multiplier }x</div>
                                </div>
                                <div className='flex items-center justify-between mb-2'>
                                    <div className='text-neutral-300'>Match Fee</div>
                                    <div className='font-bold'>{ matchData.fee } ETH</div>
                                </div>
                                <div className='flex items-center justify-between mb-2'>
                                    <div className='text-neutral-300'>Potential</div>
                                    <div className='font-bold'>{ matchData.from_user_amount * 0.97 + matchData.to_user_amount * 0.97 } ETH</div>
                                </div>
                                <div className='flex items-center justify-between mb-2'>
                                    <div className='text-neutral-300'>Total Received</div>
                                    <div className='font-bold'>
                                        { (((matchData.from_user_id == user.id && matchData.choice_to_user == 0) || (matchData.to_user_id == user.id && matchData.choice_from_user == 1)) && ((matchData.choice_from_user == matchData.choice_to_user) || (matchData.choice_from_user == null) || (matchData.choice_to_user == null))) ? <div className='font-bold'>{ Math.round((matchData.from_user_amount * 0.97 + matchData.to_user_amount * 0.97) * 10000000)/10000000 } ETH</div> : ""}
                                        { (((matchData.from_user_id == user.id && matchData.choice_to_user == 1) || (matchData.to_user_id == user.id && matchData.choice_from_user == 0)) && ((matchData.choice_from_user == matchData.choice_to_user) || (matchData.choice_from_user == null) || (matchData.choice_to_user == null))) ? <div className='font-bold'>0 ETH</div> : ""}
                                        { (matchData.from_user_id == user.id && matchData.choice_to_user == 2) ? <div className='font-bold'>{ Math.round((matchData.from_user_amount * 0.97) * 10000000)/10000000 } ETH</div> : ""}
                                        { (matchData.to_user_id == user.id && matchData.choice_from_user == 2) ? <div className='font-bold'>{ Math.round((matchData.to_user_amount * 0.97) * 10000000)/10000000 } ETH</div> : ""}
                                        { ((matchData.from_user_id == user.id) && (matchData.choice_from_user != null) && (matchData.choice_to_user != null) && (matchData.choice_from_user != matchData.choice_to_user)) ? <div className='font-bold'>{ Math.round((matchData.from_user_amount * 0.9) * 10000000)/10000000 } ETH</div> : ""}
                                        { ((matchData.to_user_id == user.id) && (matchData.choice_from_user != null) && (matchData.choice_to_user != null) && (matchData.choice_from_user != matchData.choice_to_user)) ? <div className='font-bold'>{ Math.round((matchData.to_user_amount * 0.9) * 10000000)/10000000 } ETH</div> : ""}
                                    </div>
                                </div>
                            </div>
                            <div className={`text-left text-neutral-300 leading-5 ${isFullDescription?'':'h-0 overflow-hidden'}`}>
                                <div className='my-4'>{ matchData.message } </div>
                            </div>
                            { !isFullDescription && <button className='uppercase text-xs font-bold my-3' onClick={() => {setFullDescription(true)}}>READ FULL DESCRIPTION</button>}
                            <div className='mt-6 text-center'>
                                <div className='uppercase font-bold text-lg'>Match Rating</div>
                                <div className='mt-1 border-neutral-400 rounded py-2 px-4 bg-neutral-900 w-fit mx-auto'>
                                    <Rating initialValue={ matchData.from_user_id == user.id ? matchData.rating_to_user : matchData.rating_from_user } size={24} readonly />
                                </div>
                            </div>
                            { /*
                            <div className="flex items-center mt-12">
                                <button
                                className="bg-transparent text-white font-bold p-3 w-full rounded-md border-2 border-white uppercase hover:drop-shadow-xl flex items-center justify-center gap-4"
                                >
                                    <ShareIcon />
                                    <div>Share Summary</div>
                                </button>
                            </div>
                            */ }
                            { ((matchData.from_user_id == user.id && !matchData.rating_to_user) || (matchData.to_user_id == user.id && !matchData.rating_from_user)) &&
                                <div className="flex items-center mt-3">
                                    <button onClick={() => rateMatch(matchData.id)}
                                    className="bg-transparent text-white font-bold p-3 w-full rounded-md border-2 border-white uppercase hover:drop-shadow-xl"
                                    >
                                        Rate the match
                                    </button>
                                </div>
                            }
                            <div className="flex items-center mt-4 mb-2">
                                <Link
                                to='/matches'
                                className="bg-white text-black font-bold p-3 w-full rounded-md border-2 border-white uppercase hover:drop-shadow-xl"
                                >
                                    HOME SCREEN
                                </Link>
                            </div>
                        </div>
                    </div>
                }
                <ToastContainer />
            </div>
        </div>
    )
}

export default MatchDetail