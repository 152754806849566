import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useStateContext } from '../contexts/ContextProvider';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { ToastContainer, Bounce, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { ReactComponent as BackIcon } from '../assets/icons/icon-back.svg'

const NotificationSetting = () => {

    let navigate = useNavigate();

    const { authToken } = useStateContext();
    if(!authToken) navigate('/login'); 
    const apiUrl = process.env.REACT_APP_API_URL;
    const user = jwtDecode(authToken);

    const [requests, setRequests] = useState(true); 
    const [matches, setMatches] = useState(true); 
    const [friends, setFriends] = useState(true); 
    const [deposits, setDeposits] = useState(true); 
    const [withdrawals, setWithdrawals] = useState(true); 

    useEffect(() => {
        
        if(authToken) {
            axios.defaults.headers.common["Authorization"] = "Bearer " + authToken;
        } else {
            delete axios.defaults.headers.common["Authorization"];
        }

        axios.get(apiUrl + '/notification_settings/'+user.id)
        .then((response) => {
            if(response.data && response.data.success == true && response.data.data) {
                setRequests(response.data.data.requests);
                setMatches(response.data.data.matches);
                setFriends(response.data.data.friends);
                setDeposits(response.data.data.deposits);
                setWithdrawals(response.data.data.withdrawals);
            } else {
                axios.post(apiUrl + '/notification_settings/', {"user_id": user.id, "requests": true, "matches": true, "friends": true, "deposits": true, "withdrawals": true})
                .then((response) => {
                    if(response.data && response.data.success == true && response.data.data) {
                        setRequests(response.data.data.requests);
                        setMatches(response.data.data.matches);
                        setFriends(response.data.data.friends);
                        setDeposits(response.data.data.deposits);
                        setWithdrawals(response.data.data.withdrawals);
                    }
                })
            }
        })

	}, []);

    const handleSave = () => {
        axios.put(apiUrl + '/notification_settings/'+user.id, 
            {
                "requests": requests,
                "matches": matches,
                "friends": friends,
                "deposits": deposits,
                "withdrawals": withdrawals,
            }
        )
        .then((response) => {
            if(response.data) {
                toast(response.data.msg, {
                    position: "top-right",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce
                });
            }
        })
        .catch((error) => console.log('API error - ', error));
    }
    
    return (
        <div className='m-2 mt-20 md:m-8'>
            <div className='max-w-lg m-auto text-white'>
                <button onClick={()=>navigate(-1)} className='mb-3'>
                    <BackIcon />
                </button>
                <div className="font-bold text-3xl">Notification Settings</div>
                <div className='mt-6'>
                    <div className='flex items-center justify-between gap-3'>
                        <div className=''>
                            <div className='font-bold'>Match requests</div>
                            <div className='text-sm'>Receive emails notifying you by email of any new match requests.</div>
                        </div>
                        <select className='text-white font-bold border-none h-10 p-2 pr-10 text-right bg-transparent' value={requests} onChange={(e) => setRequests(e.target.value)}>
                            <option value={true} className='text-black'>YES</option>
                            <option value={false} className='text-black'>NO</option>
                        </select>
                    </div>
                </div>
                <div className='mt-6'>
                    <div className='flex items-center justify-between gap-3'>
                        <div className=''>
                            <div className='font-bold'>Matches</div>
                            <div className='text-sm'>Receive emails notifying you by email of updates in the status of your match.</div>
                        </div>
                        <select className='text-white font-bold border-none h-10 p-2 pr-10 text-right bg-transparent' value={matches} onChange={(e) => setMatches(e.target.value)}>
                            <option value={true} className='text-black'>YES</option>
                            <option value={false} className='text-black'>NO</option>
                        </select>
                    </div>
                </div>
                <div className='mt-6'>
                    <div className='flex items-center justify-between gap-3'>
                        <div className=''>
                            <div className='font-bold'>Friends</div>
                            <div className='text-sm'>Receive emails notifying you by email of any new friend requests.</div>
                        </div>
                        <select className='text-white font-bold border-none h-10 p-2 pr-10 text-right bg-transparent' value={friends} onChange={(e) => setFriends(e.target.value)}>
                            <option value={true} className='text-black'>YES</option>
                            <option value={false} className='text-black'>NO</option>
                        </select>
                    </div>
                </div>
                <div className='mt-6'>
                    <div className='flex items-center justify-between gap-3'>
                        <div className=''>
                            <div className='font-bold'>Deposits</div>
                            <div className='text-sm'>Receive email confirmation when your funds have been transferred successfully.</div>
                        </div>
                        <select className='text-white font-bold border-none h-10 p-2 pr-10 text-right bg-transparent' value={deposits} onChange={(e) => setDeposits(e.target.value)}>
                            <option value={true} className='text-black'>YES</option>
                            <option value={false} className='text-black'>NO</option>
                        </select>
                    </div>
                </div>
                <div className='mt-6'>
                    <div className='flex items-center justify-between gap-3'>
                        <div className=''>
                            <div className='font-bold'>Withdrawals</div>
                            <div className='text-sm'>Receive email confirmation when your funds have been transferred successfully.</div>
                        </div>
                        <select className='text-white font-bold border-none h-10 p-2 pr-10 text-right bg-transparent' value={withdrawals} onChange={(e) => setWithdrawals(e.target.value)}>
                            <option value={true} className='text-black'>YES</option>
                            <option value={false} className='text-black'>NO</option>
                        </select>
                    </div>
                </div>
                <div className='mt-8'>
                    <div className="flex items-center gap-3">
                        <button onClick={() => handleSave()}
                        className="bg-white text-sm text-black p-3 w-full rounded-md border-2 border-white uppercase hover:drop-shadow-xl text-center"
                        >
                            <div className='font-bold'>Save Changes</div>
                        </button>
                    </div>
                </div>
                <ToastContainer />
            </div>
        </div>
    )
}

export default NotificationSetting