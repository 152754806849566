import axios from "axios"
import { createContext, useContext, useEffect, useState } from "react"
const StateContext = createContext()

const initialState = {
    chat: false,
    cart: false,
    userProfile:false,
    notifications:false
}

export const ContextProvider = ({ children }) => {
    const [screenSize, setScreenSize] = useState(undefined)
    const [currentColor, setCurrentColor] = useState('#000000')
    const [currentMode, setCurrentMode] = useState('Dark')
    const [themeSettings, setThemeSettings] = useState(false)
    const [activeMenu, setActiveMenu] = useState(true)
    const [isClicked, setIsClicked] = useState(initialState)
    const [authToken, setAuthToken_] = useState(localStorage.getItem("authToken"))

    const setColor = (color) => {
        setCurrentColor(color)
        localStorage.setItem('colorMode', color)
    }

    const setMode = (e) => {
        setCurrentMode(e.target.value)
        localStorage.setItem('themeMode', e.target.value)
    }

    const setAuthToken = (newToken) => {
        setAuthToken_(newToken);
    }

    useEffect(() => {
        if(authToken) {
            axios.defaults.headers.common["Authorization"] = "Bearer " + authToken;
            localStorage.setItem('authToken', authToken);
        } else {
            delete axios.defaults.headers.common["Authorization"];
            localStorage.removeItem('authToken');
        }
    }, [authToken]);

    const handleClick = (clicked) => setIsClicked({ ...initialState, [clicked]: true })

    return (
        <StateContext.Provider value={{ screenSize, setScreenSize, currentColor, setCurrentColor, currentMode, setCurrentMode, themeSettings, setThemeSettings, activeMenu, setActiveMenu, isClicked, setIsClicked, setColor, setMode, handleClick, authToken, setAuthToken, initialState }}>
            {children}
        </StateContext.Provider>
    )
}

export const useStateContext = () => useContext(StateContext)