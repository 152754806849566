import React, { useState, useEffect } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.module.css"
import { ReactComponent as BackIcon } from '../assets/icons/icon-back.svg'
import { ReactComponent as EyeIcon } from '../assets/icons/icon-eye.svg'
import axios from 'axios';

const Signup = () => {

    let navigate = useNavigate();
    const apiUrl = process.env.REACT_APP_API_URL;

    const [fullName, setFullName] = useState('');
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState(''); 
    const [repassword, setRepassword] = useState(''); 
    // verification code
    const [verificationCode, setVerificationCode] = useState('');
    const [verificationCodeView, setVerificationCodeView] = useState(false)
    const [verificationCodeError, setVerificationCodeError] = useState(false);
    //
    const [passwordView, setPasswordView] = useState(false); 
    const [repasswordView, setRepasswordView] = useState(false); 
    const [dob, setDOB] = useState(''); 
    const [terms, setTerms] = useState(true); 

    const [fullNameError, setFullNameError] = useState(false);
    const [usernameError, setUsernameError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [passwordError, setPasswordError] = useState(false); 
    const [repasswordError, setRepasswordError] = useState(false); 
    const [passwordNotMatchError, setPasswordNotMatchError] = useState(false); 
    const [dobError, setDOBError] = useState(false); 
    const [termsError, setTermsError] = useState(false); 



    const handleSignup = () => {
        if (!fullName || fullName == '') {
            setFullNameError(true);
            return false;
        } else {
            setFullNameError(false);
        }

        if (!username || username == '') {
            setUsernameError(true);
            return false;
        } else {
            setUsernameError(false);
        }

        if (!email || email == '') {
            setEmailError(true);
            return false;
        } else {
            setEmailError(false);
        }

        if (!dob || dob == '') {
            setDOBError(true);
            return false;
        } else {
            setDOBError(false);
        }

        if (!password || password == '') {
            setPasswordError(true);
            return false;
        } else {
            setPasswordError(false);
        }

        if (!repassword || repassword == '') {
            setRepasswordError(true);
            return false;
        } else {
            setRepasswordError(false);
        }

        if (password != repassword) {
            setPasswordNotMatchError(true);
            return false;
        } else {
            setPasswordNotMatchError(false);
        }

        // handle verification code
        if (verificationCode != 'MM2024') {
            setVerificationCodeError(true);
            return false;
        }
        else {
            setVerificationCodeError(false);
        }
        
        if (!terms || terms == '') {
            setTermsError(true);
            return false;
        } else {
            setTermsError(false);
        }

        var pictureUrl = '/avatars/';
        var randomNumber = Math.floor(Math.random() * 220) + 1;
        pictureUrl = pictureUrl + randomNumber + '.jpg';
        
        axios.post(apiUrl + '/register', {
            full_name: fullName,
            username: username,
            email: email.toLowerCase(),
            password: password,
            birth_date: dob,
            last_login_token: "",
            device_token: "",
            picture: pictureUrl
        })
        .then((response) => {
            if(response.data && response.data.success == true) navigate('/email-verification'); 
        })
        .catch((error) => console.log('Signup error - ', error))

    }

    return (
        <div className='m-2 mt-8 md:m-8'>
            <div className='max-w-lg m-auto text-white'>
                <button onClick={()=>navigate(-1)} className='mb-3'>
                    <BackIcon />
                </button>
                <div className="font-bold text-3xl">CREATE ACCOUNT</div>
                <div className='mt-6'>
                    <div className='flex items-center justify-between'>
                        <div className='font-bold'>Full Name</div>
                    </div>
                    <div className='relative mt-2'>
                        <input className={`text-white rounded-md border border-neutral-400 h-14 w-full p-3 bg-transparent ${fullNameError?'border-red-600':''}`} placeholder='Your name' value={fullName} onChange={(event) => setFullName(event.target.value)} />
                        <div className={`text-red-600 text-sm mt-2 ${fullNameError?'':'hidden'}`}>This field is required.</div>
                    </div>
                </div>
                <div className='mt-6'>
                    <div className='flex items-center justify-between'>
                        <div className='font-bold'>Username</div>
                    </div>
                    <div className='relative mt-2'>
                        <input className={`text-white rounded-md border border-neutral-400 h-14 w-full p-3 bg-transparent ${usernameError?'border-red-600':''}`} placeholder='Username' value={username} onChange={(event) => setUsername(event.target.value)} />
                        <div className={`text-red-600 text-sm mt-2 ${usernameError?'':'hidden'}`}>This field is required.</div>
                    </div>
                </div>
                <div className='mt-6'>
                    <div className='flex items-center justify-between'>
                        <div className='font-bold'>Email</div>
                    </div>
                    <div className='relative mt-2'>
                        <input className={`text-white rounded-md border border-neutral-400 h-14 w-full p-3 bg-transparent ${emailError?'border-red-600':''}`} placeholder='Your email' value={email} onChange={(event) => setEmail(event.target.value)} />
                        <div className={`text-red-600 text-sm mt-2 ${emailError?'':'hidden'}`}>This field is required.</div>
                    </div>
                </div>
                <div className='mt-6'>
                    <div className='flex items-center justify-between'>
                        <div className='font-bold'>Date of Birth (21+)</div>
                    </div>
                    <div className='relative mt-2'>
                        { /*
                        <input className={`text-white rounded-md border border-neutral-400 h-14 w-full p-3 bg-transparent ${fullNameError?'border-red-600':''}`} placeholder='Date of Birth' value={dob} onChange={(event) => setDOB(event.target.value)} />
                        */ }
                        <DatePicker placeholderText="MM / DD / YYYY" selected={dob} onChange={(date) => setDOB(date)} className={`text-white rounded-md border border-neutral-400 h-14 w-full p-3 bg-transparent ${dobError?'border-red-600':''}`} />
                        <div className={`text-red-600 text-sm mt-2 ${dobError?'':'hidden'}`}>This field is required.</div>
                    </div>
                </div>
                <div className='mt-6'>
                    <div className='flex items-center justify-between'>
                        <div className='font-bold'>Password</div>
                    </div>
                    <div className='relative mt-2'>
                        <input type={`${passwordView?'text':'password'}`} className={`text-white rounded-md border border-neutral-400 h-14 w-full p-3 pr-12 bg-transparent ${passwordError?'border-red-600':''}`} placeholder='Password' value={password} onChange={(event) => setPassword(event.target.value)} />
                        <button 
                            className='absolute top-0 right-0 p-3 h-14 flex items-center'
                            onClick={() => setPasswordView(!passwordView)}
                        ><EyeIcon /></button>
                        <div className={`text-red-600 text-sm mt-2 ${passwordError?'':'hidden'}`}>This field is required.</div>
                    </div>
                </div>
                <div className='mt-6'>
                    <div className='flex items-center justify-between'>
                        <div className='font-bold'>Confirm Password</div>
                    </div>
                    <div className='relative mt-2'>
                        <input type={`${repasswordView?'text':'password'}`} className={`text-white rounded-md border border-neutral-400 h-14 w-full p-3 pr-12 bg-transparent ${repasswordError||passwordNotMatchError?'border-red-600':''}`} placeholder='Confirm Password' value={repassword} onChange={(event) => setRepassword(event.target.value)} />
                        <button 
                            className='absolute top-0 right-0 p-3 h-14 flex items-center'
                            onClick={() => setRepasswordView(!repasswordView)}
                        ><EyeIcon /></button>
                        <div className={`text-red-600 text-sm mt-2 ${repasswordError?'':'hidden'}`}>This field is required.</div>
                        <div className={`text-red-600 text-sm mt-2 ${passwordNotMatchError?'':'hidden'}`}>Passwords do not match.</div>
                    </div>
                </div>

                <div className='mt-6'>
                    <div className='flex items-center justify-between'>
                        <div className='font-bold'> Verification Code </div>
                    </div>
                    <div className='relative mt-2'>
                    <input className={`text-white rounded-md border border-neutral-400 h-14 w-full p-3 bg-transparent ${verificationCodeError?'border-red-600':''}`} placeholder='Verification Code' value={verificationCode} onChange={(event) => setVerificationCode(event.target.value)} />
                        <button 
                            className='absolute top-0 right-0 p-3 h-14 flex items-center'
                            onClick={() => setVerificationCodeView(!verificationCodeView)}
                        ></button>
                        <div className={`text-red-600 text-sm mt-2 ${verificationCodeError?'':'hidden'}`}>Incorrect Verification Code.</div>
                    </div>
                </div>

                <div className='mt-6'>
                    <div className='flex items-start justify-start gap-3'>
                        <input type='checkbox' className='mt-1' checked={terms} onChange={()=>setTerms(!terms)} />
                        <label className='text-sm text-neutral-400 flex items-center gap-2'>
                            <div>I agree with</div> 
                            <Link to={'/terms'} className='font-bold'>Terms</Link> 
                            <div>and</div> 
                            <Link to={'/privacy-policy'} className='font-bold'>Privacy</Link>
                        </label>
                    </div>
                    <div className={`text-red-600 text-sm mt-2 ${termsError?'':'hidden'}`}>This field is required.</div>
                </div>
                <div className='mt-10'>
                    <button onClick={handleSignup} 
                    className="flex items-center justify-center gap-3 bg-white text-black mb-3 px-5 py-3 w-full rounded-md border-2 border-white hover:drop-shadow-xl"
                    >
                        <div className='font-bold uppercase'>CREATE ACCOUNT</div>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Signup