import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useStateContext } from '../contexts/ContextProvider';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { ToastContainer, Bounce, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { ReactComponent as BackIcon } from '../assets/icons/icon-back.svg'
import { ReactComponent as StarIcon } from '../assets/icons/icon-star.svg'
import { ReactComponent as ArrowRightIcon } from '../assets/icons/icon-arrow-right.svg'
import { ReactComponent as UserIcon } from '../assets/icons/icon-user.svg'

const Settings = () => {

    let navigate = useNavigate();
    
    const  { setAuthToken } = useStateContext()

    const { authToken } = useStateContext();
    if(!authToken) navigate('/login'); 
    const apiUrl = process.env.REACT_APP_API_URL;
    const user = jwtDecode(authToken);

    const [userPhotoURL, setUserPhotoURL] = useState(''); 
    const [fullName, setFullName] = useState(''); 
    const [username, setUsername] = useState(''); 
    const [matchCred, setMatchCred] = useState(''); 

    useEffect(() => {
        
        if(authToken) {
            axios.defaults.headers.common["Authorization"] = "Bearer " + authToken;
        } else {
            delete axios.defaults.headers.common["Authorization"];
        }

        axios.get(apiUrl + '/users/'+user.id)
        .then((response) => {
            if(response.data && response.data.success == true && response.data.data) {
                //console.log(response.data.data);
                setUserPhotoURL(response.data.data.picture);
                setFullName(response.data.data.full_name);
                setUsername(response.data.data.username);
                setMatchCred(response.data.data.profile?.match_cred);
            }
        })

    }, [])

    const handleLogout = () => {
        setAuthToken('');
        navigate('/');
    }

    return (
        <div className='m-2 mt-20 md:m-8'>
            <div className='max-w-lg m-auto text-white'>
                <div className='text-center'>
                    <div className="mb-4 relative flex justify-center">
                        { userPhotoURL ? <img className="border-4 rounded-md w-32 h-32 object-cover" src={userPhotoURL} /> : <UserIcon className="w-32 h-32" /> }
                        <div className="bg-white text-black flex w-fit absolute rounded flex items-center m-auto bottom-0 p-1 gap-1 card-item-cred">
                            <StarIcon />
                            <div className="leading-none text-sm font-bold">{matchCred?matchCred:'N/A'}</div>
                        </div>
                    </div>
                    <div className="font-bold text-xl">{fullName}</div>
                    <div className="text-neutral-500">@{username}</div>
                    <div className='mt-6 flex flex-col gap-3'>
                        <Link
                        to='/settings/profile'
                        className="flex items-center justify-between gap-3 bg-white text-black px-5 py-3 w-full rounded-md border-2 border-white hover:drop-shadow-xl"
                        >
                            <div className='text-left'>
                                <div className='font-bold uppercase'>Personal Details</div>
                                <div className='text-sm text-neutral-500'>View and edit your personal details</div>
                            </div>
                            <ArrowRightIcon />
                        </Link>
                        <Link
                        to='/settings/password'
                        className="flex items-center justify-between gap-3 bg-white text-black px-5 py-3 w-full rounded-md border-2 border-white hover:drop-shadow-xl"
                        >
                            <div className='text-left'>
                                <div className='font-bold uppercase'>Password</div>
                                <div className='text-sm text-neutral-500'>Update your password</div>
                            </div>
                            <ArrowRightIcon />
                        </Link>
                        <Link
                        to='/settings/privacy'
                        className="flex items-center justify-between gap-3 bg-white text-black px-5 py-3 w-full rounded-md border-2 border-white hover:drop-shadow-xl"
                        >
                            <div className='text-left'>
                                <div className='font-bold uppercase'>PRIVACY</div>
                                <div className='text-sm text-neutral-500'>Configure your privacy settings</div>
                            </div>
                            <ArrowRightIcon />
                        </Link>
                        <Link
                        to='/settings/notification'
                        className="flex items-center justify-between gap-3 bg-white text-black px-5 py-3 w-full rounded-md border-2 border-white hover:drop-shadow-xl"
                        >
                            <div className='text-left'>
                                <div className='font-bold uppercase'>NOTIFICATIONS</div>
                                <div className='text-sm text-neutral-500'>Configure your notification settings</div>
                            </div>
                            <ArrowRightIcon />
                        </Link>
                        <Link
                        to='/settings/faq'
                        className="flex items-center justify-between gap-3 bg-white text-black px-5 py-3 w-full rounded-md border-2 border-white hover:drop-shadow-xl"
                        >
                            <div className='text-left'>
                                <div className='font-bold uppercase'>FAQ</div>
                                <div className='text-sm text-neutral-500'>Find answers to the most asked questions</div>
                            </div>
                            <ArrowRightIcon />
                        </Link>
                        <Link
                        to='/settings/contact'
                        className="flex items-center justify-between gap-3 bg-white text-black px-5 py-3 w-full rounded-md border-2 border-white hover:drop-shadow-xl"
                        >
                            <div className='text-left'>
                                <div className='font-bold uppercase'>Help & Support</div>
                                <div className='text-sm text-neutral-500'>Contact us for help with your account</div>
                            </div>
                            <ArrowRightIcon />
                        </Link>
                    </div>
                    <div className='mt-4'>
                        <div className="flex items-center gap-3">
                            <button onClick={() => handleLogout()}
                            className="bg-transparent text-sm text-white p-3 w-full rounded-md border-2 border-white uppercase hover:drop-shadow-xl text-center"
                            >
                                <div className='font-bold'>LOG OUT</div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Settings