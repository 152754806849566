import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useStateContext } from '../contexts/ContextProvider';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { ToastContainer, Bounce, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { numberWithCommas } from '../config/common'

import { ReactComponent as WinsIcon } from '../assets/icons/icon-wins.svg'
import { ReactComponent as LossesIcon } from '../assets/icons/icon-losses.svg'
import { ReactComponent as RankIcon } from '../assets/icons/icon-rank.svg'
import { ReactComponent as Star2Icon } from '../assets/icons/icon-star2.svg'
import { ReactComponent as TransferIcon } from '../assets/icons/icon-transfer.svg'
import { ReactComponent as WithdrawIcon } from '../assets/icons/icon-withdraw.svg'
import { ReactComponent as DepositIcon } from '../assets/icons/icon-deposit.svg'
import History from '../components/History';
import Pagination from "../components/Pagination";

const Wallet = () => {

    const { authToken } = useStateContext();
    if(!authToken) navigate('/login'); 
    const apiUrl = process.env.REACT_APP_API_URL;
    const user = jwtDecode(authToken);

    const [userData, setUserData] = useState({}); 
    const [rank, setRank] = useState(''); 
    const [walletData, setWalletData] = useState({}); 
    const [historyData, setHistoryData] = useState([]);
    const [ethPrice, setEthPrice] = useState(0);  

    useEffect(() => {
        
        if(authToken) {
            axios.defaults.headers.common["Authorization"] = "Bearer " + authToken;
        } else {
            delete axios.defaults.headers.common["Authorization"];
        }

        axios.get(apiUrl + '/users/'+user.id)
        .then((response) => {
            if(response.data && response.data.success == true && response.data.data) {
                setUserData(response.data.data);
            }
            if(response.data && response.data.success == true && response.data.rank) {
                setRank(response.data.rank);
            }
        })
        .catch((error) => console.log('API error - ', error))

        axios.get(apiUrl + '/matches_completed?user_id='+user.id)
        .then((response) => {
            if(response.data && response.data.success == true && response.data.data.length > 0) {
                setHistoryData(response.data.data);
            }
        })
        .catch((error) => console.log('API error - ', error))

        axios.get(apiUrl + '/wallets/'+user.id)
        .then((response) => {
            if(response.data && response.data.success == true && response.data.data) {
                setWalletData(response.data.data);
            } else {
                axios.post(apiUrl + '/wallets/', {"user_id": user.id})
                .then((response) => {
                    if(response.data && response.data.success == true && response.data.data) {
                        setWalletData(response.data.data);
                    }
                })
            }
        })

        axios.get('https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd')
        .then((response) => {
            setEthPrice(response.data.ethereum.usd);
        })

    }, [])

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(8);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = historyData.slice(indexOfFirstItem, indexOfLastItem);

    const handlePagination = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return (
        <div className='m-2 mt-20 md:m-8'>
            <div className='max-w-lg m-auto text-white'>
                <div className="font-bold text-3xl">My Wallet</div>
                <div className='bg-neutral-800 rounded-xl p-2 mt-4 text-center'>
                    <div className='flex justify-center pt-2'>
                        <div className='w-1/4'>
                            <WinsIcon className='m-auto' />
                            <div className='text-2xl font-bold mt-2'>{userData.profile?.num_wins?userData.profile?.num_wins:'-'}</div>
                            <div className='text-sm text-neutral-400 uppercase'>Wins</div>
                        </div>
                        <div className='w-1/4'>
                            <LossesIcon className='m-auto' />
                            <div className='text-2xl font-bold mt-2'>{userData.profile?.num_losses?userData.profile?.num_losses:'-'}</div>
                            <div className='text-sm text-neutral-400 uppercase'>Losses</div>
                        </div>
                        <div className='w-1/4'>
                            <RankIcon className='m-auto' />
                            <div className='text-2xl font-bold mt-2'>{rank?rank:'-'}</div>
                            <div className='text-sm text-neutral-400 uppercase'>Rank</div>
                        </div>
                        <div className='w-1/4'>
                            <Star2Icon className='m-auto' />
                            <div className='text-2xl font-bold mt-2'>{userData.profile?.match_cred?userData.profile?.match_cred:'N/A'}</div>
                            <div className='text-sm text-neutral-400 uppercase'>Matchcred</div>
                        </div>
                    </div>
                    <div className='mt-3'>
                        <div className='flex items-center justify-between gap-3 rounded-lg bg-neutral-700 p-3'>
                            <div className='w-1/3'>
                                <div className='text-3xl font-bold'>{walletData?.available > 0 ? numberWithCommas(walletData?.available) : 0} ETH</div>
                                <div className='text-sm text-neutral-400 uppercase'>Available Balance</div>
                                { ethPrice && <div className='text-sm text-neutral-400 uppercase'>(~{Math.round(walletData?.available * ethPrice * 100)/100} USD)</div> }
                            </div>
                            <div className='w-1/3'>
                                <TransferIcon className='m-auto' />
                            </div>
                            <div className='w-1/3'>
                                <div className='text-3xl font-bold'>{walletData?.escrow > 0 ? numberWithCommas(walletData?.escrow) : 0} ETH</div>
                                <div className='text-sm text-neutral-400 uppercase'>Escrow Balance</div>
                                { ethPrice && <div className='text-sm text-neutral-400 uppercase'>(~{Math.round(walletData?.escrow * ethPrice * 100)/100} USD)</div> }
                            </div>
                        </div>
                    </div>
                </div>
                <div className='mt-4'>
                    <div className="flex items-center gap-3">
                        <Link
                        to='/wallet/withdraw'
                        className="bg-white text-sm text-black text-center font-bold p-3 w-full rounded-md border-2 border-white uppercase hover:drop-shadow-xl"
                        >
                            <WithdrawIcon className='m-auto' />
                            <div className='mt-2'>WITHDRAW FUNDS</div>
                        </Link>
                        <Link
                        to='/wallet/deposit'
                        className="bg-white text-sm text-black text-center font-bold p-3 w-full rounded-md border-2 border-white uppercase hover:drop-shadow-xl"
                        >
                            <DepositIcon className='m-auto' />
                            <div className='mt-2'>DEPOSIT FUNDS</div>
                        </Link>
                    </div>
                </div>
                <div className='mt-8'>
                    <div className='font-bold text-center mb-4'>{historyData.length==0?'No':''} Completed Match History</div>
                    { 
                        historyData && historyData.length > 0 &&
                        <div>
                            {currentItems.map((item, index) => (
                                <History item={item} user_id={user.id} key={`history-${index}`} />
                            ))}
                            <div>
                                <Pagination
                                    length={historyData.length}
                                    itemsPerPage={itemsPerPage}
                                    currentPage={currentPage}
                                    handlePagination={handlePagination}
                                />
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default Wallet