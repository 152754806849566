import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Popover from '@idui/react-popover'
import { useStateContext } from '../contexts/ContextProvider';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { ToastContainer, Bounce, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { ReactComponent as QuestionIcon } from '../assets/icons/icon-question.svg'
import { ReactComponent as BackIcon } from '../assets/icons/icon-back.svg'

const EndMatch = () => {

    let navigate = useNavigate();

    const { authToken } = useStateContext();
    if(!authToken) navigate('/login'); 
    const apiUrl = process.env.REACT_APP_API_URL;
    const user = jwtDecode(authToken);

    const params = useParams();

    const [isEndSubmitted, setEndSubmitted] = useState(false); 
    const [matchOutcome, setMatchOutcome] = useState(''); 
    const [matchData, setMatchData] = useState({}); 
    
    useEffect(() => {
        
        if(authToken) {
            axios.defaults.headers.common["Authorization"] = "Bearer " + authToken;
        } else {
            delete axios.defaults.headers.common["Authorization"];
        }

        axios.get(apiUrl + '/matches/'+params['id'])
        .then((response) => {
            if(response.data && response.data.success == true) {
                setMatchData(response.data.data);
            }
        })
        .catch((error) => console.log('API error - ', error))
        
    }, [])

    const handleEndMatch = (matchId) => {
        if (!matchOutcome) {
            toast('Please select the match outcome.', {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce
            });

            return false;
        }

        if(confirm("Are you sure you want to request to end this match?") == true) {
            axios.post(apiUrl + '/match_end_request/'+matchId, {user_id: user.id, choice: matchOutcome})
            .then((response) => {
                if(response.data) {
                    toast(response.data.msg, {
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        transition: Bounce
                    });

                    setEndSubmitted(true);
                }
            })
            .catch((error) => console.log('API error - ', error));
        }
        
    }

    return (
        <div className='m-2 mt-20 md:m-8'>
            <div className='max-w-lg m-auto text-white'>
                {
                    !isEndSubmitted &&
                    <>
                        <button onClick={()=>navigate(-1)} className='mb-3'>
                            <BackIcon />
                        </button>
                        <div className="font-bold text-3xl">End Match</div>
                        <div className='mt-6'>
                            <div className='font-bold'>Match outcome</div>
                            <div className='relative mt-2'>
                                <select className='text-white rounded-md border-2 border-neutral-300 h-14 w-full p-2 bg-transparent' value={matchOutcome} onChange={(event) => setMatchOutcome(event.target.value)}>
                                    <option className='text-white' disabled selected value=''>Select match outcome</option>
                                    <option className='text-black' value='0'>{ matchData.from_user?.full_name } { matchData.from_user_id == user.id ? '(You)' : '' } won the match</option>
                                    <option className='text-black' value='1'>{ matchData.to_user?.full_name } { matchData.to_user_id == user.id ? '(You)' : '' } won the match</option>
                                    <option className='text-black' value='2'>Tie</option>
                                </select>
                            </div>
                        </div>
                        { 
                            matchOutcome != '' && 
                            <div className='rounded-lg bg-neutral-700 p-6 mt-8'>
                                <div className='flex items-center justify-between gap-3 mb-2'>
                                    <div className='text-neutral-300'>Initial Exposure</div>
                                    <div className='font-bold'>{ matchData.from_user_amount } ETH</div>
                                </div>
                                <div className='flex items-center justify-between gap-3 mb-2'>
                                    <div className='text-neutral-300'>Multiplier</div>
                                    <div className='font-bold'>{ matchData.multiplier }x</div>
                                </div>
                                <div className='flex items-center justify-between gap-3 mb-2'>
                                    <div className='flex items-center gap-2'>
                                        <div className='text-neutral-300'>Transaction Fee</div>
                                        <Popover
                                            trigger='click'
                                            width="240px"
                                            placement='topLeft'
                                            closeOnScroll
                                            closeOnRemoteClick
                                            closeOnTab
                                            onChangeOpen={function noRefCheck(){}}
                                            onFocus={function noRefCheck(){}}
                                            content={() => (
                                                <div className='text-sm'>
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                                </div>
                                            )}
                                            >
                                            <button className='relative'><QuestionIcon /></button>
                                        </Popover>
                                    </div>
                                    <div className='font-bold'>{ matchData.from_user_id == user.id ? (matchData.from_user_amount * 0.03) : (matchData.to_user_amount * 0.03) } ETH</div>
                                </div>
                                <div className='flex items-center justify-between gap-3 mb-2'>
                                    <div className='flex items-center gap-2'>
                                        <div className='text-neutral-300'>Potential</div>
                                        <Popover
                                            trigger='click'
                                            width="240px"
                                            placement='topLeft'
                                            closeOnScroll
                                            closeOnRemoteClick
                                            closeOnTab
                                            onChangeOpen={function noRefCheck(){}}
                                            onFocus={function noRefCheck(){}}
                                            content={() => (
                                                <div className='text-sm'>
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                                </div>
                                            )}
                                            >
                                            <button className='relative'><QuestionIcon /></button>
                                        </Popover>
                                    </div>
                                    <div className='font-bold'>{matchData.from_user_amount * 0.97 + matchData.to_user_amount * 0.97} ETH</div>
                                </div>
                                { /*
                                <div className='flex items-center justify-between gap-3'>
                                    <div className='flex items-center gap-2'>
                                        <div className='text-neutral-300'>Total Received</div>
                                        <Popover
                                            trigger='click'
                                            width="240px"
                                            placement='topLeft'
                                            closeOnScroll
                                            closeOnRemoteClick
                                            closeOnTab
                                            onChangeOpen={function noRefCheck(){}}
                                            onFocus={function noRefCheck(){}}
                                            content={() => (
                                                <div className='text-sm'>
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                                </div>
                                            )}
                                            >
                                            <button className='relative'><QuestionIcon /></button>
                                        </Popover>
                                    </div>
                                    <div className='font-bold'>
                                        { matchData.from_user_id == user.id ? ( matchOutcome == 0 ? (Math.round((matchData.from_user_amount * 0.97 + matchData.to_user_amount * 0.97) * 10000000)/10000000) : ( matchOutcome == 1 ? 0 : ( matchOutcome == 2 ? (Math.round((matchData.from_user_amount * 0.97) * 10000000)/10000000) : ''))) : (matchOutcome == 0 ? 0 : ( matchOutcome == 1 ? (Math.round((matchData.from_user_amount * 0.97 + matchData.to_user_amount * 0.97) * 10000000)/10000000) : ( matchOutcome == 2 ? (Math.round((matchData.to_user_amount * 0.97) * 10000000)/10000000) : ''))) } ETH
                                    </div>
                                </div>
                                */ }
                                {
                                    matchOutcome == 2 && 
                                    <div className='flex items-center justify-center mt-3'>
                                        <div className='text-neutral-300 font-bold'>Both players will be refunded their deposits.</div>
                                    </div>
                                }
                            </div>
                        }
                        <div className='mt-24'>
                            { 
                                matchOutcome == '' && 
                                <div className='text-sm mb-4 text-center text-neutral-400'>
                                    If you choose an outcome for the match, potentials will be paid out after your opponent has agreed to the same outcome. If you choose to cancel the match altogether, both players will be refunded the escrow amount.
                                </div>
                            }
                            <div className="flex items-center gap-3">
                                {
                                    matchOutcome == '' && 
                                    <button
                                    onClick={(event) => event.preventDefault()}
                                    className="bg-neutral-700 text-sm text-black p-3 w-full rounded-md border-2 border-neutral-700 uppercase hover:drop-shadow-xl text-center"
                                    >
                                        <div className='font-bold'>SUBMIT END MATCH</div>
                                    </button>
                                }
                                {
                                    matchOutcome != '' && 
                                    <button
                                    onClick={() => handleEndMatch(matchData.id)}
                                    className="bg-white text-sm text-black p-3 w-full rounded-md border-2 border-white uppercase hover:drop-shadow-xl text-center"
                                    >
                                        <div className='font-bold'>SUBMIT END MATCH</div>
                                    </button>
                                }
                            </div>
                        </div>
                    </>
                }
                {
                    isEndSubmitted &&
                    <>
                        <div className="font-bold text-3xl mt-24">Waiting for your opponent</div>
                        <div className="mt-6">Thank you for ending the match. We have notified <b>@{ matchData.from_user_id == user.id ? matchData.to_user.username: matchData.from_user.username }</b> and are waiting for their input on the match outcome.</div>   
                        <div className='mt-24'>
                            <div className="flex items-center gap-3">
                                <Link
                                to='/matches'
                                className="bg-white text-sm text-black p-3 w-full rounded-md border-2 border-white uppercase hover:drop-shadow-xl text-center"
                                >
                                    <div className='font-bold'>Home</div>
                                </Link>
                            </div>
                        </div>
                    </>
                }
                <ToastContainer />
            </div>
        </div>
    )
}

export default EndMatch