
export const numberWithCommas = (x) => {
    if(x === undefined) return false;
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    if(parts[1]) parts[1] = parts[1].substr(0,7);
    return parts.join(".");
}

export const handle2DecimalsOnValue = (value) => {
    const regex = /([0-9]*[\.|\,]{0,1}[0-9]{0,2})/s;
    return value.match(regex)[0];
}

export const handle5DecimalsOnValue = (value) => {
    const regex = /([0-9]*[\.|\,]{0,1}[0-9]{0,5})/s;
    return value.match(regex)[0];
}

export const handle7DecimalsOnValue = (value) => {
    const regex = /([0-9]*[\.|\,]{0,1}[0-9]{0,7})/s;
    return value?value.match(regex)[0]:'';
}