import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { handle7DecimalsOnValue } from '../config/common'
import { useStateContext } from '../contexts/ContextProvider';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { ToastContainer, Bounce, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { ReactComponent as BackIcon } from '../assets/icons/icon-back.svg'
import { ReactComponent as SearchIcon } from '../assets/icons/icon-search.svg'
import { ReactComponent as CircleStarIcon } from '../assets/icons/icon-circle-star.svg'
import { ReactComponent as PencilIcon } from '../assets/icons/icon-pencil.svg'
import { ReactComponent as StarIcon } from '../assets/icons/icon-star.svg'
import { ReactComponent as UserIcon } from '../assets/icons/icon-user.svg'
import Opponent from '../components/Opponent';
import moment from 'moment';

const NewMatch = () => {

    let navigate = useNavigate();

    const { authToken } = useStateContext();
    if(!authToken) navigate('/login'); 
    const apiUrl = process.env.REACT_APP_API_URL;
    const user = jwtDecode(authToken);

    const params = useParams();

    const [isPublic, setPublic] = useState(false); 
    const [matchType, setMatchType] = useState(''); 
    const [step, setStep] = useState(1); 
    const [matchTypes, setMatchTypes] = useState([]); 
    const [friendData, setFriendData] = useState([]); 

    const [searchTerm, setSearchTerm] = useState(''); 
    const [searchedList, setSearchedList] = useState([]); 

    const [currentUserDetail, setCurrentUserDetail] = useState({}); 
    const [matchOpponent, setMatchOpponent] = useState({}); 
    const [matchTitle, setMatchTitle] = useState(''); 
    const [matchDescription, setMatchDescription] = useState(''); 
    const [matchExposure, setMatchExposure] = useState('');
    const [matchMultiplier, setMatchMultiplier] = useState(1.0);
    const [matchTotal, setMatchTotal] = useState(0.000000);

    const [matchTitleError, setMatchTitleError] = useState(false);
    const [matchDescriptionError, setMatchDescriptionError] = useState(false);
    const [matchExposureError, setMatchExposureError] = useState(false);

    const [ethPrice, setEthPrice] = useState(0);
    
    useEffect(() => {
        
        if(authToken) {
            axios.defaults.headers.common["Authorization"] = "Bearer " + authToken;
        } else {
            delete axios.defaults.headers.common["Authorization"];
        }

        axios.get(apiUrl + '/friends_all_users?user_id='+user.id)
        .then((response) => {
            if(response.data && response.data.success == true) {
                console.log(response.data.data)
                setFriendData(response.data.data);
                if (params['opponent_id']) {
                    var selectedFriend = response.data.data.find(x => x.id == params['opponent_id']);
                    setMatchOpponent(selectedFriend);
                }
            }
        })
        .catch((error) => console.log('API error - ', error))

        axios.get(apiUrl + '/match_types_global/')
        .then((response) => {
            if(response.data && response.data.success == true) {
                setMatchTypes(response.data.data);
                
                axios.get(apiUrl + '/match_types_mine/'+user.id)
                .then((response2) => {
                    if(response2.data && response2.data.success == true && response2.data.data.length > 0) {
                        var newMatchType = response.data.data.concat(response2.data.data);
                        setMatchTypes(newMatchType);
                    }
                })
                .catch((error) => console.log('API error - ', error))
            }
        })
        .catch((error) => console.log('API error - ', error))

        axios.get(apiUrl + '/users/'+user.id)
        .then((response) => {
            if(response.data && response.data.success == true) {
                setCurrentUserDetail(response.data.data);
            }
        })
        .catch((error) => console.log('API error - ', error))
        
        axios.get('https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd')
        .then((response) => {
            setEthPrice(response.data.ethereum.usd);
        })

    }, [])

    useEffect(() => {
		var filtered = friendData.filter(x => x.full_name?.toLowerCase().includes(searchTerm.toLowerCase()));
		setSearchedList(filtered);
	}, [searchTerm]);

    useEffect(() => {
        let matchTotalTemp = (matchExposure * 1 + (matchExposure * matchMultiplier));
		setMatchTotal(matchTotalTemp);
	}, [matchExposure, matchMultiplier]);

    const handleChallenge = (friendId) => {
        var selectedFriend = friendData.find(x => x.id == friendId);
        setMatchOpponent(selectedFriend);
        setSearchTerm('');
    }

    const handleAddFriend = (friend_user_id) => {
        if(confirm("Are you sure you want to send a friend request to this friend?") == true) {
            axios.post(apiUrl + '/friends', {from_user_id: user.id, to_user_id: friend_user_id, status: 0, created: moment()})
            .then((response) => {
                if(response.data) {
                    toast(response.data.msg, {
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        transition: Bounce
                    });

                    if(response.data.success == true) {
                        initData();
                    }
                }
            })
            .catch((error) => console.log('API error - ', error));
        }
    }

    const handleFriendAccept = (friend_user_id) => {
        if(confirm("Are you sure you want to accept this friend request?") == true) {
            axios.put(apiUrl + '/friends_by_user_id/'+user.id+'/'+friend_user_id, {status: 1, accepted: moment()})
            .then((response) => {
                if(response.data) {
                    toast(response.data.msg, {
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        transition: Bounce
                    });

                    if(response.data.success == true) {
                        initData();
                    }
                }
            })
            .catch((error) => console.log('API error - ', error));
        }
    }

    const handleFriendDecline = (friend_user_id) => {
        if(confirm("Are you sure you want to decline this friend request?") == true) {
            axios.put(apiUrl + '/friends_by_user_id/'+user.id+'/'+friend_user_id, {status: 2, rejected: moment()})
            .then((response) => {
                if(response.data) {
                    toast(response.data.msg, {
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        transition: Bounce
                    });

                    if(response.data.success == true) {
                        initData();
                    }
                }
            })
            .catch((error) => console.log('API error - ', error));
        }
    }

    const handleStep1 = () => {
        if (!matchOpponent || Object.keys(matchOpponent).length === 0) {
            toast('Please select a match opponent.', {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce
            });

            return false;
        }

        if (!matchType) {
            toast('Please select a match type.', {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce
            });

            return false;
        }

        setStep(2);
    }

    const handleStep2 = () => {
        if (!matchTitle || matchTitle == '') {
            setMatchTitleError(true);
            return false;
        } else {
            setMatchTitleError(false);
        }

        if (!matchDescription || matchDescription == '') {
            setMatchDescriptionError(true);
            return false;
        } else {
            setMatchDescriptionError(false);
        }

        setStep(3);
    }

    const handleStep3 = () => {
        if (!matchExposure || matchExposure == 0) {
            setMatchExposureError(true);
            return false;
        } else {
            setMatchExposureError(false);
        }

        setStep(4);
    }

    const handleCreateMatch = () => {
        
        if (!matchExposure || matchExposure == 0) {
            toast("You don't have enough funds to cover the match expenses.", {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce
            });

            return false;
        }

        if(confirm("Are you sure you want to create a new match?") == true) {

            axios.post(apiUrl + '/matches/', 
                {
                    "title": matchTitle,
                    "message": matchDescription,
                    "from_user_id": user.id,
                    "to_user_id": matchOpponent.id,
                    "multiplier": matchMultiplier,
                    "from_user_amount": matchExposure,
                    "to_user_amount": Math.round(matchExposure * matchMultiplier * 10000000)/10000000,
                    //"status": 0,
                    "is_public": isPublic,
                    "match_type_id": matchType,
                    //"created": moment()
                }
            )
            .then((response) => {
                if(response.data) {
                    toast(response.data.msg, {
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        transition: Bounce
                    });

                    if(response.data.success == true) {
                        setStep(5);
                    }
                }
            })
            .catch((error) => console.log('API error - ', error));
        }
        
    }

    return (
        <div className='m-2 mt-20 md:m-8'>
            <div className='max-w-lg m-auto text-white'>
                {
                    step > 1 &&
                    <button onClick={()=>setStep(step-1)} className='mb-3'>
                        <BackIcon />
                    </button>
                }
                
                {
                    step == 1 &&
                    <>
                        <div className="font-bold text-3xl">Create a Match</div>
                        <div className='mt-6'>
                            <div className='font-bold'>Opponent</div>
                            <div className='relative mt-2'>
                                <div className='absolute top-0 left-0 p-3 h-14 flex items-center'><SearchIcon /></div>
                                <input className='text-white rounded-md border border-neutral-400 h-14 w-full p-2 pl-10 bg-transparent' placeholder={ matchOpponent?.username ? '@'+matchOpponent.username: 'Select Opponent' } value={searchTerm} onChange={(event) => setSearchTerm(event.target.value)} />
                                {
                                    searchTerm && searchTerm != '' &&
                                    <div className='absolute p-4 border border-white rounded-md bg-black w-full max-h-96 overflow-auto z-10'>
                                        {
                                            searchedList && searchedList.length > 0 &&
                                            <div className=''>
                                                {searchedList.map((item, index) => (
                                                    <Opponent 
                                                        item={item} 
                                                        is_friend={((item.friend_from_user && item.friend_from_user?.status == 1) || (item.friend_to_user && item.friend_to_user?.status == 1))} 
                                                        is_requested={(item.id != user.id && item.friend_from_user && item.friend_from_user?.to_user_id == user.id && item.friend_from_user?.status == 0)} 
                                                        is_searched={true} 
                                                        handleChallenge={handleChallenge}
                                                        handleAddFriend={handleAddFriend}
                                                        handleFriendAccept={handleFriendAccept}
                                                        handleFriendDecline={handleFriendDecline}
                                                        key={`friend-${index}`} 
                                                    />
                                                ))}
                                            </div>
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                        <div className='mt-6'>
                            <div className='font-bold'>Match Privacy</div>
                            <div className='relative mt-2'>
                                <div className='flex items-center rounded-full border border-neutral-400 text-center text-neutral-400'>
                                    <div className={`w-1/2 font-bold p-3 m-1 rounded-full uppercase cursor-pointer ${isPublic?'bg-white text-black':''}`} onClick={() => setPublic(true)}>Public</div>
                                    <div className={`w-1/2 font-bold p-3 m-1 rounded-full uppercase cursor-pointer ${!isPublic?'bg-white text-black':''}`} onClick={() => setPublic(false)}>Private</div>
                                </div>
                            </div>
                        </div>
                        <div className='mt-6'>
                            <div className='font-bold'>Match Type</div>
                            <div className='relative mt-2'>
                                <div className="flex items-center gap-3">
                                    <Link
                                    to='/match-type'
                                     
                                    className="bg-white text-sm text-black p-3 w-full rounded-md border-2 border-white uppercase hover:drop-shadow-xl text-center"
                                    >
                                        <div className='font-bold'>Custom Match Type</div>
                                    </Link>
                                </div>
                                <div className="grid grid-cols-2 gap-3 mt-3">
                                    { matchTypes && matchTypes.length > 0 && matchTypes.map((item) => (
                                        <label htmlFor={item.id} className={`relative w-full bg-white font-bold text-sm text-black p-4 rounded-md uppercase hover:drop-shadow-xl text-center cursor-pointer border-2 ${matchType == item.id?'border-dashed border-black':''}`}>
                                            <input id={item.id} type='radio' name="matchType" value={item.id} onChange={(event) => setMatchType(event.target.value)} className='hidden' />
                                            <div>{item.title}</div>
                                            { item.is_pinned && <CircleStarIcon className='absolute top-1 left-1' />}
                                        </label>
                                        )
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className='mt-24'>
                            <div className="flex items-center gap-3">
                                <button
                                onClick={() => handleStep1()}
                                className="bg-white text-sm text-black p-3 w-full rounded-md border-2 border-white uppercase hover:drop-shadow-xl text-center"
                                >
                                    <div className='font-bold'>Next Step</div>
                                </button>
                            </div>
                        </div>
                        <div className='mt-4'>
                            <div className="flex items-center justify-center gap-2">
                                <div className='w-2 h-2 rounded border-2 border-white'></div>
                                <div className='w-2 h-2 rounded bg-neutral-600'></div>
                                <div className='w-2 h-2 rounded bg-neutral-600'></div>
                                <div className='w-2 h-2 rounded bg-neutral-600'></div>
                            </div>
                        </div>
                    </>
                }
                {
                    step == 2 &&
                    <>
                        <div className="font-bold text-3xl">Create a Match</div>
                        <div className='mt-6'>
                            <div className='flex items-center justify-between'>
                                <div className='font-bold'>Match title</div>
                                <div className='text-sm'>{ 100 - matchTitle.length }</div>
                            </div>
                            <div className='relative mt-2'>
                                <input className={`text-white rounded-md border border-neutral-400 h-14 w-full p-3 bg-transparent ${matchTitleError?'border-red-600':''}`} maxLength={100} placeholder='E.g. Lakers vs Knicks' value={matchTitle} onChange={(event) => setMatchTitle(event.target.value)} />
                                <div className={`text-red-600 text-sm mt-2 ${matchTitleError?'':'hidden'}`}>This field is required.</div>
                            </div>
                        </div>
                        <div className='mt-6'>
                            <div className='flex items-center justify-between'>
                                <div className='font-bold'>Match description</div>
                                <div className='text-sm'>{ 255 - matchDescription.length }</div>
                            </div>
                            <div className='relative mt-2'>
                                <textarea className={`text-white rounded-md border border-neutral-400 w-full p-3 bg-transparent ${matchDescriptionError?'border-red-600':''}`} rows={8} maxLength={255} placeholder='Describe the terms that will govern the match. E.g. Player 1 picks the Lakers to win.' value={matchDescription} onChange={(event) => setMatchDescription(event.target.value)}></textarea>
                                <div className={`text-red-600 text-sm mt-2 ${matchDescriptionError?'':'hidden'}`}>This field is required.</div>
                            </div>
                        </div>
                        <div className='mt-24'>
                            <div className="flex items-center gap-3">
                                <button
                                onClick={() => handleStep2()}
                                className="bg-white text-sm text-black p-3 w-full rounded-md border-2 border-white uppercase hover:drop-shadow-xl text-center"
                                >
                                    <div className='font-bold'>Next Step</div>
                                </button>
                            </div>
                        </div>
                        <div className='mt-4'>
                            <div className="flex items-center justify-center gap-2">
                                <div className='w-2 h-2 rounded bg-white'></div>
                                <div className='w-2 h-2 rounded border-2 border-white'></div>
                                <div className='w-2 h-2 rounded bg-neutral-600'></div>
                                <div className='w-2 h-2 rounded bg-neutral-600'></div>
                            </div>
                        </div>
                    </>
                }
                {
                    step == 3 &&
                    <>
                        <div className="font-bold text-3xl">Create a Match</div>
                        <div className='mt-6'>
                            <div className='flex items-center justify-between'>
                                <div className='font-bold'>Exposure</div>
                            </div>
                            <div className='relative mt-2'>
                                <div className='absolute top-0 left-0 p-3 h-14 flex items-center text-neutral-300'>ETH</div>
                                <input className={`text-white text-right rounded-md border border-neutral-400 h-14 w-full p-3 bg-transparent ${matchExposureError?'border-red-600':''}`} placeholder='0.00' value={matchExposure} onChange={(event) => setMatchExposure(handle7DecimalsOnValue(event.target.value))} />
                                { ethPrice && <div className='text-right mt-2 text-sm text-neutral-400 uppercase'>(~{Math.round(matchExposure * ethPrice * 100)/100} USD)</div> }
                                <div className={`text-red-600 text-sm mt-2 ${matchExposureError?'':'hidden'}`}>This field is required.</div>
                            </div>
                        </div>
                        <div className='mt-6'>
                            <div className='flex items-center justify-between'>
                                <div className='font-bold'>Multiplier</div>
                            </div>
                            <div className='relative mt-2 flex items-center justify-between gap-1 md:gap-6'>
                                <div className='w-10/12 md:w-11/12'>
                                    <input type="range" className='multiplier-range-slider w-full' min="0.1" max="9" value={matchMultiplier} step="0.1" onChange={(event) => setMatchMultiplier(event.target.value)} />
                                </div>
                                <div className='w-2/12 md:w-1/12 text-right'>{matchMultiplier}x</div>
                            </div>
                        </div>
                        <div className='mt-6'>
                            <div className='flex items-center justify-between'>
                                <div className='font-bold'>Total Wager</div>
                            </div>
                            <div className='relative mt-2'>
                                <div className='bg-neutral-800 rounded-md font-bold text-white p-4 text-center text-xl'>
                                    {matchTotal} ETH
                                    { ethPrice && <div className='text-center mt-2 text-sm text-neutral-400 uppercase'>(~{Math.round(matchTotal * ethPrice * 100)/100} USD)</div> }
                                </div>
                            </div>
                        </div>
                        <div className='mt-24'>
                            <div className="flex items-center gap-3">
                                <button
                                onClick={() => handleStep3()}
                                className="bg-white text-sm text-black p-3 w-full rounded-md border-2 border-white uppercase hover:drop-shadow-xl text-center"
                                >
                                    <div className='font-bold'>Next Step</div>
                                </button>
                            </div>
                        </div>
                        <div className='mt-4'>
                            <div className="flex items-center justify-center gap-2">
                                <div className='w-2 h-2 rounded bg-white'></div>
                                <div className='w-2 h-2 rounded bg-white'></div>
                                <div className='w-2 h-2 rounded border-2 border-white'></div>
                                <div className='w-2 h-2 rounded bg-neutral-600'></div>
                            </div>
                        </div>
                    </>
                }
                {
                    step == 4 &&
                    <>
                        <div className="font-bold text-3xl">Review Match details</div>
                        <div className='mt-6'>
                            <div className='rounded-lg bg-neutral-700 p-4'>
                                <div className='flex items-center justify-between gap-3'>
                                    <div className=''>
                                        <div className='text-sm text-neutral-400'>Match Type</div>
                                        <div className='font-medium'>{matchTypes.find(x => x.id == matchType).title}</div>
                                    </div>
                                    <button onClick={()=>setStep(1)}>
                                        <PencilIcon />
                                    </button>
                                </div>
                                <div className='flex items-center justify-between gap-3 mt-4'>
                                    <div className=''>
                                        <div className='text-sm text-neutral-400'>Match Title</div>
                                        <div className='font-medium'>{matchTitle}</div>
                                    </div>
                                    <button onClick={()=>setStep(2)}>
                                        <PencilIcon />
                                    </button>
                                </div>
                                <div className='flex items-center justify-between gap-3 mt-4'>
                                    <div className=''>
                                        <div className='text-sm text-neutral-400'>Opponent</div>
                                        <div className='font-medium'>@{matchOpponent.username}</div>
                                    </div>
                                    <button onClick={()=>setStep(1)}>
                                        <PencilIcon />
                                    </button>
                                </div>
                                <div className='flex items-center justify-between gap-3 mt-4'>
                                    <div className=''>
                                        <div className='text-sm text-neutral-400'>Your Total Exposure</div>
                                        <div className='font-medium'>{matchExposure} ETH   @{matchMultiplier}x</div>
                                        { ethPrice && <div className='mt-1 text-sm text-neutral-400 uppercase'>(~{Math.round(matchExposure * ethPrice * 100)/100} USD)</div> }
                                    </div>
                                    <button onClick={()=>setStep(3)}>
                                        <PencilIcon />
                                    </button>
                                </div>
                            </div>
                        </div>
                        { /*
                        <div className='mt-6'>
                            <div className='flex items-center justify-between'>
                                <div className='font-bold'>Payment Method</div>
                            </div>
                            <div className='relative mt-2 flex items-center justify-between gap-6'>
                                <select className='text-white rounded-md border-2 border-neutral-300 h-14 w-full p-2 bg-transparent' name="paymentMethod">
                                    <option className='text-black'>CHASE -----1129</option>
                                    <option className='text-black'>BOA -----2247</option>
                                </select>
                            </div>
                        </div>
                        */ }
                        <div className='mt-6'>
                            <div className='flex items-start justify-between gap-3'>
                                <input type='checkbox' className='mt-1' checked />
                                <label className='text-sm text-neutral-400'>I accept the terms, conditions, and rules of this MoneyMatch as well as the terms of service of Money Match Technology Company, LLC. 10% Match fee will be assed for failed matches.</label>
                            </div>
                        </div>
                        <div className='mt-24'>
                            <div className="flex items-center gap-3">
                                <button
                                onClick={() => handleCreateMatch()}
                                className="bg-white text-sm text-black p-3 w-full rounded-md border-2 border-white uppercase hover:drop-shadow-xl text-center"
                                >
                                    <div className='font-bold'>ACCEPT AND CREATE</div>
                                </button>
                            </div>
                        </div>
                        <div className='mt-4'>
                            <div className="flex items-center justify-center gap-2">
                                <div className='w-2 h-2 rounded bg-white'></div>
                                <div className='w-2 h-2 rounded bg-white'></div>
                                <div className='w-2 h-2 rounded bg-white'></div>
                                <div className='w-2 h-2 rounded border-2 border-white'></div>
                            </div>
                        </div>
                    </>
                }
                {
                    step == 5 &&
                    <>
                        <div className="font-bold text-4xl uppercase italic mt-4">Your match has been created!</div>
                        <div className='mt-6'>
                            <div className='flex card-header gap-2 bg-white rounded-t-xl'>
                                <div className="text-lg leading-tight italic font-bold uppercase text-black px-4 pt-2 pb-1">{matchTitle}</div>
                            </div>
                            <div className="px-5 py-2 rounded-b-xl bg-neutral-700 text-white">
                                <div className="flex items-center justify-center">
                                    <div className="w-1/3 flex flex-col items-center">
                                        <div className="mb-4 relative flex justify-center">
                                            { currentUserDetail.picture ? <img className="border-4 rounded-md w-16 h-16 object-cover" src={currentUserDetail.picture} /> : <UserIcon className="w-16 h-16" /> }
                                            <div className="bg-white text-black flex w-fit absolute rounded flex items-center m-auto bottom-0 p-1 gap-1 card-item-cred">
                                                <StarIcon />
                                                <div className="leading-none text-sm font-bold">{ currentUserDetail.profile?.match_cred?currentUserDetail.profile?.match_cred:'N/A' }</div>
                                            </div>
                                        </div>
                                        <div className="font-bold">@{ currentUserDetail.username }</div>
                                    </div>
                                    <div className="w-1/3">
                                        <div className="font-bold italic text-3xl text-center">VS</div>
                                    </div>
                                    <div className="w-1/3 flex flex-col items-center">
                                        <div className="mb-4 relative flex justify-center">
                                            { matchOpponent.picture ? <img className="border-4 rounded-md w-16 h-16 object-cover" src={matchOpponent.picture} /> : <UserIcon className="w-16 h-16" /> }
                                            <div className="bg-white text-black flex w-fit absolute rounded flex items-center m-auto bottom-0 p-1 gap-1 card-item-cred">
                                                <StarIcon />
                                                <div className="leading-none text-sm font-bold">{ matchOpponent.profile.match_cred?matchOpponent.profile.match_cred:'N/A' }</div>
                                            </div>
                                        </div>
                                        <div className="font-bold">@{ matchOpponent.username }</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='mt-4'>
                            <div className='flex items-start justify-between gap-3'>
                                <label className='text-sm text-neutral-400'>We've sent invites to your opponents. You can check the status of the invitations in the 'Sent' tab of your home screen.</label>
                            </div>
                        </div>
                        <div className='mt-24'>
                            <div className="flex items-center gap-3">
                                <Link
                                to='/matches'
                                 
                                className="bg-white text-sm text-black p-3 w-full rounded-md border-2 border-white uppercase hover:drop-shadow-xl text-center"
                                >
                                    <div className='font-bold'>HOME</div>
                                </Link>
                            </div>
                        </div>
                        <div className='mt-4'>
                            <div className="flex items-center justify-center gap-2">
                                <div className='w-2 h-2 rounded bg-white'></div>
                                <div className='w-2 h-2 rounded bg-white'></div>
                                <div className='w-2 h-2 rounded bg-white'></div>
                                <div className='w-2 h-2 rounded bg-white'></div>
                            </div>
                        </div>
                    </>
                }
                <ToastContainer />
            </div>
        </div>
    )
}

export default NewMatch