import React from "react";
import { ReactComponent as WinnerIcon } from '../assets/icons/icon-winner.svg'
import { ReactComponent as UserIcon } from '../assets/icons/icon-user.svg'
import { numberWithCommas } from '../config/common';
import moment from "moment";

const Feed = ({item}) => {

    return (
        <div className="feed mb-3">
            <div className='bg-white rounded-t-xl border-none'>
                <div className="leading-tight italic font-bold uppercase text-black px-3 pt-2 pb-1 truncate">{item.title}</div>
            </div>
            <div className="bg-neutral-800 p-2 md:p-3 text-white rounded-b-xl">
                <div className="flex justify-center w-full gap-2 md:gap-4">
                    <div className="flex items-start w-1/4 basis-1/4 lg:w-1/5">
                        { item.from_user?.picture ? <img className={`border-[3px] ${item.choice_from_user == 0 && item.choice_to_user == 0 ? 'border-neutral-400 z-10' : '' } rounded-md object-cover w-12 h-12 md:w-14 md:h-14`} src={item.from_user?.picture} /> : <UserIcon className="w-12 h-12 md:w-14 md:h-14" /> }
                        { item.to_user?.picture ? <img className={`border-[3px] ${item.choice_from_user == 1 && item.choice_to_user == 1 ? 'border-neutral-400 z-10' : '' } rounded-md object-cover w-12 h-12 md:w-14 md:h-14 ml-[-20px]`} src={item.to_user?.picture} /> : <UserIcon className="w-12 h-12 md:w-14 md:h-14 ml-[-10px]" /> }
                    </div>
                    <div className="flex flex-col w-3/4 basis-3/4 lg:w-4/5">
                        <div className="flex gap-3 leading-none">
                            <div className="font-bold text-sm md:text-md flex items-center gap-1 truncate">{ item.choice_from_user == 0 && item.choice_to_user == 0 && <WinnerIcon />}{item.from_user.full_name}</div>
                            <div className="font-bold text-sm md:text-md text-neutral-400">VS</div>
                            <div className="font-bold text-sm md:text-md flex items-center gap-1 truncate">{ item.choice_from_user == 1 && item.choice_to_user == 1 && <WinnerIcon />}{item.to_user.full_name}</div>
                        </div>
                        <div className="font-bold italic uppercase text-sm">{item.match_type?.title}</div>
                        <div className="flex justify-between">
                            <div className="italic text-neutral-500 text-sm">{moment(item.ended).format('ll')}</div>
                            { item.choice_from_user == 2 && item.choice_to_user == 2 && <div className="font-bold uppercase text-sm">(Tie)</div> }
                            <div className="font-bold leading-none">{numberWithCommas((item.from_user_amount*1 + item.to_user_amount*1))} ETH</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Feed