import React from 'react'
import { useStateContext } from '../contexts/ContextProvider'
import { Link, NavLink } from 'react-router-dom'
import { MdOutlineCancel } from 'react-icons/md'
import { TooltipComponent } from '@syncfusion/ej2-react-popups'
import { links } from '../config/setting'
import { ReactComponent as AppLogo } from '../assets/icons/MoneyMatch-Logo.svg'
import { ReactComponent as SettingsIcon } from '../assets/icons/icon-settings.svg'
import { ReactComponent as UserIcon } from '../assets/icons/icon-user.svg'

const Sidebar = () => {
    const  { currentColor, setActiveMenu, activeMenu, screenSize, setAuthToken } = useStateContext()

    const handleCloseSidebar = () => {
        if (activeMenu !== undefined && screenSize <= 900){
            setActiveMenu(false)
        }
    }

    const handleLogout = () => {
        setAuthToken('')
    }

    const activeLink = 'flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-md text-white text-md font-bold m-2'
    const normalLink =  'flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-md text-md italic text-gray-700 dark:text-default-gray dark:hover:text-white hover:bg-black m-2'
    const specialLink =  'flex items-center gap-5 pl-4 pt-2.5 pb-1.5 rounded-md text-md italic text-gray-700 dark:text-default-gray dark:hover:text-white hover:bg-black m-2 border-4 border-black special-nav-item'
  
    return (
    <div className='ml-2 mr-2 h-screen md:overflow-hidden overflow-auto md:hover:overflow-auto pb-4'>
        {activeMenu && (
            <div className='flex flex-col h-full'>
                <div className="flex justify-center items-center">
                    <Link to='/matches'   onClick={handleCloseSidebar} className='w-44 items-center gap-3 mt-6 mb-6 flex text-xl font-extrabold tracking-tight dark:text-white text-slate-900'>
                        <AppLogo className='w-full h-auto' />
                    </Link>
                    <TooltipComponent content='menu' postion='bottonCenter'>
                        <button
                        type='button'
                        onClick={() => setActiveMenu(!activeMenu)}
                        style={{color:currentColor}}
                        className='text-xl rounded-full p-3 hover:bg-light-gray block md:hidden absolute top-0 right-0'
                        >
                            <MdOutlineCancel/>
                        </button>
                    </TooltipComponent>
                </div>
                <div className="flex flex-col flex-1">
                    <div className="mt-10">
                        {links.map((link) => 
                            <div key={link.name}>
                                <NavLink
                                to={`/${link.name?.replace(/\s+/g, '-').toLowerCase()}`}
                                key={link.name}
                                onClick={handleCloseSidebar}
                                style={({ isActive}) => 
                                    ({ backgroundColor: isActive ? currentColor : ''})}
                                className={({ isActive }) => (isActive ? activeLink : (link.special ? specialLink : normalLink))}
                                >
                                    {link.icon}
                                    <span className='uppercase'>{link.name}</span>
                                </NavLink>
                            </div>
                        )}
                    </div>
                    <div className="mt-auto mb-0">
                        <div>
                            <NavLink
                                to={`/settings`}
                                key="settings"
                                onClick={handleCloseSidebar}
                                style={({ isActive}) => 
                                    ({ backgroundColor: isActive ? currentColor : ''})}
                                className={({ isActive }) => (isActive ? activeLink : normalLink)}
                                >
                                    <SettingsIcon />
                                    <span className='uppercase'>Settings</span>
                            </NavLink>
                            <NavLink
                                to={`/`}
                                key="logout"
                                onClick={handleLogout}
                                style={({ isActive}) => 
                                    ({ backgroundColor: isActive ? currentColor : ''})}
                                className={({ isActive }) => (isActive ? activeLink : normalLink)}
                                >
                                    <UserIcon />
                                    <span className='uppercase'>Log Out</span>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
        )}
    </div>
  )
}

export default Sidebar