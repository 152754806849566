import React from "react";
import { Link } from 'react-router-dom'
import { ReactComponent as StarIcon } from '../assets/icons/icon-star.svg'
import { ReactComponent as Dots3Icon } from '../assets/icons/icon-3dots.svg'
import { ReactComponent as UserIcon } from '../assets/icons/icon-user.svg'

const Opponent = ({item, is_friend, is_requested, is_searched, handleChallenge, handleAddFriend, handleFriendAccept, handleFriendDecline}) => {

    return (
        <div className="friend-item">
            <div className="flex py-2 items-center justify-between" is_requested={`11`+is_requested} is_friend={`22`+is_friend}>
                <div className="flex items-center justify-start gap-4">
                    <div onClick={() => handleChallenge(item.id)} className="relative flex justify-center cursor-pointer">
                        { item.picture ? <img className="border-4 rounded-md w-16 h-16 object-cover" src={item.picture} /> : <UserIcon className="w-16 h-16" /> }
                        <div className="bg-white text-black flex w-fit absolute rounded flex items-center m-auto bottom-0 p-1 gap-1 friend-item-cred">
                            <StarIcon />
                            <div className="leading-none text-sm font-bold">{item.profile?.match_cred?item.profile?.match_cred:'N/A'}</div>
                        </div>
                    </div>
                    <div>
                        <div onClick={() => handleChallenge(item.id)} className="font-medium cursor-pointer">{item.full_name}</div>
                        <div onClick={() => handleChallenge(item.id)} className="font-medium text-xs mb-1 cursor-pointer">@{item.username}</div>
                        { 
                            is_requested == false && 
                            <div className="flex items-center gap-2">
                                <div className="bg-neutral-800 px-2 rounded text-neutral-400 font-bold text-sm">{item.profile?.num_wins && item.profile?.num_wins != "" && item.profile?.num_wins != null ? 'W' + item.profile?.num_wins: '-'}</div>
                                <div className="bg-neutral-800 px-2 rounded text-neutral-400 font-bold text-sm">{item.profile?.num_losses && item.profile?.num_losses != "" && item.profile?.num_losses != null ? 'L' + item.profile?.num_losses: '-'}</div>
                                <div className="bg-neutral-800 px-2 rounded text-neutral-400 font-bold text-sm">{item.profile?.num_ties && item.profile?.num_ties != "" && item.profile?.num_ties != null ? 'T' + item.profile?.num_ties: '-'}</div>
                            </div>
                        }
                    </div>
                </div>
                <div className="flex items-center justify-end gap-2">
                    {   (is_requested == null || is_requested == false) &&
                        is_friend == true &&
                            <button onClick={() => handleChallenge(item.id)}
                            type='button'
                            className="bg-white text-black font-medium py-1 px-3 rounded-md uppercase text-sm"
                            >
                                Challenge
                            </button>
                    }
                    {
                        (is_requested == null || is_requested == false) &&
                        (is_friend == null || is_friend == false) &&
                        <div className="my-1">
                            <button onClick={() => handleAddFriend(item.id)}
                            type='button'
                            className="bg-white text-black font-medium py-1 px-3 rounded-md uppercase text-sm"
                            >
                                Add friend
                            </button>
                        </div>
                    }
                    { 
                        is_requested == true && 
                        <div className="flex items-center gap-2">
                            <button onClick={() => handleFriendDecline(item.id)}
                            type='button'
                            className="text-white font-medium px-3 rounded-md border-2 border-white uppercase text-sm hover:drop-shadow-xl"
                            >
                                Decline
                            </button>
                            <button onClick={() => handleFriendAccept(item.id)}
                            type='button'
                            className="bg-white text-black font-medium px-3 rounded-md border-2 border-white uppercase text-sm hover:drop-shadow-xl"
                            >
                                Accept
                            </button>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default Opponent