import React from 'react';

import { ReactComponent as HomeIcon } from '../assets/icons/icon-home.svg'
import { ReactComponent as FriendsIcon } from '../assets/icons/icon-friends.svg'
import { ReactComponent as FeedIcon } from '../assets/icons/icon-feed.svg'
import { ReactComponent as WalletIcon } from '../assets/icons/icon-wallet.svg'
import { ReactComponent as VSIcon } from '../assets/icons/icon-vs.svg'
import {ReactComponent as GamesIcon} from '../assets/icons/icon-game.svg'

export const links = [
  {
    name: 'matches',
    icon: <HomeIcon />,
  },
  {
    name: 'friends',
    icon: <FriendsIcon />,
  },
  {
    name: 'feed',
    icon: <FeedIcon />,
  },
  {
    name: 'wallet',
    icon: <WalletIcon />,
  },
  {
    name: 'new match',
    icon: <VSIcon />,
    special: true
  },
  // {
  //   name: 'games',
  //   icon: <GamesIcon />
  // }
];

export const themeColors = [
  {
    name: 'black-theme',
    color: '#000000',
  },
  {
    name: 'blue-theme',
    color: '#1A97F5',
  },
  {
    name: 'green-theme',
    color: '#03C9D7',
  },
  {
    name: 'purple-theme',
    color: '#7352FF',
  },
  {
    name: 'red-theme',
    color: '#FF5C8E',
  },
  {
    name: 'indigo-theme',
    color: '#1E4DB7',
  },
  {
    color: '#FB9678',
    name: 'orange-theme',
  },
];
