import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Feed from '../components/Feed';
import { useStateContext } from '../contexts/ContextProvider';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import Pagination from "../components/Pagination";

const Feeds = () => {

    let navigate = useNavigate();

    const apiUrl = process.env.REACT_APP_API_URL;
    let user = null;
    
    const { authToken } = useStateContext();

    const [feedData, setFeedData] = useState([]);
    const [isEmpty, setIsEmpty] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(15);

    useEffect(() => {
        
        if(authToken) {
            axios.defaults.headers.common["Authorization"] = "Bearer " + authToken;
            user = jwtDecode(authToken);
                
            axios.get(apiUrl + '/matches_feed?user_id='+user.id)
            .then((response) => {
                if(response.data && response.data.success == true && response.data.data.length > 0) {
                    setFeedData(response.data.data);
                } else {
                    setIsEmpty(true);
                }
            })
            .catch((error) => console.log('API error - ', error))
        } else {
            delete axios.defaults.headers.common["Authorization"];
            navigate('/login'); 
        }

    }, [])
    
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = feedData.slice(indexOfFirstItem, indexOfLastItem);

    const handlePagination = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return (
        <div className='m-2 mt-20 md:m-8'>
            <div className='max-w-lg m-auto text-white'>
                <div className='font-bold text-3xl mb-1'>Feed</div>
                { 
                    feedData && feedData.length > 0 &&
                    <div className='pt-8'>
                        <div>
                            {currentItems.map((item, index) => (
                                <Feed item={item} key={`feed-${index}`} />
                            ))}
                        </div>
                        <div>
                            <Pagination
                                length={feedData.length}
                                itemsPerPage={itemsPerPage}
                                currentPage={currentPage}
                                handlePagination={handlePagination}
                            />
                        </div>
                    </div>
                }
                {feedData.length == 0 && isEmpty && <div className="text-white my-8">No matches feed</div>}
            </div>
        </div>
    )
}

export default Feeds