import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { ReactComponent as BackIcon } from '../assets/icons/icon-back.svg'
import { ReactComponent as StarIcon } from '../assets/icons/icon-star.svg'
import { ReactComponent as ArrowRightIcon } from '../assets/icons/icon-arrow-right.svg'

const Faq = () => {

    let navigate = useNavigate();
    const [faqItem, setFaqItem] = useState(0); 

    return (
        <div className='m-2 mt-20 md:m-8'>
            <div className='max-w-lg m-auto text-white'>
                {
                    faqItem == 0 &&
                    <button onClick={()=>navigate(-1)} className='mb-3'>
                        <BackIcon />
                    </button>
                }
                {
                    faqItem > 0 &&
                    <button onClick={()=>setFaqItem(0)} className='mb-3'>
                        <BackIcon />
                    </button>
                }
                <div className="font-bold text-3xl">FAQ</div>
                {
                    faqItem == 0 &&
                    <div className='mt-6 flex flex-col gap-3'>
                        <button
                        onClick={() => setFaqItem(1)}
                        className="flex items-center justify-between gap-3 bg-white text-black px-5 py-3 w-full rounded-md border-2 border-white hover:drop-shadow-xl"
                        >
                            <div className='text-left'>
                                <div className='font-bold'>MatchCred</div>
                                <div className='text-sm text-neutral-500'>Learn about MatchCred and your breakdown</div>
                            </div>
                            <ArrowRightIcon />
                        </button>
                        <button
                        onClick={() => setFaqItem(2)}
                        className="flex items-center justify-between gap-3 bg-white text-black px-5 py-3 w-full rounded-md border-2 border-white hover:drop-shadow-xl"
                        >
                            <div className='text-left'>
                                <div className='font-bold'>Ranking</div>
                                <div className='text-sm text-neutral-500'>Learn about the factors that go into determining your ranking.</div>
                            </div>
                            <ArrowRightIcon />
                        </button>
                        <button
                        onClick={() => setFaqItem(3)}
                        className="flex items-center justify-between gap-3 bg-white text-black px-5 py-3 w-full rounded-md border-2 border-white hover:drop-shadow-xl"
                        >
                            <div className='text-left'>
                                <div className='font-bold'>Disputes</div>
                                <div className='text-sm text-neutral-500'>Learn about what causes disputes and how you can prevent disputed matches.</div>
                            </div>
                            <ArrowRightIcon />
                        </button>
                        <button
                        onClick={() => setFaqItem(4)}
                        className="flex items-center justify-between gap-3 bg-white text-black px-5 py-3 w-full rounded-md border-2 border-white hover:drop-shadow-xl"
                        >
                            <div className='text-left'>
                                <div className='font-bold'>Contests</div>
                                <div className='text-sm text-neutral-500'>Learn about Contests, new features and developments.</div>
                            </div>
                            <ArrowRightIcon />
                        </button>
                        <button
                        onClick={() => setFaqItem(5)}
                        className="flex items-center justify-between gap-3 bg-white text-black px-5 py-3 w-full rounded-md border-2 border-white hover:drop-shadow-xl"
                        >
                            <div className='text-left'>
                                <div className='font-bold'>Privacy Policy</div>
                                <div className='text-sm text-neutral-500'>Learn about your rights and privacy protection adn the data we collect on our users.</div>
                            </div>
                            <ArrowRightIcon />
                        </button>
                        <button
                        onClick={() => setFaqItem(6)}
                        className="flex items-center justify-between gap-3 bg-white text-black px-5 py-3 w-full rounded-md border-2 border-white hover:drop-shadow-xl"
                        >
                            <div className='text-left'>
                                <div className='font-bold'>Community Standards</div>
                                <div className='text-sm text-neutral-500'>Learn about our Terms of Service and user responsibility on the MoneyMatch platform.</div>
                            </div>
                            <ArrowRightIcon />
                        </button>
                    </div>
                }
                {
                    faqItem == 1 &&
                    <div className='mt-6'>
                        <div className='font-bold text-2xl px-4'>MatchCred</div>
                        <div className='text-neutral-300 px-4 mt-4'>The MatchCred is a predictive algorithm which assigns a user a value (from 1 to 5) which establishes their reputation on the app. Abstractly, MatchCred should allow users to get a sense of counterparty risk associated with matching other users. MatchCred scores create accountability, and aim at preventing disputes through mutual cooperation. The MatchCred is continuously updated through user behavior. </div>
                    </div>
                }
                {
                    faqItem == 2 &&
                    <div className='mt-6'>
                        <div className='font-bold text-2xl px-4'>Ranking</div>
                        <div className='text-neutral-300 px-4 mt-4'>A user’s rank represents his/her statistical ability to win matches. Rank extends from 1 (best) to n (# of users with &gt; 3 matches), meaning a rank of 300 means this user is the 300th best user on the app. *Ties to be given an asterisk i.e. 350* means tied for 350th best user. All users’ rankings should be updated every 30 seconds across the entire app. Ranking will be kept internally until a user completes 3 matches, at which point a rating will be displayed publicly.</div>
                    </div>
                }
                {
                    faqItem == 3 &&
                    <div className='mt-6'>
                        <div className='font-bold text-2xl px-4'>Disputes</div>
                        <div className='text-neutral-300 px-4 mt-4'>In the event users fail to reach consensus, the match will be declared a dispute. While we do not arbitrate disputes, in order to encourage honesty and prevent disputes, we will return both user’s funds with a 1.00% fee (max $3.00) of the exposure of the user who initiated the match from both players. Additionally, both user’s MatchCred credibility scores may be affected negatively from repeated instances of disputes. We recommend that users avoid matching users with whom they have disputed in the past or who have low MatchCred credibility scores.</div>
                    </div>
                }
                {
                    faqItem == 4 &&
                    <div className='mt-6'>
                        <div className='font-bold text-2xl px-4'>Contests</div>
                        <div className='text-neutral-300 px-4 mt-4'>Periodically, contests will be announced by the Money Match admin. Those who fulfill the contest criteria will be rewarded on the app, based on the contest terms.</div>
                    </div>
                }
                {
                    faqItem == 5 &&
                    <div className='mt-6'>
                        <div className='font-bold text-2xl px-4'>Privacy Policy</div>
                        <div className='text-neutral-300 px-4 mt-4'>
                            <p className='mb-4'>We at Money Match believe that everyone has the right to use our platform safely and respectfully. To ensure that our community is a place where everyone feels welcome and supported, we have established these community standards. By using our platform, you agree to follow these standards and to respect the rights of others.</p>
                            <ul className='list-decimal mb-8'>
                                <li className='mb-2'>Be respectful of others. Money Match is a place for people to connect and interact with one another. While we encourage healthy debate and discussion, we do not tolerate any form of abuse, harassment, or discrimination. This includes, but is not limited to, derogatory comments based on race, ethnicity, religion, sexual orientation, gender identity, or disability.</li>
                                <li className='mb-2'>Do not engage in any illegal activity. Money Match is not a platform for illegal activity of any kind. This includes, but is not limited to, the buying or selling of illegal goods or services, the sharing of copyrighted material, or the distribution of illegal drugs.</li>
                                <li className='mb-2'>Do not share personal information. Money Match is a platform for connecting with others, but it is not a place to share personal information, such as your home address or phone number. If you choose to share personal information with others, you do so at your own risk.</li>
                                <li className='mb-2'>Do not spam or engage in any deceptive or misleading practices. Money Match is not a platform for spamming or any other deceptive or misleading practices. This includes, but is not limited to, the sharing of links to inappropriate or malicious websites, or the sending of unsolicited messages.</li>
                                <li className='mb-2'>Do not engage in any activity that could compromise the security or integrity of the platform. Money Match is committed to maintaining the security and integrity of our platform. Any activity that could compromise this, such as hacking or tampering with the platform, is strictly prohibited.</li>
                                <li className='mb-2'>Respect the terms of use. In addition to these community standards, you must also abide by the terms of use for Money Match. This includes, but is not limited to, respecting the intellectual property rights of others and not using the platform for any unauthorized or illegal purpose.</li>
                            </ul>
                            <p className='my-4 font-bold'>How the app works:</p>
                            <ul className='list-disc mb-4'>
                                <li className='mb-2'>Users may propose interactions with one another on the Money Match app.</li>
                                <li className='mb-2'>If a proposal is accepted, the interaction will commence.</li>
                                <li className='mb-2'>Upon completion of the interaction, both users will be required to select the outcome and rate each other. This will impact their credibility score.</li>
                                <li className='mb-2'>If both users agree on an outcome, a payment will be facilitated.</li>
                                <li className='mb-2'>If a mutual decision cannot be reached, the payments will be returned to both users with an incomplete fee being retained by the company, Money Match.</li>
                                <li className='mb-2'>If a time limit has been established for the interaction and only one user has selected an outcome, their proposed solution will be accepted.</li>
                                <li className='mb-2'>If no outcome is selected before the time limit expires, the payments will be returned to the users with a fee being retained by the company, Money Match.</li>
                            </ul>
                            <p className='my-8'>We reserve the right to remove any content or terminate any account that violates these community standards or the terms of use. If you have any questions or concerns about these standards or your use of the platform, please do not hesitate to contact us. Thank you for being a part of the Money Match community!</p>
                        </div>
                    </div>
                }
                {
                    faqItem == 6 &&
                    <div className='mt-6'>
                        <div className='font-bold text-2xl px-4'>Community Standards</div>
                        <div className='text-neutral-300 px-4 mt-4'>
                            <p className='mb-4'>Welcome to Money Match!</p>
                            <p className='mb-4'>These Terms of Use (or "Terms") govern your use of Money Match, except where we expressly state that separate terms (and not these) apply, and provide information about the Money Match Service (the "Service"), outlined below. When you create a Money Match account or use Money Match, you agree to these terms. </p>
                            <p className='mb-4'>ARBITRATION NOTICE: YOU AGREE THAT DISPUTES BETWEEN YOU AND MONEY MATCH WILL BE RESOLVED BY BINDING, INDIVIDUAL ARBITRATION AND YOU WAIVE YOUR RIGHT TO PARTICIPATE IN A CLASS ACTION LAWSUIT OR CLASS-WIDE ARBITRATION. WE EXPLAIN SOME EXCEPTIONS AND HOW YOU CAN OPT OUT OF ARBITRATION BELOW. </p>
                            <ul className='list-decimal my-8'>
                                <li className='mb-2'>Introduction: These terms of service (the "Terms") govern your use of the Money Match app, which is a social media platform that allows users to engage with their friends on various topics. By accessing or using the Money Match app, you agree to be bound by these Terms and our Privacy Policy. If you do not agree to these Terms, you may not use the Money Match app.</li>
                                <li className='mb-2'>Acceptance of terms: By accessing or using the Money Match app, you acknowledge that you have read, understood, and agree to be bound by these Terms. If you are using the Money Match app on behalf of an organization, you represent and warrant that you have the authority to bind that organization to these Terms.</li>
                                <li className='mb-2'>Modification of terms: We reserve the right to modify these Terms at any time. If we make material changes to these Terms, we will provide notice through the Money Match app or by other means. Your continued use of the Money Match app after any such changes constitutes your acceptance of the revised Terms.</li>
                                <li className='mb-2'>Account creation: In order to use the Money Match app, you must create an account. To create an account, you must be at least 18 years of age and have a valid email address. You may not use the Money Match app if you have been previously banned or suspended from the app.</li>
                                <li className='mb-2'>User conduct: You are responsible for your use of the Money Match app and any interactions you make through the app. You agree to comply with all applicable laws and regulations in connection with your use of the app and your interactions. You may not use the Money Match app for any illegal or abusive purpose, including for illegal activities or to harass or bully other users.</li>
                                <li className='mb-2'>Intellectual property: The Money Match app and all content and materials included on the app, including text, graphics, logos, images, and software, are the property of Money Match or its licensors and are protected by copyright and trademark laws. You may not use any content or materials on the Money Match app for any commercial purpose without the express written consent of Money Match.</li>
                                <li className='mb-2'>Disclaimer of warranties: The Money Match app is provided "as is," without warranty of any kind, either express or implied. Money Match does not warrant that the Money Match app will be uninterrupted or error-free, and will not be liable for any interruptions or errors.</li>
                                <li className='mb-2'>Data protection: Money Match takes the protection of its users' data seriously. By using the Money Match app, you consent to the collection, use, and sharing of your data in accordance with our Privacy Policy. You acknowledge that Money Match may access, preserve, and disclose your data if required to do so by law or in the good faith belief that such action is necessary to: (1) comply with legal obligations; (2) protect the rights, property, or safety of Money Match, its users, or the public; or (3) enforce these Terms.</li>
                                <li className='mb-2'>Limitation of liability: Money Match will not be liable for any damages of any kind arising from the use of the Money Match app, including but not limited to direct, indirect, incidental, punitive, and consequential damages.</li>
                                <li className='mb-2'>Indemnification: You agree to indemnify and hold Money Match and its affiliates, officers, agents, and employees harmless from any claim or demand, including reasonable attorneys' fees, made by any third party due to or arising out of your use of the Money Match app, your violation of these Terms, or your violation of any rights of another.</li>
                                <li className='mb-2'>Termination: Money Match reserves the right to terminate your account or access to the Money Match app at any time and for any reason, without notice. If your account is terminated, you will no longer be able to access the Money Match app or any wagers you have made through the app.</li>
                                <li className='mb-2'>Governing law: These Terms and your use of the Money Match app will be governed by and construed in accordance with the laws of the State of Delaware, without giving effect to any principles of conflicts of law. As of June 18, 2024, Money Match Technology Company, MMTC, and www.moneymatchalpha.com are for use in alpha testing mode only. Only authorized users may use this site and any unathorized users and users without proper identification are not allowed on the platform. To this end, we have enabled a verification method where only users with a unique (and changing) identifier are able to access the site and make accounts. If we find users without this access code or whom have many an account in violation of this provision, they will be promptly banned from the platform. To repeat: Money Match is not currently a public-facing, production-level product for general consumption but rather a technology project. </li>
                            </ul>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default Faq
