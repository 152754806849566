import React, { useState, useEffect, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { ReactComponent as AppLogoWhite } from '../assets/icons/MoneyMatch-Logo-White.svg'
import { ReactComponent as FacebookIcon } from '../assets/icons/icon-facebook.svg'
import { ReactComponent as GoogleIcon } from '../assets/icons/icon-google.svg'
import { ReactComponent as AppleIcon } from '../assets/icons/icon-apple.svg'
import { ReactComponent as LineIcon } from '../assets/icons/icon-line.svg'

import { useStateContext } from '../contexts/ContextProvider'
import { LoginSocialGoogle, LoginSocialFacebook, LoginSocialApple } from 'reactjs-social-login';

const REDIRECT_URI = "https://moneymatch-frontend-v3-4e2c1171be41.herokuapp.com/";
//const REDIRECT_URI = "http://localhost:3000/";

const Intro = () => {

    const  { setAuthToken } = useStateContext()

    let navigate = useNavigate();

    const [provider, setProvider] = useState('')
    const [profile, setProfile] = useState(null)

    const onLoginStart = useCallback(() => {
        //alert('login start')
    }, [])

    const handleLogin = () => {
        
        console.log(provider);
        console.log(profile);
        
        /*
        axios.post(apiUrl + '/oauth_login', {
            token: token,
            provider: provider,
            name: email,
            name: name,
            device_token: ""
        })
        .then((response) => {
            if(response.data && response.data.success == true) {
                setLoginError('');
                setAuthToken(response.data.data.token);
            } else {
                setLoginError(response.data.msg);
            }
        })
        .catch((error) => console.log('SSO Login error - ', error))
        */
    }

    const onLogoutSuccess = () => {
        setAuthToken('')
    }

    return (
        <div className='m-2 mt-20 md:m-8'>
            <div className='max-w-lg m-auto text-white'>
                <div className='text-center'>
                    <div className="my-32 relative flex justify-center">
                        <AppLogoWhite />
                    </div>
                    
                    <div className='mt-6'>
                        <Link
                        to='/signup'
                         
                        className="flex items-center justify-center gap-3 bg-white text-black mb-3 px-5 py-3 w-full rounded-md border-2 border-white hover:drop-shadow-xl"
                        >
                            <div className='font-bold uppercase'>SIGN UP</div>
                        </Link>
                        <div className='flex items-center justify-center gap-3 text-white my-6'>
                            <LineIcon />
                            <div className='font-bold uppercase'>OR</div>
                            <LineIcon />
                        </div>
                        { /*
                        <button
                        className="flex items-center justify-center gap-3 bg-[#1877F2] text-white mb-3 px-5 py-3 w-full rounded-md border-2 border-[#1877F2] hover:drop-shadow-xl"
                        >
                            <FacebookIcon />
                            <div className='font-bold uppercase'>CONTINUE WITH FACEBOOK</div>
                        </button>
                        <button
                        className="flex items-center justify-center gap-3 bg-white text-black mb-3 px-5 py-3 w-full rounded-md border-2 border-white hover:drop-shadow-xl"
                        >
                            <GoogleIcon />
                            <div className='font-bold uppercase'>CONTINUE WITH GOOGLE</div>
                        </button>
                        <button
                        className="flex items-center justify-center gap-3 bg-white text-black mb-3 px-5 py-3 w-full rounded-md border-2 border-white hover:drop-shadow-xl"
                        >
                            <AppleIcon />
                            <div className='font-bold uppercase'>CONTINUE WITH APPLE</div>
                        </button>
                        */}

                        <LoginSocialFacebook
                            appId={process.env.REACT_APP_FB_APP_ID || ''}
                            fieldsProfile={
                                'id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender'
                            }
                            onLoginStart={onLoginStart}
                            onLogoutSuccess={onLogoutSuccess}
                            redirect_uri={REDIRECT_URI}
                            onResolve={({ provider, data }) => {
                                setProvider(provider);
                                setProfile(data);
                                handleLogin();
                            }}
                            onReject={err => {
                                console.log(err);
                            }}
                        >
                            <button className="flex items-center justify-center gap-3 bg-[#1877F2] text-white mb-3 px-5 py-3 w-full rounded-md border-2 border-[#1877F2] hover:drop-shadow-xl">
                                <FacebookIcon />
                                <div className='font-bold uppercase'>CONTINUE WITH FACEBOOK</div>
                            </button>
                        </LoginSocialFacebook>

                        <LoginSocialGoogle
                            client_id={process.env.REACT_APP_GG_APP_ID || ''}
                            onLoginStart={onLoginStart}
                            redirect_uri={REDIRECT_URI}
                            scope="openid profile email"
                            discoveryDocs="claims_supported"
                            access_type="offline"
                            onResolve={({ provider, data }) => {
                                setProvider(provider);
                                setProfile(data);
                                handleLogin();
                            }}
                            onReject={err => {
                                console.log(err);
                            }}
                        >
                            <button className="flex items-center justify-center gap-3 bg-white text-black mb-3 px-5 py-3 w-full rounded-md border-2 border-white hover:drop-shadow-xl">
                                <GoogleIcon />
                                <div className='font-bold uppercase'>CONTINUE WITH GOOGLE</div>
                            </button>
                        </LoginSocialGoogle>

                        <LoginSocialApple
                            client_id={process.env.REACT_APP_APPLE_ID || ''}
                            scope={'name email'}
                            redirect_uri={REDIRECT_URI}
                            onLoginStart={onLoginStart}
                            onResolve={({ provider, data }) => {
                                setProvider(provider);
                                setProfile(data);
                                handleLogin();
                            }}
                            onReject={err => {
                                console.log(err);
                            }}
                        >
                            <button className="flex items-center justify-center gap-3 bg-white text-black mb-3 px-5 py-3 w-full rounded-md border-2 border-white hover:drop-shadow-xl">
                                <AppleIcon />
                                <div className='font-bold uppercase'>CONTINUE WITH APPLE</div>
                            </button>
                        </LoginSocialApple>

                        <div className="flex items-center justify-center gap-3 text-white mt-6 mb-3">
                            <div className='text-neutral-300'>Already have an account?</div>
                            <Link to='/login' className='font-bold uppercase'>LOG IN</Link>
                        </div>
                    </div>

                    <div className='mt-24'>
                        <div className='text-neutral-300 text-sm'>By Money Match Technology Company</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Intro