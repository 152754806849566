import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import {Rating } from 'react-simple-star-rating'
import { useStateContext } from '../contexts/ContextProvider';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { ToastContainer, Bounce, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { ReactComponent as AwardIcon } from '../assets/icons/icon-award.svg'
import { ReactComponent as UserIcon } from '../assets/icons/icon-user.svg'

const Rate = () => {
    
    let navigate = useNavigate();

    const { authToken } = useStateContext();
    if(!authToken) navigate('/login'); 
    const apiUrl = process.env.REACT_APP_API_URL;
    const user = jwtDecode(authToken);

    const params = useParams();

    const [ratingValue, setRatingValue] = useState(0);
    const [matchData, setMatchData] = useState({}); 
    
    useEffect(() => {
        
        if(authToken) {
            axios.defaults.headers.common["Authorization"] = "Bearer " + authToken;
        } else {
            delete axios.defaults.headers.common["Authorization"];
        }

        axios.get(apiUrl + '/matches/'+params['id'])
        .then((response) => {
            if(response.data && response.data.success == true) {
                setMatchData(response.data.data);
            }
        })
        .catch((error) => console.log('API error - ', error))
        
    }, [])

    const handleRating = (rate) => {
        setRatingValue(rate);
    }

    const handleRatingConfirm = (matchId) => {
        if (!ratingValue) {
            toast('Please fill the rating value.', {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce
            });

            return false;
        }

        if(confirm("Are you sure you want to rate this match?") == true) {
            axios.post(apiUrl + '/match_rating/'+matchId, {user_id: user.id, rating: Math.round(ratingValue/20)})
            .then((response) => {
                if(response.data) {
                    toast(response.data.msg, {
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        transition: Bounce
                    });

                    if(response.data.success == true) {
                        navigate('/matches')
                    }
                }
            })
            .catch((error) => console.log('API error - ', error));
        }
    }

    return (
        <div className='m-2 mt-20 md:m-8'>
            <div className='max-w-lg m-auto text-white'>
                <div className="font-bold text-4xl">MATCH ENDED!</div>
                <div className='text-center mt-8'>
                    <div className='flex justify-between gap-2 bg-white rounded-t-xl border-none'>
                        <div className="text-left text-lg leading-tight italic font-bold uppercase text-black px-4 pt-2 pb-1">{ matchData.title }</div>
                        <div className="basis-24 max-w-24 flex-1 flex shrink-0 card-item-type">{ matchData.match_type && matchData.match_type.image && <img src={matchData.match_type.image} className='w-full object-cover rounded-tr-xl' /> }</div>
                    </div>
                    <div className="relative px-5 py-3 pb-5 text-white bg-neutral-800 rounded-b-xl">
                        <div className="flex items-center justify-center">
                            <div className="w-1/3 flex flex-col items-center">
                                <div className="mb-3 relative flex justify-center">
                                    { matchData.from_user?.picture ? <img className="w-20 h-20 object-cover border-4 rounded-md" src={matchData.from_user?.picture} /> : <UserIcon className="w-20 h-20" /> }
                                    { matchData.choice_from_user == 0 && matchData.choice_to_user == 0 && 
                                        <div className="text-black flex w-fit absolute rounded flex items-center m-auto bottom-0 p-1 gap-1 card-item-cred">
                                            <AwardIcon />
                                        </div>
                                    }
                                </div>
                                <div className="font-bold">{matchData.from_user?.full_name}</div>
                                { matchData.choice_from_user == 0 && matchData.choice_to_user == 0 && <div className="bg-white text-black uppercase leading-none py-1 px-3 rounded font-bold text-sm mt-1">Winner</div> }
                                { matchData.choice_from_user == 1 && matchData.choice_to_user == 1 && <div className="bg-white text-black uppercase leading-none py-1 px-3 rounded font-bold text-sm mt-1 opacity-0">Losser</div> }
                            </div>
                            <div className="w-1/3">
                                <div className="font-bold italic text-3xl text-center">VS</div>
                                { matchData.choice_from_user == 2 && matchData.choice_to_user == 2 && <div className="bg-white text-black uppercase leading-none py-1 px-3 rounded font-bold text-sm mt-8">Tie</div> }
                                { matchData.choice_from_user != matchData.choice_to_user && <div className="bg-white text-black uppercase leading-none py-1 px-3 rounded font-bold text-sm mt-8">Dispute</div> }
                            </div>
                            <div className="w-1/3 flex flex-col items-center">
                                <div className="mb-3 relative flex justify-center">
                                    { matchData.to_user?.picture ? <img className="w-20 h-20 object-cover border-4 rounded-md" src={matchData.to_user?.picture} /> : <UserIcon className="w-20 h-20" /> }
                                    { matchData.choice_from_user == 1 && matchData.choice_to_user == 1 && 
                                        <div className="text-black flex w-fit absolute rounded flex items-center m-auto bottom-0 p-1 gap-1 card-item-cred">
                                            <AwardIcon />
                                        </div>
                                    }
                                </div>
                                <div className="font-bold">{matchData.to_user?.full_name}</div>
                                { matchData.choice_from_user == 1 && matchData.choice_to_user == 1 && <div className="bg-white text-black uppercase leading-none py-1 px-3 rounded font-bold text-sm mt-1">Winner</div> }
                                { matchData.choice_from_user == 0 && matchData.choice_to_user == 0 && <div className="bg-white text-black uppercase leading-none py-1 px-3 rounded font-bold text-sm mt-1 opacity-0">Losser</div> }
                            </div>
                        </div>
                    </div>
                    <div className='mt-12 text-center'>
                        <div className='uppercase font-bold text-lg'>Rate the match</div>
                        <div className='mt-4 border-1 border-neutral-400 rounded p-3'>
                            <Rating onClick={handleRating} />
                        </div>
                    </div>
                    <div className="relative mt-24">
                        <div className="flex items-center">
                            <button onClick={() => handleRatingConfirm(matchData.id)}
                            className="bg-white text-black font-bold p-3 w-full rounded-md border-2 border-white uppercase hover:drop-shadow-xl"
                            >
                                Confirm
                            </button>
                        </div>
                        <div className="flex items-center mt-3">
                            <Link
                            to='/matches'
                             
                            className="bg-white text-black font-bold p-3 w-full rounded-md border-2 border-white uppercase hover:drop-shadow-xl"
                            >
                                SKIP
                            </Link>
                        </div>
                    </div>
                </div>
                <ToastContainer />
            </div>
        </div>
    )
}

export default Rate