import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useStateContext } from '../contexts/ContextProvider';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { ToastContainer, Bounce, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { ReactComponent as BackIcon } from '../assets/icons/icon-back.svg'
import { ReactComponent as EyeIcon } from '../assets/icons/icon-eye.svg'

const Privacy = () => {

    let navigate = useNavigate();

    const { authToken } = useStateContext();
    if(!authToken) navigate('/login'); 
    const apiUrl = process.env.REACT_APP_API_URL;
    const user = jwtDecode(authToken);
    
    const [statistics, setStatistics] = useState(1); 
    const [history, setHistory] = useState(2); 
    const [amounts, setAmounts] = useState(0); 
    const [disputes, setDisputes] = useState(0); 

    useEffect(() => {
        
        if(authToken) {
            axios.defaults.headers.common["Authorization"] = "Bearer " + authToken;
        } else {
            delete axios.defaults.headers.common["Authorization"];
        }

        axios.get(apiUrl + '/privacy_settings/'+user.id)
        .then((response) => {
            if(response.data && response.data.success == true && response.data.data) {
                setStatistics(response.data.data.statistics);
                setHistory(response.data.data.history);
                setAmounts(response.data.data.amounts);
                setDisputes(response.data.data.disputes);
            } else {
                axios.post(apiUrl + '/privacy_settings/', {"user_id": user.id, "statistics": 1, "history": 2, "amounts": 0, "disputes": 0})
                .then((response) => {
                    if(response.data && response.data.success == true && response.data.data) {
                        setStatistics(response.data.data.statistics);
                        setHistory(response.data.data.history);
                        setAmounts(response.data.data.amounts);
                        setDisputes(response.data.data.disputes);
                    }
                })
            }
        })

	}, []);

    const handleSave = () => {
        axios.put(apiUrl + '/privacy_settings/'+user.id, 
            {
                "statistics": statistics,
                "history": history,
                "amounts": amounts,
                "disputes": disputes,
            }
        )
        .then((response) => {
            if(response.data) {
                toast(response.data.msg, {
                    position: "top-right",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce
                });
            }
        })
        .catch((error) => console.log('API error - ', error));
    }
    
    return (
        <div className='m-2 mt-20 md:m-8'>
            <div className='max-w-lg m-auto text-white'>
                <button onClick={()=>navigate(-1)} className='mb-3'>
                    <BackIcon />
                </button>
                <div className="font-bold text-3xl">Privacy Settings</div>
                <div className='mt-6'>
                    <div className='flex items-center justify-between gap-3'>
                        <div className=''>
                            <div className='font-bold'>Display Statistics</div>
                            <div className='text-sm'>Display your Win / Loss / Tie against specific users on your profile page.</div>
                        </div>
                        <select className='text-white font-bold border-none h-10 p-2 pr-10 text-right bg-transparent' value={statistics} onChange={(e) => setStatistics(e.target.value)}>
                            <option value={1} className='text-black'>ALWAYS</option>
                            <option value={2} className='text-black'>FRIENDS ONLY</option>
                            <option value={0} className='text-black'>NEVER</option>
                        </select>
                    </div>
                </div>
                <div className='mt-6'>
                    <div className='flex items-center justify-between gap-3'>
                        <div className=''>
                            <div className='font-bold'>Match History</div>
                            <div className='text-sm'>Display your Match History on your profile page.</div>
                        </div>
                        <select disabled className='text-white font-bold border-none h-10 p-2 pr-10 text-right bg-transparent' value={history} onChange={(e) => setHistory(e.target.value)}>
                            <option value={1} className='text-black'>ALWAYS</option>
                            <option value={2} className='text-black'>FRIENDS ONLY</option>
                            <option value={0} className='text-black'>NEVER</option>
                        </select>
                    </div>
                </div>
                <div className='mt-6'>
                    <div className='flex items-center justify-between gap-3'>
                        <div className=''>
                            <div className='font-bold'>Match Amounts</div>
                            <div className='text-sm'>Display amount won and lost per match in your match history on your profile page.</div>
                        </div>
                        <select disabled className='text-white font-bold border-none h-10 p-2 pr-10 text-right bg-transparent' value={amounts} onChange={(e) => setAmounts(e.target.value)}>
                            <option value={1} className='text-black'>ALWAYS</option>
                            <option value={2} className='text-black'>FRIENDS ONLY</option>
                            <option value={0} className='text-black'>NEVER</option>
                        </select>
                    </div>
                </div>
                <div className='mt-6'>
                    <div className='flex items-center justify-between gap-3'>
                        <div className=''>
                            <div className='font-bold'>Display Disputes</div>
                            <div className='text-sm'>Display your disputes in your match history on your profile page.</div>
                        </div>
                        <select disabled className='text-white font-bold border-none h-10 p-2 pr-10 text-right bg-transparent' value={disputes} onChange={(e) => setDisputes(e.target.value)}>
                            <option value={1} className='text-black'>ALWAYS</option>
                            <option value={2} className='text-black'>FRIENDS ONLY</option>
                            <option value={0} className='text-black'>NEVER</option>
                        </select>
                    </div>
                </div>
                <div className='mt-8'>
                    <div className="flex items-center gap-3">
                        <button onClick={() => handleSave()}
                        className="bg-white text-sm text-black p-3 w-full rounded-md border-2 border-white uppercase hover:drop-shadow-xl text-center"
                        >
                            <div className='font-bold'>Save Changes</div>
                        </button>
                    </div>
                </div>
                <ToastContainer />
            </div>
        </div>
    )
}

export default Privacy