import React from "react";
import { ReactComponent as RaiseIcon } from '../assets/icons/icon-arrow-top.svg'
import { ReactComponent as LostIcon } from '../assets/icons/icon-arrow-bottom-2.svg'
import { ReactComponent as Star3Icon } from '../assets/icons/icon-star3.svg'
import { ReactComponent as UserIcon } from '../assets/icons/icon-user.svg'
import { Link } from "react-router-dom";
import moment from "moment";

const History = ({item, user_id}) => {

    var userPhotoURL = item.from_user.picture;
    var userLinkID = item.from_user.id;

    if (item.from_user.id==user_id) {
        userPhotoURL = item.to_user.picture;
        userLinkID = item.to_user.id
    }

    return (
        <div className="history-item">
            <Link to={`/matches/`+ item.id} className="flex items-end justify-between gap-3 bg-neutral-800 p-2 mb-3 rounded-xl">
                <div className="flex items-start justify-start gap-3">
                    <Link to={`/friends/`+ userLinkID}>
                        { userPhotoURL ? <img className="border-[3px] border-neutral-400 rounded-md object-cover w-14 h-14" src={userPhotoURL} /> : <UserIcon className="w-14 h-14" /> }
                    </Link>
                    <div>
                        <div className="flex gap-2">
                            <div className="font-bold text-sm md:text-md text-neutral-400">VS</div>
                            <div className="font-bold text-sm md:text-md flex items-center gap-1">{item.from_user.id==user_id?item.to_user.full_name:item.from_user.full_name}</div>
                        </div>
                        <div className="font-bold italic text-sm md:text-md flex items-center gap-1 uppercase">{item.title}</div>
                        <div className="italic text-neutral-500 text-sm">{moment(item.ended).format('L')}</div>
                    </div>
                </div>
                <div className="text-right pr-3">
                    <div className="flex items-center justify-end gap-1">
                        <div className="text-sm leading-5">{item.choice_from_user == item.choice_to_user ? '' : '(Dispute)'}</div>
                    </div>
                    <div className="flex items-center justify-end gap-1">
                        {item.choice_from_user == item.choice_to_user ? ( ((item.choice_from_user == 0 && item.from_user.id==user_id) || (item.choice_from_user == 1 && item.to_user.id==user_id)) ? <RaiseIcon /> : <LostIcon /> ) : <RaiseIcon /> }
                        <div className="font-bold text-lg leading-5">{item.choice_from_user == item.choice_to_user ? ( ((item.choice_from_user == 0 && item.from_user.id==user_id) || (item.choice_from_user == 1 && item.to_user.id==user_id)) ? Math.round(item.to_user_amount * 0.97 * 10000000)/10000000 : 0 ) : (item.from_user.id==user_id ? Math.round(item.from_user_amount * 0.9 * 10000000)/10000000 : Math.round(item.to_user_amount * 0.9 * 10000000)/10000000)} ETH</div>
                    </div>
                    { item.from_user.id == user_id && item.rating_to_user && 
                        <div className="flex items-center justify-end gap-1">
                            <Star3Icon />
                            <div className="text-sm">{item.rating_to_user}</div>
                        </div>
                    }
                    { item.to_user.id == user_id && item.rating_from_user && 
                        <div className="flex items-center justify-end gap-1">
                            <Star3Icon />
                            <div className="text-sm">{item.rating_from_user}</div>
                        </div>
                    }
                </div>
            </Link>
        </div>
    );
}

export default History