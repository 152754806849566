import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useStateContext } from '../contexts/ContextProvider';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { ToastContainer, Bounce, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';

import { ReactComponent as BackIcon } from '../assets/icons/icon-back.svg'
import { ReactComponent as StarIcon } from '../assets/icons/icon-star.svg'
import { ReactComponent as WinsIcon } from '../assets/icons/icon-wins.svg'
import { ReactComponent as LossesIcon } from '../assets/icons/icon-losses.svg'
import { ReactComponent as RankIcon } from '../assets/icons/icon-rank.svg'
import { ReactComponent as Star2Icon } from '../assets/icons/icon-star2.svg'
import { ReactComponent as UserIcon } from '../assets/icons/icon-user.svg'

const FriendDetail = () => {

    let navigate = useNavigate();

    const { authToken } = useStateContext();
    if(!authToken) navigate('/login'); 
    const apiUrl = process.env.REACT_APP_API_URL;
    const user = jwtDecode(authToken);

    const params = useParams();

    const [isFriend, setIsFriend] = useState(false);
    const [friendData, setFriendData] = useState([]);
    const [rank, setRank] = useState('');
    const [againstData, setAgainstData] = useState({});

    useEffect(() => {
        
        if(authToken) {
            axios.defaults.headers.common["Authorization"] = "Bearer " + authToken;
        } else {
            delete axios.defaults.headers.common["Authorization"];
        }

        axios.get(apiUrl + '/friends/'+user.id+'/'+params['id'])
        .then((response) => {
            if(response.data && response.data.success == true) {
                if (response.data.data) setIsFriend(true);
            }
        })
        .catch((error) => console.log('API error - ', error))

        axios.get(apiUrl + '/users/'+params['id'])
        .then((response) => {
            if(response.data && response.data.success == true) {
                setFriendData(response.data.data);
                setRank(response.data.rank);
            }
        })
        .catch((error) => console.log('API error - ', error))

        axios.get(apiUrl + '/friends_recording_against?user_id='+user.id+'&friend_user_id='+params['id'])
        .then((response) => {
            if(response.data && response.data.success == true) {
                //console.log(response.data.data);
                setAgainstData(response.data.data);
            }
        })
        .catch((error) => console.log('API error - ', error))
        
    }, [])

    const handleAddFriend = (friend_user_id) => {
        if(confirm("Are you sure you want to send a friend request to this friend?") == true) {
            axios.post(apiUrl + '/friends', {from_user_id: user.id, to_user_id: friend_user_id, status: 0, created: moment()})
            .then((response) => {
                if(response.data) {
                    toast(response.data.msg, {
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        transition: Bounce
                    });

                    if(response.data.success == true) {
                        navigate('/friends')
                    }
                }
            })
            .catch((error) => console.log('API error - ', error));
        }
    }

    const handleFriendRemove = () => {
        if(confirm("Are you sure you want to remove this friend?") == true) {
            axios.delete(apiUrl + '/friends_by_user_id/'+user.id+'/'+params['id'])
            .then((response) => {
                if(response.data) {
                    toast(response.data.msg, {
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        transition: Bounce
                    });

                    if(response.data.success == true) {
                        navigate('/friends')
                    }
                }
            })
            .catch((error) => console.log('API error - ', error));
        }
    }

    return (
        <div className='m-2 mt-20 md:m-8'>
            <div className='max-w-lg m-auto text-white'>
                <button onClick={()=>navigate(-1)} className='mb-3'>
                    <BackIcon />
                </button>
                <div className='text-center'>
                    <div className="mb-4 relative flex justify-center">
                        { friendData.picture ? <img className="border-4 rounded-md w-24 h-24 object-cover" src={friendData.picture} /> : <UserIcon className="w-24 h-24" /> }
                        <div className="bg-white text-black flex w-fit absolute rounded flex items-center m-auto bottom-0 p-1 gap-1 card-item-cred">
                            <StarIcon />
                            <div className="leading-none text-sm font-bold">{friendData.profile?.match_cred?friendData.profile?.match_cred:'N/A'}</div>
                        </div>
                    </div>
                    <div className="font-bold text-3xl">{friendData.full_name}</div>
                    <div className="text-neutral-500">@{friendData.username}</div>
                    <div className='bg-neutral-800 rounded-xl p-4 mt-4'>
                        { (friendData.privacy_setting == null || (friendData.privacy_setting != null && (friendData.privacy_setting?.statistics == 1 || (friendData.privacy_setting?.statistics == 2 && isFriend)))) &&
                            <div className='flex justify-center border-b-2 border-neutral-600 mb-3 pb-3'>
                                <div className='w-1/4'>
                                    <WinsIcon className='m-auto' />
                                    <div className='text-2xl font-bold mt-2'>{friendData.profile?.num_wins?friendData.profile?.num_wins:'-'}</div>
                                    <div className='italic text-sm text-neutral-400 uppercase'>Wins</div>
                                </div>
                                <div className='w-1/4'>
                                    <LossesIcon className='m-auto' />
                                    <div className='text-2xl font-bold mt-2'>{friendData.profile?.num_losses?friendData.profile?.num_losses:'-'}</div>
                                    <div className='italic text-sm text-neutral-400 uppercase'>Losses</div>
                                </div>
                                <div className='w-1/4'>
                                    <RankIcon className='m-auto' />
                                    <div className='text-2xl font-bold mt-2'>{rank?rank:'-'}</div>
                                    <div className='italic text-sm text-neutral-400 uppercase'>Rank</div>
                                </div>
                                <div className='w-1/4'>
                                    <Star2Icon className='m-auto' />
                                    <div className='text-2xl font-bold mt-2'>{friendData.profile?.match_cred?friendData.profile?.match_cred:'N/A'}</div>
                                    <div className='italic text-sm text-neutral-400 uppercase'>Matchcred</div>
                                </div>
                            </div>
                        }
                        <div className=''>
                            <div className='uppercase font-bold text-lg'>RECORD AGAINST</div>
                            <div className='flex justify-center gap-3 mt-3'>
                                <div className='w-1/3 bg-neutral-700 rounded-lg p-1'>
                                    <div className='text-lg font-bold'>{againstData.wins?againstData.wins:0}</div>
                                    <div className='italic text-sm text-neutral-400 uppercase'>Wins</div>
                                </div>
                                <div className='w-1/3 bg-neutral-700 rounded-lg p-1'>
                                    <div className='text-lg font-bold'>{againstData.losses?againstData.losses:0}</div>
                                    <div className='italic text-sm text-neutral-400 uppercase'>Losses</div>
                                </div>
                                <div className='w-1/3 bg-neutral-700 rounded-lg p-1'>
                                    <div className='text-lg font-bold'>{againstData.ties?againstData.ties:0}</div>
                                    <div className='italic text-sm text-neutral-400 uppercase'>Ties</div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    { /* <div className='font-medium mt-2'>Friends since 2022-09-16</div> */ }
                    <div className='mt-32'>
                        <div className="flex items-center gap-2">
                            { isFriend &&
                                <button onClick={handleFriendRemove}
                                className="text-white font-bold p-3 w-full rounded-md border-2 border-white uppercase hover:drop-shadow-xl hover:bg-white hover:border-white hover:text-black"
                                >
                                    Remove friend
                                </button>
                            }
                            { !isFriend &&
                                <button onClick={() => handleAddFriend(params['id'])}
                                className="text-white font-bold p-3 w-full rounded-md border-2 border-white uppercase hover:drop-shadow-xl hover:bg-white hover:border-white hover:text-black"
                                >
                                    Add friend
                                </button>
                            }
                            <Link to={`/new-match`}
                            className="bg-white text-black font-bold p-3 w-full rounded-md border-2 border-white uppercase hover:drop-shadow-xl hover:bg-black hover:text-white hover:border-white"
                            >
                                Challenge
                            </Link>
                        </div>
                    </div>
                </div>
                <ToastContainer />
            </div>
        </div>
    )
}

export default FriendDetail