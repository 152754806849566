import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as BackIcon } from '../assets/icons/icon-back.svg'
import { useStateContext } from '../contexts/ContextProvider'
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { ToastContainer, Bounce, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Contact = () => {

    const { authToken } = useStateContext();
    if(!authToken) navigate('/login'); 
    const apiUrl = process.env.REACT_APP_API_URL;
    const user = jwtDecode(authToken);

    let navigate = useNavigate();

    const [subject, setSubject] = useState(''); 
    const [message, setMessage] = useState(''); 
    
    const [subjectError, setSubjectError] = useState(false);
    const [messageError, setMessageError] = useState(false); 

    useEffect(() => {
        
        if(authToken) {
            axios.defaults.headers.common["Authorization"] = "Bearer " + authToken;
        } else {
            delete axios.defaults.headers.common["Authorization"];
        }

    }, [])

    const handleSendMessage = () => {
        if (!subject || subject == '') {
            setSubjectError(true);
            return false;
        } else {
            setSubjectError(false);
        }

        if (!message || message == '') {
            setMessageError(true);
            return false;
        } else {
            setMessageError(false);
        }

        axios.post(apiUrl + '/tickets', {
            subject: subject,
            message: message,
            user_id: user?.id
        })
        .then((response) => {
            if(response.data) {
                toast(response.data.msg, {
                    position: "top-right",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce
                });

                if(response.data.success == true) {
                    setSubject('');
                    setMessage('');
                }
            }
        })
        .catch((error) => console.log('API error - ', error))

    }

    return (
        <div className='m-2 mt-20 md:m-8'>
            <div className='max-w-lg m-auto text-white'>
                <button onClick={()=>navigate(-1)} className='mb-3'>
                    <BackIcon />
                </button>
                <div className="font-bold text-3xl">Contact us</div>
                <div className='text-neutral-300 mt-4'>Have a question or inquiry? Send us a message, the support team will respond within 1-3 days.</div>
                <div className='mt-6'>
                    <div className='flex items-center justify-between'>
                        <div className='font-bold'>Subject</div>
                    </div>
                    <div className='relative mt-2'>
                        <input className={`text-white rounded-md border border-neutral-400 h-14 w-full p-3 bg-transparent ${subjectError?'border-red-600':''}`} placeholder='Subject' value={subject} onChange={(event) => setSubject(event.target.value)} />
                        <div className={`text-red-600 text-sm mt-2 ${subjectError?'':'hidden'}`}>This field is required.</div>
                    </div>
                </div>
                <div className='mt-6'>
                    <div className='flex items-center justify-between'>
                        <div className='font-bold'>Your message</div>
                        <div className='text-sm'>{ 750 - message.length }</div>
                    </div>
                    <div className='relative mt-2'>
                        <textarea className={`text-white rounded-md border border-neutral-400 w-full p-3 bg-transparent ${messageError?'border-red-600':''}`} maxLength={750} rows={8} placeholder='Tell us about your inquiry, add as much detail as possible.' value={message} onChange={(event) => setMessage(event.target.value)}></textarea>
                        <div className={`text-red-600 text-sm mt-2 ${messageError?'':'hidden'}`}>This field is required.</div>
                    </div>
                </div>
                <div className='mt-8'>
                    <div className="flex items-center gap-3">
                        <button onClick={() => handleSendMessage()}
                        className="bg-white text-sm text-black p-3 w-full rounded-md border-2 border-white uppercase hover:drop-shadow-xl text-center"
                        >
                            <div className='font-bold'>SEND MESSAGE</div>
                        </button>
                    </div>
                </div>
                <ToastContainer />
            </div>
        </div>
    )
}

export default Contact