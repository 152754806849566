import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useStateContext } from '../contexts/ContextProvider';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { ToastContainer, Bounce, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { ReactComponent as BackIcon } from '../assets/icons/icon-back.svg'
import { ReactComponent as EyeIcon } from '../assets/icons/icon-eye.svg'

const Password = () => {

    let navigate = useNavigate();

    const { authToken } = useStateContext();
    if(!authToken) navigate('/login'); 
    const apiUrl = process.env.REACT_APP_API_URL;
    const user = jwtDecode(authToken);
    
    const [password, setPassword] = useState(''); 
    const [newPassword, setNewPassword] = useState(''); 
    const [repassword, setRepassword] = useState(''); 

    const [passwordView, setPasswordView] = useState(false); 
    
    const [passwordError, setPasswordError] = useState(false); 
    const [newPasswordError, setNewPasswordError] = useState(false); 
    const [repasswordError, setRepasswordError] = useState(false); 
    const [passwordNotMatchError, setPasswordNotMatchError] = useState(false); 

    useEffect(() => {
        
        if(authToken) {
            axios.defaults.headers.common["Authorization"] = "Bearer " + authToken;
        } else {
            delete axios.defaults.headers.common["Authorization"];
        }

	}, []);

    const handleSave = () => {

        if (!password || password == '') {
            setPasswordError(true);
            return false;
        } else {
            setPasswordError(false);
        }

        if (!newPassword || newPassword == '') {
            setNewPasswordError(true);
            return false;
        } else {
            setNewPasswordError(false);
        }

        if (!repassword || repassword == '') {
            setRepasswordError(true);
            return false;
        } else {
            setRepasswordError(false);
        }

        if (newPassword != repassword) {
            setPasswordNotMatchError(true);
            return false;
        } else {
            setPasswordNotMatchError(false);
        }

        axios.put(apiUrl + '/users/'+user.id+'/update_password', 
            {
                "oldPassword": password,
                "newPassword": newPassword,
            }
        )
        .then((response) => {
            if(response.data) {
                toast(response.data.msg, {
                    position: "top-right",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce
                });
            }
        })
        .catch((error) => console.log('API error - ', error));
    }

    return (
        <div className='m-2 mt-20 md:m-8'>
            <div className='max-w-lg m-auto text-white'>
                <button onClick={()=>navigate(-1)} className='mb-3'>
                    <BackIcon />
                </button>
                <div className="font-bold text-3xl">Update Password</div>
                <div className='mt-6'>
                    <div className='flex items-center justify-between'>
                        <div className='font-bold'>Current Password</div>
                    </div>
                    <div className='relative mt-2'>
                        <input type="password" className={`text-white rounded-md border border-neutral-400 h-14 w-full p-3 bg-transparent ${passwordError?'border-red-600':''}`} value={password} onChange={(event) => setPassword(event.target.value)} />
                        <div className={`text-red-600 text-sm mt-2 ${passwordError?'':'hidden'}`}>This field is required.</div>
                    </div>
                </div>
                <div className='mt-6'>
                    <div className='flex items-center justify-between'>
                        <div className='font-bold'>New Password</div>
                    </div>
                    <div className='relative mt-2'>
                        <input type={`${passwordView?'text':'password'}`} className={`text-white rounded-md border border-neutral-400 h-14 w-full p-3 pr-12 bg-transparent ${newPasswordError?'border-red-600':''}`} value={newPassword} onChange={(event) => setNewPassword(event.target.value)} />
                        <button 
                            className='absolute top-0 right-0 p-3 h-14 flex items-center'
                            onClick={() => setPasswordView(!passwordView)}
                        ><EyeIcon /></button>
                        <div className={`text-red-600 text-sm mt-2 ${newPasswordError?'':'hidden'}`}>This field is required.</div>
                    </div>
                </div>
                <div className='mt-6'>
                    <div className='flex items-center justify-between'>
                        <div className='font-bold'>Confirm New Password</div>
                    </div>
                    <div className='relative mt-2'>
                        <input type="password" className={`text-white rounded-md border border-neutral-400 h-14 w-full p-3 bg-transparent ${repasswordError?'border-red-600':''}`} value={repassword} onChange={(event) => setRepassword(event.target.value)} />
                        <div className={`text-red-600 text-sm mt-2 ${repasswordError?'':'hidden'}`}>This field is required.</div>
                        <div className={`text-red-600 text-sm mt-2 ${passwordNotMatchError?'':'hidden'}`}>Passwords do not match.</div>
                    </div>
                </div>
                
                <div className='mt-24'>
                    <div className="flex items-center gap-3">
                        <button onClick={() => handleSave()}
                        className="bg-white text-sm text-black p-3 w-full rounded-md border-2 border-white uppercase hover:drop-shadow-xl text-center"
                        >
                            <div className='font-bold'>Save Changes</div>
                        </button>
                    </div>
                </div>
                <ToastContainer />
            </div>
        </div>
    )
}

export default Password