import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useStateContext } from '../contexts/ContextProvider';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { ToastContainer, Bounce, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { ReactComponent as BackIcon } from '../assets/icons/icon-back.svg'
import { ReactComponent as ImageIcon } from '../assets/icons/icon-image.svg'

const MatchTypeEdit = () => {

    let navigate = useNavigate();

    const { authToken } = useStateContext();
    if(!authToken) navigate('/login'); 
    const apiUrl = process.env.REACT_APP_API_URL;
    const user = jwtDecode(authToken);

    const params = useParams();

    const [matchTypeTitle, setMatchTypeTitle] = useState(''); 
    const [matchTypeIconURL, setMatchTypeIconURL] = useState(''); 
    const [matchTypeIcon, setMatchTypeIcon] = useState(null); 

    const [matchTypeTitleError, setMatchTypeTitleError] = useState(''); 
    
    useEffect(() => {
        
        if(authToken) {
            axios.defaults.headers.common["Authorization"] = "Bearer " + authToken;
        } else {
            delete axios.defaults.headers.common["Authorization"];
        }

        axios.get(apiUrl + '/match_types/'+params['id'])
        .then((response) => {
            if(response.data && response.data.success == true) {
                if(response.data.data.title) setMatchTypeTitle(response.data.data.title);
                if(response.data.data.image) setMatchTypeIconURL(response.data.data.image);
            }
        })
	}, []);

    const handleMatchTypeEdit = () => {
        if (!matchTypeTitle || matchTypeTitle == '') {
            setMatchTypeTitleError(true);
            return false;
        } else {
            setMatchTypeTitleError(false);
        }

        var formData = new FormData();
        formData.append('user_id', user.id);
        formData.append('title', matchTypeTitle);
        if(matchTypeIcon) formData.append('file', matchTypeIcon);

        axios.put(apiUrl + '/match_types/'+params['id'], formData)
        .then((response) => {
            if(response.data) {
                toast(response.data.msg, {
                    position: "top-right",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce
                });

                if(response.data.success == true) {
                    navigate('/match-type')
                }
            }
        })
        .catch((error) => console.log('API error - ', error));
    }

    return (
        <div className='m-2 mt-20 md:m-8'>
            <div className='max-w-lg m-auto text-white'>
                <button onClick={()=>navigate(-1)} className='mb-3'>
                    <BackIcon />
                </button>
                <div className="font-bold text-3xl">Edit Custom Match type</div>
                <div className='mt-6'>
                    <div className='flex items-center justify-between'>
                        <div className='font-bold'>Match Type Title</div>
                        <div className='text-sm'>{ 36 - matchTypeTitle.length }</div>
                    </div>
                    <div className='relative mt-2'>
                        <input className={`text-white rounded-md border border-neutral-400 h-14 w-full p-3 bg-transparent ${matchTypeTitleError?'border-red-600':''}`} placeholder='E.g. Card Game' maxLength={36} value={matchTypeTitle} onChange={(event) => setMatchTypeTitle(event.target.value)} />
                        <div className={`text-red-600 text-sm mt-2 ${matchTypeTitleError?'':'hidden'}`}>This field is required.</div>
                    </div>
                </div>
                <div className='mt-6'>
                    <div className='flex items-center justify-between'>
                        <div className='font-bold'>Icon</div>
                    </div>
                    <div className='relative mt-2'>
                        { matchTypeIconURL && <img src={matchTypeIconURL} className='max-w-40 max-h-40' />}
                    </div>
                    <div className='relative mt-2'>
                        <label htmlFor='matchIcon' className='flex items-center gap-3 rounded-md border border-neutral-400 h-14 w-full p-3 bg-transparent'>
                            <ImageIcon />
                            <div className='text-neutral-300'>{ matchTypeIcon ? matchTypeIcon.name : 'Select image' }</div>
                        </label>
                        <input type="file" id="matchIcon" className='invisible' onChange={(event) => setMatchTypeIcon(event.target.files[0])} accept='image/*' />
                    </div>
                </div>
                <div className='mt-24'>
                    <div className="flex items-center gap-3">
                        <button onClick={() => handleMatchTypeEdit()}
                        className="bg-white text-sm text-black p-3 w-full rounded-md border-2 border-white uppercase hover:drop-shadow-xl text-center"
                        >
                            <div className='font-bold'>Update</div>
                        </button>
                    </div>
                </div>
                <ToastContainer />
            </div>
        </div>
    )
}

export default MatchTypeEdit