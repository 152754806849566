import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { Card } from '../components';
import { useStateContext } from '../contexts/ContextProvider';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';

const Home = () => {
    
    let navigate = useNavigate();

    const { authToken } = useStateContext();
    if(!authToken) navigate('/login'); 
    const apiUrl = process.env.REACT_APP_API_URL;
    const user = jwtDecode(authToken);

    const [banners, setBanners] = useState([]);
    const [bannerRows, setBannerRows] = useState([]);
    const [activeData, setActiveData] = useState([]);
    const [pendingData, setPendingData] = useState([]);
    const [sentData, setSentData] = useState([]);
    const [invitesData, setInvitesData] = useState([]);

    useEffect(() => {
        
        if(authToken) {
            axios.defaults.headers.common["Authorization"] = "Bearer " + authToken;
        } else {
            delete axios.defaults.headers.common["Authorization"];
        }

        axios.get(apiUrl + '/banners')
        .then((response) => {
            if(response.data && response.data.success == true && response.data.data.length > 0) {
                setBanners(response.data.data);
                var rows = response.data.data.reduce(function(rows, key, index) {
                    return (index % 2 == 0 ? rows.push([key]) : rows[rows.length-1].push(key)) && rows;
                }, []);
                setBannerRows(rows);
            }
        })
        .catch((error) => console.log('API error - ', error))

        axios.get(apiUrl + '/matches_active?user_id='+user.id)
        .then((response) => {
            if(response.data && response.data.success == true && response.data.data.length > 0) {
                setActiveData(response.data.data);
            }
        })
        .catch((error) => console.log('API error - ', error))

        axios.get(apiUrl + '/matches_pending?user_id='+user.id)
        .then((response) => {
            if(response.data && response.data.success == true && response.data.data.length > 0) {
                setPendingData(response.data.data);
            }
        })
        .catch((error) => console.log('API error - ', error))
        
        axios.get(apiUrl + '/matches_sent?user_id='+user.id)
        .then((response) => {
            if(response.data && response.data.success == true && response.data.data.length > 0) {
                setSentData(response.data.data);
            }
        })
        .catch((error) => console.log('API error - ', error))

        
        axios.get(apiUrl + '/matches_invites?user_id='+user.id)
        .then((response) => {
            if(response.data && response.data.success == true && response.data.data.length > 0) {
                setInvitesData(response.data.data);
            }
        })
        .catch((error) => console.log('API error - ', error))

    }, [])

    return (
        <div className='m-2 mt-20 md:m-8'>
            <div className='max-w-4xl m-auto'>
                <div className='hidden md:block home-carousel-md'>
                    <Carousel showThumbs={false} showArrows={false} showStatus={false} infiniteLoop={true} autoPlay={true} stopOnHover={true} swipeable={true}>
                        {bannerRows.map(row => (
                            <div className='flex gap-7'>
                                {row.map((item, index) => <div><img src={item.image} key={`banner-${index}`} /></div>)}
                            </div>
                            ))
                        }
                    </Carousel>
                </div>
                <div className='md:hidden'>
                    <Carousel showThumbs={false} showArrows={false} showStatus={false} infiniteLoop={true} autoPlay={true} stopOnHover={true} swipeable={true}>
                        {banners.map((item, index) => (
                            <div><img src={item.image} key={`banner-m-${index}`} /></div>
                        ))}
                    </Carousel>
                </div>
                <div className='mt-10 home-tabs-wrapper'>
                    <Tabs>
                        <TabList>
                            <Tab>Active</Tab>
                            <Tab>
                                Pending
                                { pendingData.length > 0 && <span className="absolute rounded h-4 w-4 top-0 right-0 bg-white text-black text-xs leading-4 text-center font-normal">{pendingData.length}</span> }
                            </Tab>
                            <Tab>
                                Sent
                            </Tab>
                            <Tab>
                                Invites 
                                { invitesData.length > 0 && <span className="absolute rounded h-4 w-4 top-0 right-0 bg-white text-black text-xs leading-4 text-center font-normal">{invitesData.length}</span> }
                            </Tab>
                        </TabList>

                        <TabPanel>
                            <div className='home-active-panel-wrapper grid grid-cols-1 md:grid-cols-2 gap-7'>
                                {activeData.map((item, index) => (
                                    <div className='w-full'><Card card={item} status="active" user_id={user?.id} key={`card-${index}`} /></div>
                                ))}
                            </div>
                            {activeData.length == 0 && <div className="text-white text-center my-8">No active matches</div>}
                        </TabPanel>
                        <TabPanel>
                            <div className='home-active-panel-wrapper grid grid-cols-1 md:grid-cols-2 gap-7'>
                                {pendingData.map((item, index) => (
                                    <div className='w-full'><Card card={item} status="pending" user_id={user?.id} key={`card-${index}`} /></div>
                                ))}
                            </div>
                            {pendingData.length == 0 && <div className="text-white text-center my-8">No pending matches</div>}
                        </TabPanel>
                        <TabPanel>
                            <div className='home-active-panel-wrapper grid grid-cols-1 md:grid-cols-2 gap-7'>
                                {sentData.map((item, index) => (
                                    <div className='w-full'><Card card={item} status="sent" user_id={user?.id} key={`card-${index}`} /></div>
                                ))}
                            </div>
                            {sentData.length == 0 && <div className="text-white text-center my-8">No sent matches</div>}
                        </TabPanel>
                        <TabPanel>
                            <div className='home-active-panel-wrapper grid grid-cols-1 md:grid-cols-2 gap-7'>
                                {invitesData.map((item, index) => (
                                    <div className='w-full'><Card card={item} status="invite" user_id={user?.id} key={`card-${index}`} /></div>
                                ))}
                            </div>
                            {invitesData.length == 0 && <div className="text-white text-center my-8">No invited matches</div>}
                        </TabPanel>
                    </Tabs>
                </div>
            </div>
        </div>
    )
}

export default Home