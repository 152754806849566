import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useStateContext } from '../contexts/ContextProvider';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { ToastContainer, Bounce, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { ReactComponent as BackIcon } from '../assets/icons/icon-back.svg'

const Propose = () => {

    let navigate = useNavigate();

    const { authToken } = useStateContext();
    if(!authToken) navigate('/login'); 
    const apiUrl = process.env.REACT_APP_API_URL;
    const user = jwtDecode(authToken);

    const params = useParams();

    const [matchOutcome, setMatchOutcome] = useState(''); 
    const [proposeReason, setProposeReason] = useState(''); 
    const [matchData, setMatchData] = useState({}); 
    
    useEffect(() => {
        
        if(authToken) {
            axios.defaults.headers.common["Authorization"] = "Bearer " + authToken;
        } else {
            delete axios.defaults.headers.common["Authorization"];
        }

        axios.get(apiUrl + '/matches/'+params['id'])
        .then((response) => {
            if(response.data && response.data.success == true) {
                setMatchData(response.data.data);
            }
        })
        .catch((error) => console.log('API error - ', error))
        
    }, [])

    const handlePropose = (matchId) => {

        if (matchOutcome == '') {
            toast('Please select the match outcome.', {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce
            });

            return false;
        }

        if(confirm("Are you sure you want to propose different outcome for this match?") == true) {

            if ( (matchData.from_user_id == user.id && matchData.choice_to_user == matchOutcome) || (matchData.to_user_id == user.id && matchData.choice_from_user == matchOutcome) ) {
                toast('You chose the same match outcome as your opponent.', {
                    position: "top-right",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce
                });

                return false;
            }

            axios.post(apiUrl + '/match_end_request/'+matchId, {user_id: user.id, choice: matchOutcome})
            .then((response) => {
                if(response.data) {
                    toast(response.data.msg, {
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        transition: Bounce
                    });

                    if(response.data.success == true) {
                        setTimeout(function() { navigate('/matches') }, 1000);
                    }
                }
            })
            .catch((error) => console.log('API error - ', error));
        }
    }
    
    return (
        <div className='m-2 mt-20 md:m-8'>
            <div className='max-w-lg m-auto text-white'>
                <button onClick={()=>navigate(-1)} className='mb-3'>
                    <BackIcon />
                </button>
                <div className="font-bold text-3xl">Propose different outcome</div>
                <div className='mt-6'>
                    <div className='font-bold'>Your proposed match outcome</div>
                    <div className='relative mt-2'>
                        <select className='text-white rounded-md border-2 border-neutral-300 h-14 w-full p-2 bg-transparent' value={matchOutcome} onChange={(event) => setMatchOutcome(event.target.value)}>
                            <option className='text-white' disabled selected value=''>Select match outcome</option>
                            <option className='text-black' value='0'>{ matchData.from_user?.full_name } { matchData.from_user_id == user.id ? '(You)' : '' } won the match</option>
                            <option className='text-black' value='1'>{ matchData.to_user?.full_name } { matchData.to_user_id == user.id ? '(You)' : '' } won the match</option>
                            <option className='text-black' value='2'>Tie</option>
                        </select>
                    </div>
                </div>
                <div className='mt-6'>
                    <div className='flex items-center justify-between'>
                        <div className='font-bold'>Explanation</div>
                        <div className='text-sm'>{ 510 - proposeReason.length }</div>
                    </div>
                    <div className='relative mt-2'>
                        <textarea className='text-white rounded-md border border-neutral-400 w-full p-3 bg-transparent' rows={8} maxLength={510} placeholder='Describe why the other player should agree to a different outcome. Remember to always be respectful and provide as much information as possible to help the other player see your point of view.' value={proposeReason} onChange={(event) => setProposeReason(event.target.value)}></textarea>
                    </div>
                </div>
                <div className='mt-24'>
                    <div className="flex items-center gap-3">
                        <Link
                        to='/matches'
                         
                        className="bg-transparent text-sm text-white p-3 w-full rounded-md border-2 border-white uppercase hover:drop-shadow-xl text-center mb-3"
                        >
                            <div className='font-bold'>Cancel</div>
                        </Link>
                    </div>
                    <div className="flex items-center gap-3">
                        <button onClick={() => handlePropose(matchData.id)}
                        className="bg-white text-sm text-black p-3 w-full rounded-md border-2 border-white uppercase hover:drop-shadow-xl text-center"
                        >
                            <div className='font-bold'>Suggest new outcome</div>
                        </button>
                    </div>
                </div>
                <ToastContainer />
            </div>
        </div>
    )
}

export default Propose