import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { ReactComponent as BackIcon } from '../assets/icons/icon-back.svg'

const Terms = () => {

    let navigate = useNavigate();

    return (
        <div className='m-2 mt-20 md:m-8'>
            <div className='max-w-3xl m-auto text-white'>
                <button onClick={()=>navigate(-1)} className='mb-3'>
                    <BackIcon />
                </button>
                <div className="font-bold text-3xl">Money Match: TERMS OF USE</div>
                <div className="font-bold text-4xl mt-6">Money Match Technology Company, LLC</div>
                <div className='mt-6'>
                    <div className='text-neutral-300'>
                        <p className='mb-4'>Welcome to Money Match, the ultimate online gaming platform. Please carefully read and understand these Terms of Use before using our services. By accessing or using Money Match, you agree to abide by these terms, as well as any additional guidelines, rules, and policies that may be posted on the platform.</p>
                        <p className='mb-4'>Your use of the money match website, services and tools are governed by the following terms and conditions ("Terms of Use") as applicable to the user including the applicable policies which are incorporated herein by way of reference. By mere use of the website, You shall be contracting with money match. These terms and conditions including the policies constitute Your binding obligations, with money match.</p>
                        <p className='mb-4'>For the purpose of these Terms of Use, wherever the context so requires "You" or "User" shall mean any natural or legal person who has agreed to become a user on website by providing data while registering on this website as Registered User. The term " Money Match ","We","Us","Our" shall mean money match Website and all its affiliates. These policies apply to all types of content on this website. Money Match Terms of Service and Community Guidelines prohibit the violates or infringes upon someone else’s copyrights, trademarks, or other intellectual property rights. Any user that infringes another person’s copyright may be removed. We reserve the right and have the authority to deactivate / delete your user account at any time, especially if you fail to adhere to the terms outlined in this agreement.</p>
                    </div>
                </div>
                <div className='mt-6'>
                    <div className='mb-4 font-bold'>Services Offered on Money Match:</div>
                    <div className='text-neutral-300'>
                        <p className='mb-4'>Money Match, provides a platform enabling skilled gaming through our website or app.</p>
                        <p className='mb-4'>The user may not disrupt the normal functioning of the Services, attempt to interfere with it, disrupt our website, any connected networks, or bypass any measures we've implemented to prevent or restrict access to the Services or employ automated scripts to gather information from or engage with the Services in any way or collect information in any way from the platform.</p>
                        <p className='mb-4'>Using the Services for any commercial or unauthorized purpose without our express written consent, including sending commercial advertisements, solicitations, or spam, without our explicit written consent, is prohibited.</p>
                    </div>
                </div>
                <div className='mt-6'>
                    <div className='mb-4 underline'>1. Acceptance of Terms</div>
                    <div className='text-neutral-300'>
                        <p className='mb-4'>1.1. By accessing or using Money Match, you acknowledge that you are at least 21 years of age or the legal age for skilled gaming in your jurisdiction. Age (21+), (18+) in some jurisdictions. Cannot play in any country, state, or providence where skilled gaming is illegal or restricted according to local law. It is your responsibility to ensure compliance with all applicable laws and regulations.</p>
                        <p className='mb-4'>1.2. You agree to be bound by these Terms of Use and any modifications thereof, which we may make from time to time. It is your responsibility to review these terms regularly for updates.</p>
                    </div>
                </div>
                <div className='mt-6'>
                    <div className='mb-4 underline'>2. Account Registration</div>
                    <div className='text-neutral-300'>
                        <p className='mb-4'>2.1. To use Money Match, you must create an account. You agree to provide accurate and complete information during the registration process. You should provide correct and relevant information about:</p>
                        <ul className='list-disc list-inside mb-4'>
                            <li className='mb-2'>Name</li>
                            <li className='mb-2'>Address</li>
                            <li className='mb-2'>Email (must be verified)</li>
                            <li className='mb-2'>Phone</li>
                            <li className='mb-2'>Username</li>
                            <li className='mb-2'>Facebook, Google oauth2 information</li>
                        </ul>
                        <p className='mb-4'>You are responsible for maintaining the confidentiality of your account credentials.</p>
                        <p className='mb-4'>2.1a KYC. In order to pass Know Your Customer (KYC) requirements for you to fund your account you may be required to provide additional detail. You attest that any information your provide will be correct and relevant. You may be required by our payment processor to provide the following information:</p>
                        <ul className='list-disc list-inside mb-4'>
                            <li className='mb-2'>Social Security Number (SSN)</li>
                            <li className='mb-2'>Annual Income</li>
                            <li className='mb-2'>Banking account credentials</li>
                        </ul>
                        <p className='mb-4'>2.2. You are solely responsible for all activities that occur under your account, and you must immediately notify Money Match of any unauthorized use of your account.</p>
                    </div>
                </div>
                <div className='mt-6'>
                    <div className='mb-4 underline'>3. Responsible use</div>
                    <div className='text-neutral-300'>
                        <p className='mb-4'>3.1. Money Match is committed to promoting responsible user behavior. We provide self-exclusion options, deposit limits, and other tools to help you control your activities. Please use these responsibly.</p>
                    </div>
                </div>
                <div className='mt-6'>
                    <div className='mb-4 underline'>4. Deposits and Withdrawals</div>
                    <div className='text-neutral-300'>
                        <p className='mb-4'>4.1. You may fund your Money Match account through approved payment methods. You agree that all funds deposited into your account are your own, and you are not using any illicit funds.</p>
                        <p className='mb-4'>4.2. Withdrawals are subject to Money Match's withdrawal policies and may be subject to verification checks.</p>
                    </div>
                </div>
                <div className='mt-6'>
                    <div className='mb-4 underline'>5. Wagering</div>
                    <div className='text-neutral-300'>
                        <p className='mb-4'>5.1. You are responsible for understanding the rules and terms of the matches and wagers you participate in with other users. Money Match will not be liable for any losses incurred due to a lack of understanding of the rules. </p>
                        <div className='ml-4'>
                            <div className='mb-4 font-bold'>Rules of money match are as follows:</div>
                            <p className='mb-4 font-bold'>a.	User Account, Password, and Security:</p>
                            <p className='mb-4'>If You use the website, You shall be responsible for maintaining the confidentiality of your Display Name and Password and You shall be responsible for all the activities that occur under your Display Name and Password. You agree that if You provide any information that is untrue, inaccurate, not current or incomplete, we shall have the right to indefinitely suspend or terminate or block access of your account on our website. If you choose a username or a similar identifier for your account, we might change it if we deem it necessary or appropriate, such as if it violates someone's intellectual property rights or impersonates another user.</p>
                            <p className='mb-4'>You agree to</p>
                            <ul className='list-disc list-inside mb-4'>
                                <li className='mb-2'>immediately notify us of any unauthorized use/ unexpected use / breach of your password or account and</li>
                                <li className='mb-2'>ensure that you exit from your account at the end of each session.</li>
                            </ul>
                            <p className='mb-4 font-bold'>b.	User Conduct and Rules on the website:</p>
                            <p className='mb-4'>You agree, undertake and confirm that Your use of the website shall be strictly governed by the following binding principles:</p>
                            <p className='mb-4'>You shall not host, display, upload, modify, publish, transmit, update or share any information which:</p>
                            <ul className='list-[upper-roman] mb-4 ml-12'>
                                <li className='mb-2'>is grossly harmful, harassing, defamatory, obscene, pornographic, pedophilic, libelous, invasive of another's privacy, hateful, or racially, ethnically objectionable, disparaging</li>
                                <li className='mb-2'>is misleading in any way</li>
                                <li className='mb-2'>Treat all users with respect and courtesy.</li>
                                <li className='mb-2'>Maintain a professional tone in all interactions.</li>
                                <li className='mb-2'>Prohibit plagiarism and cheating.</li>
                                <li className='mb-2'>Clarify the platform's privacy policy.</li>
                                <li className='mb-2'>Instruct users not to share personal information in public forums.</li>
                                <li className='mb-2'>Describe how user data is handled and protected.</li>
                                <li className='mb-2'>Encourage constructive feedback and discourage trolling or spamming.</li>
                                <li className='mb-2'>Avoid any form of discrimination, harassment, or offensive language.</li>
                                <li className='mb-2'>promotes illegal activities or conduct that is abusive, threatening, obscene, defamatory or libelous</li>
                                <li className='mb-2'>infringes upon or violates any third party's rights including, but not limited to, intellectual property rights, rights of privacy (including without limitation unauthorized disclosure of a person's name, email address, physical address or phone number) or rights of publicity</li>
                                <li className='mb-2'>contains restricted or password-only access pages, or hidden pages or images (those not linked to or from another accessible page)</li>
                                <li className='mb-2'>provides instructional information about illegal activities such as making or buying illegal weapons, violating someone's privacy, or providing or creating computer viruses</li>
                                <li className='mb-2'>infringes any patent, trademark, copyright or other proprietary rights or third party's trade secrets or rights of publicity or privacy or shall not be fraudulent or involve the sale of counterfeit or stolen products</li>
                                <li className='mb-2'>violates any law for the time being in force</li>
                                <li className='mb-2'>shall not be false, inaccurate or</li>
                                <li className='mb-2'>Misuse of another User’s phone/email</li>
                                <li className='mb-2'>Users uses invalid address, email and phone no.</li>
                                <li className='mb-2'>Overuse of a voucher code</li>
                                <li className='mb-2'>Use of a special voucher not tagged to the email ID used.</li>
                            </ul>
                            <div className='mb-4 italic'>
                                <p className='mb-2'>Money Match is a two-way agreement between two users that proceeds as follows:</p>
                                <p className='mb-2'>User A initiates a Money Match request by specifying (in order):</p>
                                <p className='mb-2'>Opponent (other user), Match privacy (private matches appear on the public feed), Match Title, Description, Match Type i.e. Category, Exposure, Multiplier.</p>
                                <p className='mb-2'>For example: </p>
                                <p className='mb-2'>a) Single Match</p>
                                <p className='mb-2'>Opponent: UserB</p>
                                <p className='mb-2'>Privacy: Public</p>
                                <p className='mb-2'>Title: “First to 9 holes 10/25/23”</p>
                                <p className='mb-2'>Description: “The first user who scores 9 holes wins”</p>
                                <p className='mb-2'>Type: Golf</p>
                                <p className='mb-2'>Exposure: $50.00</p>
                                <p className='mb-2'>Multiplier: 2.2x</p>
                                <p className='mb-2'>Total Wager: 50.00</p>
                                <p className='mb-2'>b) Tournament</p>
                                <p className='mb-2'>Style: Tournament</p>
                                <p className='mb-2'>Number of Players:</p>
                                <p className='mb-2'>Opponents: UserB, UserC, UserD, UserE,UserF, UserG, UserH</p>
                                <p className='mb-2'>Privacy: Private</p>
                                <p className='mb-2'>Title: “Chess Tournament”</p>
                                <p className='mb-2'>Description: “Elimination style chess tournament”</p>
                                <p className='mb-2'>Type: Board Game</p>
                                <p className='mb-2'>Exposure: $50.00</p>
                                <p className='mb-2'>Multiplier: N/A (No multiplier for tournaments)</p>
                                <p className='mb-2'>Total Wager: 50.00</p>
                            </div>
                            <p className='mb-4'>Money is then deposited by the user who starts the match into a holding account. If the user cancels the match before it is accepted, then the money is returned to them.</p>
                            <p className='mb-4'>After a match request is sent, the other user(s) is notified that they have been invited to a match and must accept it for it to be active. If they accept it, then funds are removed from their payment method to a holding account. The amount is determined by the exposure and multiplier specified by the original user i.e. the exposure of userB = exposure (of User A) * multiplier.</p>
                        </div>
                        <p className='mb-4'>5.2. Money Match reserves the right to void any wager or money match placed in violation of these terms or in the event of a technical malfunction.</p>
                    </div>
                </div>
                <div className='mt-6'>
                    <div className='mb-4 underline'>6. Prohibited Activities</div>
                    <div className='text-neutral-300'>
                        <p className='mb-4'>6.1. You must not engage in any of the following activities:</p>
                        <ul className='list-[lower-alpha] list-inside mb-4 ml-4'>
                            <li className='mb-2'>Cheating, colluding, or engaging in fraudulent activities.</li>
                            <li className='mb-2'>Using automated systems or software to gain an unfair advantage.</li>
                            <li className='mb-2'>Hacking or attempting to hack the Money Match platform.</li>
                            <li className='mb-2'>Providing false information or using multiple accounts to exploit promotions.</li>
                        </ul>
                        <p className='mb-4'>As such, Money Match is a wagering platform allowing users to wager money with one another. While Money Match does not arbitrate matches, users with very low Match Cred Credibility scores or who engage in other misleading or fraudulent behavior are kicked off and banned from the platform.</p>
                    </div>
                </div>
                <div className='mt-6'>
                    <div className='mb-4 underline'>7. Intellectual Property</div>
                    <div className='text-neutral-300'>
                        <p className='mb-4'>7.1. All content on Money Match, including but not limited to text, graphics, logos, images, and software, is the property of Money Match or its licensors and is protected by copyright and other intellectual property laws.</p>
                        <p className='mb-4'>7.2. You may not reproduce, distribute, or create derivative works from any of the content without the express written consent of Money Match.</p>
                    </div>
                </div>
                <div className='mt-6'>
                    <div className='mb-4 underline'>8. Termination & Suspension</div>
                    <div className='text-neutral-300'>
                        <p className='mb-4'>Termination, often considered the most severe action, may be warranted in situations of repeated violations of community guidelines, dishonesty, or misuse of personalized content. The process typically involves a thorough investigation to ensure fair treatment. Users are typically provided with clear warnings and opportunities to rectify their behavior before termination is considered. In cases where personalized content is abused, the platform should have mechanisms in place to revoke access to such content promptly.</p>
                        <p className='mb-4'>Suspension, on the other hand, is a temporary measure designed to address less severe infractions or provide a cooling-off period for disruptive users. This is particularly relevant for online gaming platforms with personalized content because it allows for a pause in access to the personalized resources while issues are resolved. It may be implemented for breaches of conduct, copyright violations, or other breaches of platform policies. The duration of suspension can vary based on the severity of the infraction. Users with credibility scores below 3 may expect suspensions arising from future disputes.</p>
                        <p className='mb-4'>8.1. Money Match may, at its discretion, suspend or terminate your account at any time for violation of these terms, fraud, or any other misuse of the platform.</p>
                        <p className='mb-4'>8.2. We do not tolerate any form of abuse, harassment, or discrimination. This includes, but is not limited to, derogatory comments based on race, ethnicity, religion, sexual orientation, gender identity, or disability.</p>
                    </div>
                </div>
                <div className='mt-6'>
                    <div className='mb-4 underline'>9. No illegal activity</div>
                    <div className='text-neutral-300'>
                        <p className='mb-4'>Money Match is not a platform for illegal activity of any kind. This includes, but is not limited to, the buying or selling of illegal goods or services, the sharing of copyrighted material, or the distribution of illegal drugs.</p>
                        <p className='mb-4'>9.1. Do not spam or engage in any deceptive or misleading practices. Money Match is not a platform for spamming or any other deceptive or misleading practices. This includes, but is not limited to, the sharing of links to inappropriate or malicious websites, or the sending of unsolicited messages.</p>
                        <p className='mb-4'>9.2. Do not engage in any activity that could compromise the security or integrity of the platform. Money Match is committed to maintaining the security and integrity of our platform. Any activity that could compromise this, such as hacking or tampering with the platform, is strictly prohibited.</p>
                        <p className='mb-4'>9.3. Specific (to Money Match):</p>
                        <div className='mb-4 ml-4'>
                            <p className='mb-4'>Falsification of any legal disclosures as to age, name, location, especially in jurisdictions that prohibit online gambling. </p>
                            <p className='mb-4'>Falsification of any information during KYC process or any other process to any of our approved payment providers or other vendors is also not allowed.</p>
                            <p className='mb-4'>Cheating and any other forms of purposeful dishonesty. Users that are proven or suspected of disputing matches fraudulently, meaning that they select an outcome they knowingly have reason to believe did not occur should be banned. </p>
                            <p className='mb-4'>Fixing matches and collusion. In match play, users are prohibited from fixing matches. This means that users are not allowed to collude with other users to advance in matches. </p>
                            <p className='mb-4'>Disclosure of personal information to other users. Users are not allowed to disclose personal information to other users on Money Match that is outside the scope of engaging in approved activities on the app. </p>
                            <p className='mb-4'>Unreachability and disconnecting: Users that do not submit a match result after certain lengths of time (1 week) and after being reminded to submit a result by other participant(s). Any of these behaviors may get a user a lifetime ban.</p>
                        </div>
                    </div>
                </div>
                <div className='mt-6'>
                    <div className='mb-4 underline'>10. Disclaimer of Warranties</div>
                    <div className='text-neutral-300'>
                        <p className='mb-4'>10.1. 	Money Match provides its services "as is," without any warranties or guarantees of any kind. We do not guarantee uninterrupted access or that the platform will be error-free.</p>
                        <div className='mb-4 ml-4'>
                            <p className='mb-4'>We have a support team that is on staff 9am-5pm ET hours on weekdays minus Federal holidays. Complaints are handled within 3 days although complicated cases may take longer. They should email support@getmoneymatch.com. </p>
                            <p className='mb-4'>We take commissions from instant transfers of money from the app to a user’s bank account and we take a match dispute fee of 10.00% for simple money matches and 25.00% for tournament matches.</p>
                        </div>
                        <p className='mb-4'>10.2. 	Since we do not arbitrate matches, we do not offer any refunds relating to match conduct, i.e. for lost, disputed, or tied matches.</p>
                        <p className='mb-4'>We provide refunds under a limited number of circumstances, including:</p>
                        <ul className='list-[lower-alpha] list-inside mb-4 ml-4'>
                            <li className='mb-2'>Inability to withdraw funds, double charges, inaccurate amounts of money transferred, etc, due to technological issues i.e. issues with a payment processor. This needs to be addressed to our support team.</li>
                            <li className='mb-2'>Review of extraordinary circumstances by our support staff due to users mistakenly making a transaction.</li>
                        </ul>
                    </div>
                </div>
                <div className='mt-6'>
                    <div className='mb-4 underline'>11. Privacy and data protection:</div>
                    <div className='text-neutral-300'>
                        <p className='mb-4'>11.1. We take the privacy and data protection of our users seriously. Users are expected to respect each other's privacy and not share personal information without consent. We adhere to strict data protection guidelines and request that users report any data breaches or privacy concerns promptly.</p>
                        <p className='mb-4'>11.2. On this website, users can find detailed information on how their personal data is collected, used, and protected. It should include a clear and easily understandable privacy policy that outlines the types of data collected, such as user profiles, login information, and behavioral data, and the purposes for which this data is utilized, which typically involve personalizing the gaming experience, providing recommendations, and improving the platform's services.</p>
                        <p className='mb-4'>11.3. Additionally, the website explains the mechanisms in place for obtaining user consent, the security measures in use to protect data, and any third-party partnerships or data sharing practices, ensuring that users are aware of how their information may be shared or utilized beyond the platform itself.</p>
                        <p className='mb-4'>11.4. Moreover, the privacy website informs users about their rights concerning their data, including the ability to review, edit, or delete their information, as well as the process for making data access or deletion requests.</p>
                        <p className='mb-4'>11.5. This transparency helps build trust among users by demonstrating the platform's commitment to respecting their privacy. It also serves as a resource for users to understand how their data is used to personalize their gaming experience, ultimately fostering a sense of control and security in an online gaming match or a tournament. The privacy website acts as a foundation for a strong privacy framework, reassuring users that their data is handled responsibly and ethically.</p>
                    </div>
                </div>
                <div className='mt-6'>
                    <div className='mb-4 underline'>12. Third party users on Money match website for Services:</div>
                    <div className='text-neutral-300'>
                        <p className='mb-4'>Money match will not utilize or provide third party links or widgets on money match website to interact or utilize external services from third party service. </p>
                        <p className='mb-4'>We have the authority to deactivate / delete your user account at any time, especially if you fail to adhere to the terms outlined in this agreement. This action might be taken if activities on your account, as determined solely by us, pose a risk to the Services, violate third-party rights, or breach applicable laws and regulations. After the deletion of your account, you won't have the ability to reactivate it or recover any of the content or information you've previously added.</p>
                    </div>
                </div>
                <div className='mt-6'>
                    <div className='mb-4 underline'>13. Dispute Resolution and Reporting Violations:</div>
                    <div className='text-neutral-300'>
                        <p className='mb-4'>We understand that disputes and conflicts may arise while using our app. As such, Money Match is a social wagering platform allowing users to wager money with one another. While Money Match does not arbitrate matches, users with very low Match Cred Credibility scores or who engage in other misleading or fraudulent behavior are kicked off and banned from the platform. Money Match also has social networking features such as ability to add friends and a global feed of all public matches on the network. To address this, we provide a clear process for dispute resolution. Users are encouraged to first attempt to resolve issues through open and respectful communication on platform. In the event that the dispute persists, we have a reporting system in place for violations of our conduct and rules. Users are encouraged to report any conduct that violates our guidelines, and our support team will investigate and take appropriate action.</p>
                    </div>
                </div>
                <div className='mt-6'>
                    <div className='mb-4 underline'>14. Limitation of Liability</div>
                    <div className='text-neutral-300'>
                        <p className='mb-4'>14.1. Money Match shall not be liable for any direct, indirect, incidental, special, or consequential damages, including but not limited to, loss of profits, data, or business interruption, arising out of your use of the platform. Since we do not arbitrate matches, we do not offer any refunds relating to match conduct, i.e. for lost, disputed, or tied matches.</p>
                        <p className='mb-4'>We provide refunds under a limited number of circumstances, including:</p>
                        <ul className='list-[lower-alpha] list-inside mb-4 ml-4'>
                            <li className='mb-2'>Inability to withdraw funds, double charges, inaccurate amounts of money transferred, etc, due to technological issues i.e. issues with a payment processor. This needs to be addressed to our support team.</li>
                            <li className='mb-2'>Review of extraordinary circumstances by our support staff due to users mistakenly making a transaction.</li>
                        </ul>
                    </div>
                </div>
                <div className='mt-6'>
                    <div className='mb-4 underline'>15. Governing Law</div>
                    <div className='text-neutral-300'>
                        <p className='mb-4'>15.1. These terms are governed by and construed in accordance with the laws of the state of Delaware, USA.</p>
                        <p className='mb-4'>15.2.  It includes arbitration and conciliation as a requirement clause. Also, jurisdiction for court proceedings should be the state of Delaware. Also, mandate arbitration before court proceedings.</p>
                    </div>
                </div>
                <div className='mt-6'>
                    <div className='mb-4 underline'>16. Limitation on Participation</div>
                    <div className='text-neutral-300'>
                        <p className='mb-4'>16.1. You may establish only one Member account per person to participate in the Services offered on the Web Site & mobile application. Money Match reserves the right to monitor any effort to establish multiple accounts; in the event it is discovered that you have opened more than one account per person, Money Match reserves the right to close all your accounts without notice. </p>
                        <p className='mb-4'>16.2. Money Match may impose monetary limitations on the deposit transactions in which you engage to fund your Member account. Money Match reserves the right to change these monetary limitations at any time.</p>
                        <p className='mb-4'>16.3 Money Match reserves the right to expire the credit balance of any dormant account. Money Match reserves the right to archive any dormant account and hence make it inaccessible to the Member. A dormant account shall be defined as any account that has been inactive for greater than 180 days.</p>
                    </div>
                </div>
                <div className='mt-6'>
                    <div className='mb-4 underline'>17. Security</div>
                    <div className='text-neutral-300'>
                        <p className='mb-4'>The security of your information is one of the top priorities of Money Match. Encryption techniques are used to provide protection of your credit card information. Whilst Money Match has taken precaution to ensure the security of your banking and/or credit card details, Money Match shall not be liable for any loss or disclosure thereof through no fault Money Match. Your credit card and/or banking information shall however have to be disclosed if Money Match is required by law to do so. If so required by law, Money Match shall endeavor to inform you by prior notice to you. </p>
                    </div>
                </div>
                <div className='mt-6'>
                    <div className='mb-4 underline'>18. Taxes and Confidentiality</div>
                    <div className='text-neutral-300'>
                        <p className='mb-4'>Taxes or other government imposts may be payable by you on your winnings, depending on the jurisdiction in which you reside or relevant laws applicable to you otherwise. Money Match shall not disclose any information regarding your net loss or net profits to any third party unless required to do so by law. </p>
                    </div>
                </div>
                <div className='mt-6'>
                    <div className='mb-4 underline'>19. Equipement</div>
                    <div className='text-neutral-300'>
                        <p className='mb-4'>You must supply your own equipment to gain access to the Web Site & mobile application and to partake in the Game. You acknowledge that you use the Services at your own risk and that the Services are provided on an "as available" and "as is" basis.</p>
                    </div>
                </div>
                <div className='mt-6'>
                    <div className='mb-4 underline'>20. Continuity of Service</div>
                    <div className='text-neutral-300'>
                        <p className='mb-4'>Money Match reserves the right to modify or discontinue the Service at any time and Money Match shall not be liable to any Member or third party for any loss or damage or claim of whatever nature as a result Money Match modifying or discontinuing the Service. Money Match makes no warranty that the Service will be uninterrupted, secure, error free or timely. </p>
                    </div>
                </div>
                <div className='mt-6'>
                    <div className='mb-4 underline'>21. Liability in respect of Equipment </div>
                    <div className='text-neutral-300'>
                        <p className='mb-4'>Money Match shall not be liable for any loss or damages caused to your equipment as a result of downloading software or data from the Web Site & mobile application and/or using such software or data and/or you participating in the Game.</p>
                    </div>
                </div>
                <div className='mt-6'>
                    <div className='mb-4 underline'>22. Liability in respect of Equipment</div>
                    <div className='text-neutral-300'>
                        <p className='mb-4'>On accepting any amount of winnings you hereby consent to the use by Money Match of your name, or in the case of you partaking under an alias, your alias, for advertising and promotional purposes and for display on the public feed. Such use of your name or alias by Money Match shall be free of any charge.</p>
                    </div>
                </div>
                <div className='my-12'>
                    <div className='mb-4 font-bold'>Conclusion:</div>
                    <div className='text-neutral-300'>
                        <p className='mb-4'>In conclusion, the concept of a personalized online gaming platform has the potential to revolutionize the way we acquire knowledge and skills. Thank you for choosing to use Money Match, the premier online skilled gaming platform. Before you proceed with your experience, please take a moment to read and understand our comprehensive Terms of Use. By accessing or using the Money Match app/website, you agree to abide by these terms and conditions. If you do not agree to these terms, please refrain from using our services.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Terms