import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Popover from '@idui/react-popover'
import { useStateContext } from '../contexts/ContextProvider';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { ToastContainer, Bounce, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { ReactComponent as QuestionIcon } from '../assets/icons/icon-question.svg'
import { ReactComponent as BackIcon } from '../assets/icons/icon-back.svg'

const Dispute = () => {

    const { authToken } = useStateContext();
    if(!authToken) navigate('/login'); 
    const apiUrl = process.env.REACT_APP_API_URL;
    const user = jwtDecode(authToken);

    let navigate = useNavigate();

    const params = useParams();

    const [step, setStep] = useState(1); 
    const [matchOutcome, setMatchOutcome] = useState(''); 
    const [disputeReason, setDisputeReason] = useState(''); 
    const [matchData, setMatchData] = useState({}); 
    
    useEffect(() => {
        
        if(authToken) {
            axios.defaults.headers.common["Authorization"] = "Bearer " + authToken;
        } else {
            delete axios.defaults.headers.common["Authorization"];
        }

        axios.get(apiUrl + '/matches/'+params['id'])
        .then((response) => {
            if(response.data && response.data.success == true) {
                setMatchData(response.data.data);
            }
        })
        .catch((error) => console.log('API error - ', error))
        
    }, [])

    const handleDispute = (matchId) => {

        if (!matchOutcome) {
            toast('Please select the match outcome.', {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce
            });

            return false;
        }

        if(confirm("Are you sure you want to dispute this match?") == true) {

            if ( (matchData.from_user_id == user.id && matchData.choice_to_user == matchOutcome) || (matchData.to_user_id == user.id && matchData.choice_from_user == matchOutcome) ) {
                toast('You chose the same match outcome as your opponent.', {
                    position: "top-right",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce
                });

                return false;
            }

            axios.post(apiUrl + '/match_dispute/'+matchId, {user_id: user.id, matchOutcome: matchOutcome, reason: disputeReason})
            .then((response) => {
                if(response.data) {
                    toast(response.data.msg, {
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        transition: Bounce
                    });

                    if(response.data.success == true) {
                        navigate('/matches')
                    }
                }
            })
            .catch((error) => console.log('API error - ', error));
        }
    }

    return (
        <div className='m-2 mt-20 md:m-8'>
            <div className='max-w-lg m-auto text-white'>
                <button onClick={()=>navigate(-1)} className='mb-3'>
                    <BackIcon />
                </button>
                {
                    step == 1 &&
                    <>
                        <div className="font-bold text-3xl mt-24">You're about to dispute the outcome of a match</div>
                        <div className="mt-6 text-sm text-neutral-300">If you continue both players will be assessed a match dispute fee and both players will be refunded the escrow amount. <br /> A dispute has a negative impact on both players and incurs in a <b>Dispute Fee</b>.</div>   
                        <div className='rounded-lg bg-neutral-700 p-6 mt-8'>
                            <div className='flex items-center justify-between gap-3'>
                                <div className='flex items-center gap-2'>
                                    <div className='text-neutral-300'>Dispute Fee</div>
                                    <Popover
                                        trigger='click'
                                        width="240px"
                                        placement='topLeft'
                                        closeOnScroll
                                        closeOnRemoteClick
                                        closeOnTab
                                        onChangeOpen={function noRefCheck(){}}
                                        onFocus={function noRefCheck(){}}
                                        content={() => (
                                            <div className='text-sm'>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                            </div>
                                        )}
                                        >
                                        <button className='relative'><QuestionIcon /></button>
                                    </Popover>
                                </div>
                                <div className='font-bold'>{ matchData.from_user_id == user.id ? Math.round(matchData.from_user_amount * 0.1 * 10000000)/10000000 : Math.round(matchData.to_user_amount * 0.1 * 10000000)/10000000 } ETH</div>
                            </div>
                        </div>
                        <div className='mt-24'>
                            <div className="flex items-center gap-3">
                                <Link
                                to='/matches'
                                 
                                className="bg-transparent text-sm text-white p-3 w-full rounded-md border-2 border-white uppercase hover:drop-shadow-xl text-center mb-3"
                                >
                                    <div className='font-bold'>DO NOT DISPUTE</div>
                                </Link>
                            </div>
                            <div className="flex items-center gap-3">
                                <button
                                onClick={() => setStep(2)}
                                className="bg-white text-sm text-black p-3 w-full rounded-md border-2 border-white uppercase hover:drop-shadow-xl text-center"
                                >
                                    <div className='font-bold'>DISPUTE OUTCOME</div>
                                </button>
                            </div>
                        </div>
                    </>
                }
                {
                    step == 2 &&
                    <>
                        <div className="font-bold text-3xl">Tell us about your dispute</div>
                        <div className="mt-6 text-sm text-neutral-300">Please, describe your position regarding the match outcome.</div>   
                        <div className='mt-6'>
                            <div className='font-bold'>Your proposed match outcome</div>
                            <div className='relative mt-2'>
                                <select className='text-white rounded-md border-2 border-neutral-300 h-14 w-full p-2 bg-transparent' value={matchOutcome} onChange={(event) => setMatchOutcome(event.target.value)}>
                                    <option className='text-white' disabled selected value=''>Select match outcome</option>
                                    <option className='text-black' value='0'>{ matchData.from_user?.full_name } { matchData.from_user_id == user.id ? '(You)' : '' } won the match</option>
                                    <option className='text-black' value='1'>{ matchData.to_user?.full_name } { matchData.to_user_id == user.id ? '(You)' : '' } won the match</option>
                                    <option className='text-black' value='2'>Tie</option>
                                </select>
                            </div>
                        </div>
                        <div className='mt-6'>
                            <div className='flex items-center justify-between'>
                                <div className='font-bold'>Explanation</div>
                                <div className='text-sm'>{ 510 - disputeReason.length }</div>
                            </div>
                            <div className='relative mt-2'>
                                <textarea className='text-white rounded-md border border-neutral-400 w-full p-3 bg-transparent' rows={8} maxLength={510} placeholder='Describe why your proposed outcome should have been the outcome for this match.' value={disputeReason} onChange={(event) => setDisputeReason(event.target.value)}></textarea>
                            </div>
                        </div>
                        <div className='mt-24'>
                            <div className="flex items-center gap-3">
                                <Link
                                to='/matches'
                                 
                                className="bg-transparent text-sm text-white p-3 w-full rounded-md border-2 border-white uppercase hover:drop-shadow-xl text-center mb-3"
                                >
                                    <div className='font-bold'>Cancel dispute</div>
                                </Link>
                            </div>
                            <div className="flex items-center gap-3">
                                <button onClick={() => handleDispute(matchData.id)}
                                className="bg-white text-sm text-black p-3 w-full rounded-md border-2 border-white uppercase hover:drop-shadow-xl text-center"
                                >
                                    <div className='font-bold'>Submit dispute</div>
                                </button>
                            </div>
                        </div>
                    </>
                }
                <ToastContainer />
            </div>
        </div>
    )
}

export default Dispute