import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useStateContext } from '../contexts/ContextProvider';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import moment from 'moment';

import { Button } from '.'
import { MdOutlineCancel } from 'react-icons/md'

const Notification = () => {

    let navigate = useNavigate();

    const { authToken, setIsClicked, initialState } = useStateContext();
    if(!authToken) navigate('/login'); 
    const apiUrl = process.env.REACT_APP_API_URL;
    const user = jwtDecode(authToken);

    const [newData, setNewData] = useState([]); 
    const [notificationData, setNotificationData] = useState([]); 

    useEffect(() => {
        
        if(authToken) {
            axios.defaults.headers.common["Authorization"] = "Bearer " + authToken;
        } else {
            delete axios.defaults.headers.common["Authorization"];
        }

        axios.get(apiUrl + '/notifications_unviewed/'+user.id)
        .then((response) => {
            if(response.data && response.data.success == true && response.data.data.length > 0) {
                setNewData(response.data.data);
            }
        })

        axios.get(apiUrl + '/notifications_viewed/'+user.id)
        .then((response) => {
            if(response.data && response.data.success == true && response.data.data.length > 0) {
                setNotificationData(response.data.data);
            }
        })

        setAsViewed();
	}, []);

    function setAsViewed() {
        axios.post(apiUrl + '/notifications_viewed/'+user.id).then((response) => {})
        setTimeout(function() {setAsViewed();}, 3000);
    }

    return (
        <div className='notification-popup absolute right-1 md:right-5 top-16 bg-white dark:bg-black text-white p-4 rounded-lg h-[640px] w-[calc(100%-8px)] md:w-96 border-1 overflow-y-auto'>
            <div className="flex items-center justify-between absolute top-0 right-0">
                <Button icon={<MdOutlineCancel/>} color='white' bgHoverColor="dark-gray" size='2xl' borderRadius='50%'/>
            </div>
            <div className="mt-2">
                <div className='font-bold text-3xl mb-1'>Notifications</div>
                <div className='font-bold text-lg mt-8'>New</div>
                { 
                    newData && newData.length > 0 &&
                    <div className='pt-6'>
                        <div>
                            {newData.map((item, index) => (
                                <NotificationItem item={item} setIsClicked={setIsClicked} initialState={initialState} key={`notification-${index}`} />
                            ))}
                        </div>
                    </div>
                }
                <div className='font-bold text-lg mt-8'>Last 7 days</div>
                { 
                    notificationData && notificationData.length > 0 &&
                    <div className='pt-6'>
                        <div>
                            {notificationData.map((item, index) => (
                                <NotificationItem item={item} setIsClicked={setIsClicked} initialState={initialState} key={`notification-${index}`} />
                            ))}
                        </div>
                    </div>
                }
            </div>
        </div>
        
    )
}

const NotificationItem = ({item, setIsClicked, initialState}) => {
    return (
        <div className="notification mb-6">
            <div className="flex justify-between w-full gap-2">
                <div className="leading-none">
                    { /* <div className="font-bold">{item.from_user.full_name}</div> */ }
                    <div className="text-neutral-300">{item.title}</div>
                </div>
                <div className="text-neutral-500 text-sm">{ moment().diff(moment(item.createdAt), 'days') > 0 ? moment().diff(moment(item.createdAt), 'days')+'d' : ( moment().diff(moment(item.createdAt), 'hours') > 0 ? moment().diff(moment(item.createdAt), 'hours')+'h' : moment().diff(moment(item.createdAt), 'minutes')+'m')}</div>
            </div>
            <div className="text-neutral-300 text-sm mt-1">{item.description}</div>
            <div className='bg-white rounded-xl border-none mt-1'>
                <Link to={'/matches/'+item?.match?.id} onClick={() => setIsClicked(initialState)} className="leading-tight italic font-bold uppercase text-black px-3 pt-2 pb-1 truncate">{item?.match?.title}</Link>
            </div>
        </div>
    )
}

export default Notification
