import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useStateContext } from '../contexts/ContextProvider';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { AiOutlineMenu } from 'react-icons/ai';
import { TooltipComponent } from '@syncfusion/ej2-react-popups'

import { ReactComponent as IconNotification } from '../assets/icons/icon-notification.svg'
import { Notification } from '.';
import { ReactComponent as AppLogoWhite } from '../assets/icons/MoneyMatch-Logo-White.svg'
import { ReactComponent as UserIcon } from '../assets/icons/icon-user.svg'

const NavButton = ({title, customFunc, icon, color, number}) => {
    return (
    <TooltipComponent content={title} position='BottomCenter' cssClass='my-tooltip'>
      <button
       type='button'
       onClick={() => customFunc()}
       style={{ color }}
       className="relative text-xl rounded-full py-2 px-3 hover:bg-black"
       >
        {number*1 > 0 &&
          <span className="absolute rounded h-4 w-4 top-1 right-1 bg-white text-black text-xs leading-4">
            {number}
          </span>
        }
        {icon}
      </button>
    </TooltipComponent>
    )
}

const Navbar = () => {
    const { currentColor, activeMenu, setActiveMenu, handleClick, isClicked, setScreenSize, screenSize } = useStateContext()

    let navigate = useNavigate();

    const { authToken } = useStateContext();
    if(!authToken) navigate('/login'); 
    const apiUrl = process.env.REACT_APP_API_URL;
    const user = jwtDecode(authToken);

    const [notificationCount, setNotificationCount] = useState(0); 
    const [userPhotoURL, setUserPhotoURL] = useState(''); 

    useEffect(() => {

      if(authToken) {
          axios.defaults.headers.common["Authorization"] = "Bearer " + authToken;
      } else {
          delete axios.defaults.headers.common["Authorization"];
      }

      axios.get(apiUrl + '/notifications_count?user_id='+user.id)
      .then((response) => {
          if(response.data && response.data.success == true) {
              setNotificationCount(response.data.data);
          }
      })

      axios.get(apiUrl + '/users/'+user.id)
      .then((response) => {
        if(response.data && response.data.success == true && response.data.data) {
          setUserPhotoURL(response.data.data.picture);
        }
      })
      
      const handleResize = () => setScreenSize(window.innerWidth)

      window.addEventListener('resize', handleResize)

      handleResize()

      return () => window.removeEventListener('resize', handleResize)
      
    }, [])

    useEffect(() => {
      if (screenSize <= 900) {
        setActiveMenu(false)
      } else {
        setActiveMenu(true)
      }
    }, [screenSize])

    const handleActiveMenu = () => setActiveMenu(!activeMenu)

    return (
      <div className='flex items-center justify-between p-2 mr-1 md:mr-6 md-ml-6 relative'>
        <div className="flex items-center gap-2">
          <NavButton customFunc={handleActiveMenu} title='Menu' icon={<AiOutlineMenu />} color="#FFFFFF" />
          <AppLogoWhite className='block md:hidden w-36 h-auto' />
        </div>
        <div className="flex items-center gap-2">
          <NavButton title="Notification" dotColor={'rgb(254, 201, 15)'} number={notificationCount} customFunc={() => handleClick('notification')} color={currentColor} icon={<IconNotification/>} />
          { isClicked.notification && <Notification/> }

          <Link
            to='/settings'
            className="relative text-xl rounded-full hover:bg-black text-white w-9 h-9"
            >
              { userPhotoURL ? <img className="border-2 rounded-md w-9 h-9 object-cover" src={userPhotoURL} /> : <UserIcon className="w-9 h-9" /> }
          </Link>
        </div>
      </div>
    )
}

export default Navbar