import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import axios from 'axios';
import { ToastContainer, Bounce, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { ReactComponent as BackIcon } from '../assets/icons/icon-back.svg'
import { ReactComponent as EyeIcon } from '../assets/icons/icon-eye.svg'
import { ReactComponent as CheckIcon } from '../assets/icons/icon-checkmark.svg'

const ResetPassword = () => {

    let navigate = useNavigate();

    const apiUrl = process.env.REACT_APP_API_URL;

    const params = useParams();

    const [tokenMatched, setTokenMatched] = useState(false);
    const [password, setPassword] = useState('');
    const [repassword, setRepassword] = useState('');
    const [isResetPassword, setResetPassword] = useState(false);
    
    const [passwordView, setPasswordView] = useState(false); 
    const [repasswordView, setRepasswordView] = useState(false); 

    const [passwordError, setPasswordError] = useState(false); 
    const [repasswordError, setRepasswordError] = useState(false); 
    const [passwordNotMatchError, setPasswordNotMatchError] = useState(false); 

    useEffect(() => {
        
        axios.get(apiUrl + '/reset/'+params['token'])
        .then((response) => {
            if(response.data && response.data.success == true) {
                setTokenMatched(true);
            }
        })
        .catch((error) => console.log('API error - ', error))
        
    }, [])

    const handleResetPassword = () => {

        if (!password || password == '') {
            setPasswordError(true);
            return false;
        } else {
            setPasswordError(false);
        }

        if (!repassword || repassword == '') {
            setRepasswordError(true);
            return false;
        } else {
            setRepasswordError(false);
        }

        if (password != repassword) {
            setPasswordNotMatchError(true);
            return false;
        } else {
            setPasswordNotMatchError(false);
        }

        axios.post(apiUrl + '/reset_password/'+params['token'], {"password": password})
        .then((response) => {
            if(response.data && response.data.success == true) {
                setResetPassword(true);
            }
        })
        .catch((error) => console.log('API error - ', error))
        
    }

    return (
        <div className='m-2 mt-8 md:m-8'>
            <div className='max-w-lg m-auto text-white'>
                <button onClick={()=>navigate(-1)} className='mb-3'>
                    <BackIcon />
                </button>
                {
                    !tokenMatched && 
                    <div className="font-bold text-3xl mt-6 text-center">Invalid reset token</div>
                }
                {
                    tokenMatched && isResetPassword == false && 
                    <>
                        <div className="font-bold text-3xl">SET NEW PASSWORD</div>
                        <div className='mt-6'>
                            <div className='flex items-center justify-between'>
                                <div className='font-bold'>New Password</div>
                            </div>
                            <div className='relative mt-2'>
                                <input type={`${passwordView?'text':'password'}`} className={`text-white rounded-md border border-neutral-400 h-14 w-full p-3 pr-12 bg-transparent ${passwordError?'border-red-600':''}`} placeholder='Password' value={password} onChange={(event) => setPassword(event.target.value)} />
                                <button 
                                    className='absolute top-0 right-0 p-3 h-14 flex items-center'
                                    onClick={() => setPasswordView(!passwordView)}
                                ><EyeIcon /></button>
                                <div className={`text-red-600 text-sm mt-2 ${passwordError?'':'hidden'}`}>This field is required.</div>
                            </div>
                        </div>
                        <div className='mt-6'>
                            <div className='flex items-center justify-between'>
                                <div className='font-bold'>Repeat New Password</div>
                            </div>
                            <div className='relative mt-2'>
                                <input type={`${repasswordView?'text':'password'}`} className={`text-white rounded-md border border-neutral-400 h-14 w-full p-3 pr-12 bg-transparent ${repasswordError||passwordNotMatchError?'border-red-600':''}`} placeholder='Confirm Password' value={repassword} onChange={(event) => setRepassword(event.target.value)} />
                                <button 
                                    className='absolute top-0 right-0 p-3 h-14 flex items-center'
                                    onClick={() => setRepasswordView(!repasswordView)}
                                ><EyeIcon /></button>
                                <div className={`text-red-600 text-sm mt-2 ${repasswordError?'':'hidden'}`}>This field is required.</div>
                                <div className={`text-red-600 text-sm mt-2 ${passwordNotMatchError?'':'hidden'}`}>Passwords do not match.</div>
                            </div>
                        </div>
                        <div className='mt-24'>
                            <button onClick={() => handleResetPassword()}
                            className="flex items-center justify-center gap-3 bg-white text-black mb-3 px-5 py-3 w-full rounded-md border-2 border-white hover:drop-shadow-xl"
                            >
                                <div className='font-bold uppercase'>Set new password</div>
                            </button>
                        </div>
                    </>
                }
                {
                    tokenMatched && isResetPassword == true && 
                    <>
                        <div className='mt-32'><CheckIcon /></div>
                        <div className="font-bold text-3xl mt-6">Password reset successful</div>

                        <div className='mt-24'>
                            <Link to='/login'  
                            className="flex items-center justify-center gap-3 bg-white text-black mb-3 px-5 py-3 w-full rounded-md border-2 border-white hover:drop-shadow-xl"
                            >
                                <div className='font-bold uppercase'>ACCEPT</div>
                            </Link>
                        </div>
                    </>
                }
                <ToastContainer />
            </div>
        </div>
    )
}

export default ResetPassword