import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'

const EmailVerification = () => {

    let navigate = useNavigate();
    
    return (
        <div className='m-2 mt-20 md:m-8'>
            <div className='max-w-lg m-auto text-white'>
                <div className="font-bold text-3xl mt-36">Almost there!</div>
                <div className="font-bold text-xl mt-2">Email verification</div>
                <div className='text-sm text-neutral-400 mt-4'>
                    We've sent an email with a verification link to the address you provided. Please follow the instructions on that email to verify your account.
                </div>
                
                <div className='mt-24'>
                    <button onClick={() => navigate('/login')}
                    className="flex items-center justify-center gap-3 bg-white text-black mb-3 px-5 py-3 w-full rounded-md border-2 border-white hover:drop-shadow-xl"
                    >
                        <div className='font-bold uppercase'>ACCEPT</div>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default EmailVerification