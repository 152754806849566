import React from "react";
import { Link } from 'react-router-dom'
import ReactCardFlip from "react-card-flip";
import { numberWithCommas } from '../config/common'

import { ReactComponent as StarIcon } from '../assets/icons/icon-star.svg'
import { ReactComponent as TimerIcon } from '../assets/icons/icon-timer.svg'
import { ReactComponent as UserIcon } from '../assets/icons/icon-user.svg'

const Card = ({card, status, user_id}) => {
    const [isFlipped, setIsFlipped] = React.useState(false);

    const handleFlip = (event) => {
      event.preventDefault();
      setIsFlipped((prev) => !prev)
    }

    return (
        <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal">
          <Link to={`/matches/`+ card.id}   className="CardFront">
            {
              status == 'pending' &&
                <div className="pt-1 text-center font-bold text-white">
                  REQUEST TO END MATCH RECEIVED
                </div>
            }
            {
              status == 'sent' &&
                <div className="pt-1 text-center font-bold text-white">
                  INVITE SENT
                </div>
            }
            {
              status == 'invite' &&
                <div className="pt-1 text-center font-bold text-white">
                  INVITE RECEIVED
                </div>
            }
            <div className={`flex justify-between card-header gap-2 bg-white ${(status != 'pending' && status != 'sent' && status != 'invite')?'rounded-t-xl':''} border-none`}>
              <Link to={`/matches/`+ card.id}   className="text-lg leading-tight italic font-bold uppercase text-black px-4 pt-2 pb-1">{card.title}</Link>
              <div className="basis-24 max-w-24 flex-1 flex shrink-0 card-item-type">{ card.match_type && card.match_type.image && <img src={card.match_type.image} className={`w-full object-cover ${(status != 'pending' && status != 'sent' && status != 'invite')?'rounded-tr-xl':''}`} /> }</div>
            </div>
            <div className="card-content px-2.5 md:px-5 py-2.5 text-white">
              <div className="flex items-center justify-center">
                { card.from_user_id != user_id ? 
                  <Link to={`/friends/`+ card.from_user.id} className="w-1/3 flex flex-col items-center">
                    <div className="mb-4 relative flex justify-center">
                      { card.from_user.picture ? <img className="border-4 rounded-md object-cover w-20 h-20" src={card.from_user.picture} /> : <UserIcon className="w-20 h-20" /> }
                      <div className="bg-white text-black flex w-fit absolute rounded flex items-center m-auto bottom-0 p-1 gap-1 card-item-cred">
                        <StarIcon />
                        <div className="leading-none text-sm font-bold">{card.from_user_profile.match_cred?card.from_user_profile.match_cred:'N/A'}</div>
                      </div>
                    </div>
                    <div className="font-bold text-center">{card.from_user.full_name}</div>
                    <div className="text-neutral-500 leading-none">{card.from_user.username}</div>
                  </Link>
                  :
                  <div className="w-1/3 flex flex-col items-center">
                    <div className="mb-4 relative flex justify-center">
                      { card.from_user.picture ? <img className="border-4 rounded-md object-cover w-20 h-20" src={card.from_user.picture} /> : <UserIcon className="w-20 h-20" /> }
                      <div className="bg-white text-black flex w-fit absolute rounded flex items-center m-auto bottom-0 p-1 gap-1 card-item-cred">
                        <StarIcon />
                        <div className="leading-none text-sm font-bold">{card.from_user_profile.match_cred?card.from_user_profile.match_cred:'N/A'}</div>
                      </div>
                    </div>
                    <div className="font-bold text-center">{card.from_user.full_name}</div>
                    <div className="text-neutral-500 leading-none">{card.from_user.username}</div>
                  </div>
                }
                <div className="w-1/3">
                  <div className="font-bold italic text-3xl text-center">VS</div>
                </div>
                { card.to_user_id != user_id ? 
                  <Link to={`/friends/`+ card.to_user.id} className="w-1/3 flex flex-col items-center">
                    <div className="mb-4 relative flex justify-center">
                      { card.to_user.picture ? <img className="border-4 rounded-md object-cover w-20 h-20" src={card.to_user.picture} /> : <UserIcon className="w-20 h-20" /> }
                      <div className="bg-white text-black flex w-fit absolute rounded flex items-center m-auto bottom-0 p-1 gap-1 card-item-cred">
                        <StarIcon />
                        <div className="leading-none text-sm font-bold">{card.to_user_profile.match_cred?card.to_user_profile.match_cred:'N/A'}</div>
                      </div>
                    </div>
                    <div className="font-bold text-center">{card.to_user.full_name}</div>
                    <div className="text-neutral-500 leading-none">{card.to_user.username}</div>
                  </Link>
                  :
                  <div className="w-1/3 flex flex-col items-center">
                    <div className="mb-4 relative flex justify-center">
                      { card.to_user.picture ? <img className="border-4 rounded-md object-cover w-20 h-20" src={card.to_user.picture} /> : <UserIcon className="w-20 h-20" /> }
                      <div className="bg-white text-black flex w-fit absolute rounded flex items-center m-auto bottom-0 p-1 gap-1 card-item-cred">
                        <StarIcon />
                        <div className="leading-none text-sm font-bold">{card.to_user_profile.match_cred?card.to_user_profile.match_cred:'N/A'}</div>
                      </div>
                    </div>
                    <div className="font-bold text-center">{card.to_user.full_name}</div>
                    <div className="text-neutral-500 leading-none">{card.to_user.username}</div>
                  </div>
                }
              </div>
              <div className="flex items-center my-3">
                <div className="w-1/3 bg-black rounded p-1 text-center">
                  <div className="font-bold text-lg">{numberWithCommas(card?.from_user_amount)} ETH</div>
                  <div className="italic text-neutral-400 text-xs">EXPOSURE</div>
                </div>
                <div className="w-1/3 px-4">
                  <div className="bg-neutral-900 rounded p-1 text-center">
                    <div className="font-bold text-lg">{card.multiplier}x</div>
                    <div className="italic text-neutral-400 text-xs">MULTIPLIER</div>
                  </div>
                </div>
                <div className="w-1/3 bg-black rounded p-1 text-center">
                  <div className="font-bold text-lg ">{numberWithCommas(card?.to_user_amount)} ETH</div>
                  <div className="italic text-neutral-400 text-xs">EXPOSURE</div>
                </div>
              </div>
              { /*
                (status == 'pending' || status == 'sent' || status == 'invite') &&
                  <div className="mb-3 flex justify-center items-center gap-1">
                    <TimerIcon />
                    <div>{card.time_left}</div>
                    <div>left</div>
                  </div>
                  */
              }
              {
                status == 'pending' &&
                  <div className="my-1">
                    <button
                      type='button'
                      className="text-white font-bold p-3 w-full rounded-md border-2 border-white uppercase hover:drop-shadow-xl hover:bg-black hover:border-black"
                      >
                        View end match request
                    </button>
                  </div>
              }
              {
                status == 'sent' &&
                  <div className="my-1">
                    <button
                      type='button'
                      className="text-white font-bold p-3 w-full rounded-md border-2 border-white uppercase hover:drop-shadow-xl hover:bg-black hover:border-black"
                      >
                        Cancel match request
                    </button>
                  </div>
              }
              {
                status == 'invite' &&
                  <div className="my-1 flex items-center gap-2">
                    <button
                      type='button'
                      className="text-white font-bold p-3 w-full rounded-md border-2 border-white uppercase hover:drop-shadow-xl hover:bg-black hover:border-black"
                      >
                        Decline
                    </button>
                    <button
                      type='button'
                      className="bg-white text-black font-bold p-3 w-full rounded-md border-2 border-white uppercase hover:drop-shadow-xl hover:bg-black hover:text-white hover:border-black"
                      >
                        Accept
                    </button>
                  </div>
              }
              <div className="pt-2 text-xs text-center text-neutral-400 cursor-pointer" onClick={(e) => handleFlip(e)}>
                Flip card for terms, conditions, and rules
              </div>
            </div>
          </Link>
          <div className="CardBack">
          {
              status == 'pending' &&
                <div className="pt-1 text-center font-bold text-white">
                  REQUEST TO END MATCH RECEIVED
                </div>
            }
            {
              status == 'sent' &&
                <div className="pt-1 text-center font-bold text-white">
                  INVITE SENT
                </div>
            }
            {
              status == 'invite' &&
                <div className="pt-1 text-center font-bold text-white">
                  INVITE RECEIVED
                </div>
            }
            <div className={`flex card-header gap-2 bg-white ${(status != 'pending' && status != 'sent' && status != 'invite')?'rounded-t-xl':''} border-none`}>
              <div className="text-lg leading-tight italic font-bold uppercase text-black px-4 pt-2 pb-1">{card.title}</div>
              <div className="basis-24 flex-1 flex shrink-0 card-item-type">{ card.match_type && card.match_type.image && <img src={card.type_image} className={`w-full object-cover ${(status != 'pending' && status != 'sent' && status != 'invite')?'rounded-tr-xl':''}`} /> }</div>
            </div>
            <div className="p-4 text-white">
              <div>{card.message}</div>
              <div className="pt-2 text-xs text-center text-neutral-400 cursor-pointer" onClick={(event) => handleFlip(event)}>
                Flip card for card info
              </div>  
            </div>
          </div>
        </ReactCardFlip>
    );
}

export default Card