import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useStateContext } from '../contexts/ContextProvider';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { ToastContainer, Bounce, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import { numberWithCommas } from '../config/common'

import { ReactComponent as TransferIcon } from '../assets/icons/icon-transfer.svg'
import { ReactComponent as BackIcon } from '../assets/icons/icon-back.svg'
import { ReactComponent as PencilIcon } from '../assets/icons/icon-pencil.svg'
import { ReactComponent as CheckIcon } from '../assets/icons/icon-checkmark.svg'
import { ReactComponent as ArrowBottomIcon } from '../assets/icons/icon-arrow-bottom.svg'

const Withdraw = () => {

    let navigate = useNavigate();

    const { authToken } = useStateContext();
    if(!authToken) navigate('/login'); 
    const apiUrl = process.env.REACT_APP_API_URL;
    const user = jwtDecode(authToken);

    const [step, setStep] = useState(1);
    const [walletData, setWalletData] = useState({}); 
    const [withdrawalAddress, setWithdrawalAddress] = useState(''); 

    const [walletError, setWalletError] = useState(false); 

    useEffect(() => {
        
        if(authToken) {
            axios.defaults.headers.common["Authorization"] = "Bearer " + authToken;
        } else {
            delete axios.defaults.headers.common["Authorization"];
        }

        axios.get(apiUrl + '/wallets/'+user.id)
        .then((response) => {
            if(response.data && response.data.success == true && response.data.data) {
                setWalletData(response.data.data);
            } else {
                axios.post(apiUrl + '/wallets/', {"user_id": user.id})
                .then((response) => {
                    if(response.data && response.data.success == true && response.data.data) {
                        setWalletData(response.data.data);
                    }
                })
            }
        })

    }, [])

    const handleGoToConfirmation = () => {
        if (!withdrawalAddress || withdrawalAddress == '') {
            setWalletError(true);
            return false;
        } else {
            setWalletError(false);
        }

        setStep(2);
    }

    const handleWithdrawRequest = () => {
        if (walletData.available <= 0) {
            toast('No available funds to withdraw.', {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce
            });

            return false;
        }

        
        axios.put(apiUrl + '/wallets/'+user.id, {"withdraw_address": withdrawalAddress, "is_withdraw_requested": true, "last_requested": moment()})
        .then((response) => {
            if(response.data && response.data.success == true) {
                setStep(3);
            }
        })
        .catch((error) => console.log('API error - ', error))
        
    }

    return (
        <div className='m-2 mt-20 md:m-8'>
            <div className='max-w-lg m-auto text-white'>
                <button onClick={()=>navigate(-1)} className='mb-3'>
                    <BackIcon />
                </button>
                { 
                    step == 1 &&
                    <>
                        <div className="font-bold text-3xl">Withdraw funds</div>
                        <div className='mt-3'>
                            <div className='flex items-center justify-between gap-3 rounded-lg bg-neutral-700 p-3'>
                                <div className='w-1/3'>
                                    <div className='text-3xl font-bold'>{walletData?.available > 0 ? numberWithCommas(walletData?.available) : 0} ETH</div>
                                    <div className='text-sm text-neutral-400 uppercase'>Available Balance</div>
                                </div>
                                <div className='w-1/3'>
                                    <TransferIcon className='m-auto' />
                                </div>
                                <div className='w-1/3'>
                                    <div className='text-3xl font-bold'>{walletData?.escrow > 0 ? numberWithCommas(walletData?.escrow) : 0} ETH</div>
                                    <div className='text-sm text-neutral-400 uppercase'>Escrow Balance</div>
                                </div>
                            </div>
                        </div>
                        <div className='mt-6'>
                            <div className='font-bold'>Destination Wallet Address</div>
                            <div className='relative mt-2'>
                                <input className={`text-white text-left rounded-md border-2 border-neutral-300 h-14 w-full p-2 bg-transparent ${walletError?'border-red-600':''}`} value={withdrawalAddress} onChange={(event)=>setWithdrawalAddress(event.target.value)} />
                                <div className={`text-red-600 text-sm mt-2 ${walletError?'':'hidden'}`}>This field is required.</div>
                            </div>
                        </div>
                        <div className='mt-24'>
                            <div className="flex items-center gap-3">
                                <button onClick={() => handleGoToConfirmation()}
                                className="bg-white text-sm text-black p-3 w-full rounded-md border-2 border-white uppercase hover:drop-shadow-xl text-center"
                                >
                                    <div className='font-bold'>GO TO CONFIRMATION</div>
                                </button>
                            </div>
                        </div>
                    </>
                }
                { 
                    step == 2 &&
                    <>
                        <div className="font-bold text-3xl">Confirm withdrawal</div>
                        <div className='mt-3 rounded-lg bg-neutral-700 p-4'>
                            <div className='flex items-center justify-between gap-3'>
                                <div className=''>
                                    <div className='text-sm text-neutral-400'>Amount to withdraw</div>
                                    <div className='font-medium'>{numberWithCommas(walletData?.available)} ETH</div>
                                </div>
                            </div>
                            <div className='flex items-center justify-between gap-3 mt-4'>
                                <div className=''>
                                    <div className='text-sm text-neutral-400'>Destination</div>
                                    <div className='font-medium'>{withdrawalAddress}</div>
                                </div>
                                <button onClick={()=> setStep(1)}>
                                    <PencilIcon />
                                </button>
                            </div>
                        </div>
                        <div className='mt-24'>
                            <div className="flex items-center gap-3">
                                <button onClick={() => handleWithdrawRequest()}
                                className="bg-white text-sm text-black p-3 w-full rounded-md border-2 border-white uppercase hover:drop-shadow-xl text-center"
                                >
                                    <div className='font-bold'>CONFIRM WITHDRAW REQUEST</div>
                                </button>
                            </div>
                        </div>
                    </>
                }
                { 
                    step == 3 &&
                    <>
                        <div className="font-bold text-3xl">Your money is on its way</div>
                        <div className='text-sm text-neutral-400 my-4'>You should see the transaction in your account in a few days. </div>
                        <div className='mt-3 rounded-lg bg-neutral-700 p-4 text-center'>
                            <div className='text-sm text-neutral-400'>You withdrew</div>
                            <div className='font-bold text-3xl'>{numberWithCommas(walletData?.available)} ETH</div>
                            <div className='text-sm text-neutral-400'>{ moment().format('DD MMM YYYY') }</div>
                            <div className='flex items-center justify-center gap-2 mt-3'>
                                <CheckIcon />
                                <div className='text-[#6FFF33] text-sm'>Funds will be reached in a few days.</div>
                            </div>
                        </div>
                        <div className='flex items-center justify-end gap-2 mt-3 text-right'>
                            <ArrowBottomIcon />
                            <div className='text-sm'>Receipt</div>
                        </div>
                        <div className='mt-24'>
                            <div className="flex items-center gap-3">
                                <Link
                                to='/wallet'
                                className="bg-white text-sm text-black p-3 w-full rounded-md border-2 border-white uppercase hover:drop-shadow-xl text-center"
                                >
                                    <div className='font-bold'>Wallet</div>
                                </Link>
                            </div>
                        </div>
                    </>
                }
                <ToastContainer />
            </div>
        </div>
    )
}

export default Withdraw