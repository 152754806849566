import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { ReactComponent as BackIcon } from '../assets/icons/icon-back.svg'

const PrivacyPolicy = () => {

    let navigate = useNavigate();

    return (
        <div className='m-2 mt-20 md:m-8'>
            <div className='max-w-3xl m-auto text-white'>
                <button onClick={()=>navigate(-1)} className='mb-3'>
                    <BackIcon />
                </button>
                <div className="font-bold text-3xl">Money Match: Privacy Policy</div>
                <div className="font-bold text-4xl mt-6">Money Match Technology Company, LLC</div>
                <div className='mt-6'>
                    <div className='text-neutral-300'>
                        <p className='mb-4'>Money Match Technology Company, LLC owns and operates the Website that links to these terms (“Money Match”, ”Money Match: Bet Manager app”, “wwwi.getmoneymatch.com”, “https://moneymatch-frontend-v3-4e2c1171be41.herokuapp.com" or the "Company"). We are pleased to offer you access to our website and the ability to participate in Money match gaming and promotions (collectively the "Services") that we may provide from our website, or mobile application(s) subject to these terms.</p>
                        <p className='mb-4'>Money Match Technology Company, LLC is a company registered in United States of America under Colorado Limited Liability Company Act 1990, having its registered office at 555 South Elizabeth Street, Denver, Colorado, 80209 555 17th Street, Denver, Colorado, 80202, USA. This Privacy Policy describes the types of personal information that we collect, how we use such information, with whom we share it, and the choices available regarding our use of the information. We also describe the measures we take to protect the security of the information and how to contact us about our privacy practices.</p>
                        <p className='mb-4'>Your use of the website is subject to the terms of use & policies set forth in this Privacy Policy. Money match is a gaming website built to make your gaming experience as enjoyable and competitive as possible. This Privacy Policy applies to the personal information collected on the website of Money Match or document that links to this Privacy Policy. This Privacy Policy is only applicable to the aforesaid matters and shall not be presumed as applicable to any other gaming platforms that are running under similar name or logo on any other website. Unless otherwise indicated at the time that you provide your personal information, any personal information collected at third-party website is subject to this Privacy Policy. This Privacy Policy does not apply to our data collection activities offline or otherwise outside of our Services (unless otherwise stated below), and does not govern the data practices of third parties that may interact with our Services.</p>
                        <p className='mb-4'>PLEASE READ THIS PRIVACY POLICY CAREFULLY, BY ACCESSING OR USING THE COMPANY SERVICE, YOU ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTAND, AND AGREE TO BE BOUND BY ALL THE TERMS OF THIS PRIVACY POLICY AND OUR TERMS OF SERVICE. IF YOU DO NOT AGREE TO SUCH TERMS, EXIT THIS PAGE AND DO NOT ACCESS OR USE THE COMPANY SERVICE. </p>
                    </div>
                </div>
                <div className='mt-6'>
                    <div className='mb-4 underline'>INFORMATION WE COLLECT:</div>
                    <div className='text-neutral-300'>
                        <p className='mb-4'>We may collect or may have collected the following categories of information about you.</p>
                        <ul className='list-disc mb-4 ml-8'>
                            <li className='mb-2'>Contact information and personal identifiers, such as your name, address, email address, telephone number, and username or social media handle.</li>
                            <li className='mb-2'>Name</li>
                            <li className='mb-2'>Address</li>
                            <li className='mb-2'>Email (must be verified)</li>
                            <li className='mb-2'>Phone</li>
                            <li className='mb-2'>Username</li>
                            <li className='mb-2'>Facebook, Google oauth2 information</li>
                        </ul>
                    </div>
                </div>
                <div className='mt-6'>
                    <div className='mb-4 underline'>HOW WE COLLECT INFORMATION: </div>
                    <div className='text-neutral-300'>
                        <p className='mb-4'>We may collect or may have collected personal information about you from various sources. The categories of sources from which we may collect personal information are: </p>
                        <ul className='list-disc mb-4 ml-8'>
                            <li className='mb-2'>Directly from you, such as when you participate in a league, contact us with a question or complaint, use one of our mobile or desktop website, create an account on our website, subscribe for newsletter, respond to a survey, participate in a contest or other promotion, or sign-up to receive marketing communications.</li>
                            <li className='mb-2'>Cookies and other automatic collection methods. When you visit our website or use one of our mobile sites, and when you open or click on emails we send you, we may automatically collect information from your browser or device using technologies such as cookies, web beacons, pixel tags, and similar technologies. Cookies are a website’s way of remembering who you are. They are small text files that websites send to your computer or other Internet-connected device to uniquely identify your browser or to store information or settings in your browser. We use them as they aid us to identify account holders and to optimize their experience on our Services. Web beacons or pixel tags are small images which are embedded into our website or emails that provide us with information about your browser or device, or whether you open or click on the emails we send you. These technologies enable us, to collect information such as device identifiers and online or other network activity information. </li>
                            <li className='mb-2'>Through call recording technology when you speak to customer service or any other offline & online technologies we provide.</li>
                            <li className='mb-2'>From social media platforms and networks, such as Facebook, Twitter, Pinterest, and Instagram. For example, we may obtain your information from a social media platform or network if you interact with us on social media or choose to log-in to our website using your social media credentials.</li>
                            <li className='mb-2'>From other Companies, brands or affiliates that you have interacted with.</li>
                        </ul>
                    </div>
                </div>
                <div className='mt-6'>
                    <div className='mb-4 underline'>HOW WE USE INFORMATION</div>
                    <div className='text-neutral-300'>
                        <p className='mb-4'>We may use or may have used the information we have about you:</p>
                        <ul className='list-disc mb-4 ml-8'>
                            <li className='mb-2'>We may use cookies or other Tracking Technologies that are useful in order to assess the performance of the Services, including as part of our analytic practices or otherwise to improve the content, ads, horse racing, gaming service or services offered through the Services.</li>
                            <li className='mb-2'>To administer your account and participation in special events, contests, surveys, promotions, newsletter subscriptions or our any other current or future membership programs.</li>
                            <li className='mb-2'>For marketing and advertising, such as to send you marketing and advertising materials via, text message or email, and to show you advertisements for money match gaming services tailored to your interests and location on social media and other websites.</li>
                            <li className='mb-2'>For analytics purposes, such as to understand how you use our website and mobile applications, understand your preferred method with us; determine what browser and devices you use to visit our website or mobile applications; and to evaluate and improve our money match gaming service, advertisements, website and mobile applications.</li>
                            <li className='mb-2'>To operate and improve our business, to provide quality assurance, conduct research and development to develop new money match gaming services, and perform accounting, auditing and other internal business functions.</li>
                            <li className='mb-2'>For legal and security purposes, such as to detect, prevent, and prosecute harmful, fraudulent, or illegal activity, loss prevention, identify and repair bugs on our website or mobile applications, and to comply with applicable legal requirements, relevant industry standards and our policies.</li>
                        </ul>
                        <p className='mb-4'>We also may use or may have used the information in other ways for which we provide specific notice at the time of collection. </p>
                    </div>
                </div>
                <div className='mt-6'>
                    <div className='mb-4 underline'>INFORMATION WE SHARE: </div>
                    <div className='text-neutral-300'>
                        <p className='mb-4'>We may share or may have shared your personal and non-personal information with: </p>
                        <ul className='list-disc mb-4 ml-8'>
                            <li className='mb-2'>Our existing or future corporate brands and affiliates. </li>
                            <li className='mb-2'>Service providers. We may transfer personal information to service providers who perform services on our behalf based on our instructions. We do not authorize these service providers to use or disclose the information except as necessary to perform services on our behalf or comply with legal requirements. Examples of these service providers include entities that process credit card payments, and that provide website and application functionality, hosting, analytics, advertising and marketing services.</li>
                            <li className='mb-2'>Parties to a corporate transaction. We also reserve the right to transfer personal information we have about you in the event we sell or transfer all or a portion of our business or assets (including in the event of a merger, acquisition, joint venture, reorganization, divestiture, dissolution, or liquidation). </li>
                        </ul>
                    </div>
                </div>
                <div className='mt-6'>
                    <div className='mb-4 underline'>YOUR RIGHTS AND CHOICES: </div>
                    <div className='mb-4 underline italic'>ACCESSING, UPDATING, AND MODIFYING PERSONAL INFORMATION </div>
                    <div className='text-neutral-300'>
                        <p className='mb-4'>Subject to applicable law, you may have the right to request access to and receive details about the personal information we maintain about you, update and correct inaccuracies in your personal information, and have the personal information deleted, as appropriate. These rights may be limited in some circumstances by local law. We may take reasonable steps to verify your identity before granting access or making corrections. You may request to access, change, or delete your personal information by sending us an email at  support@getmoneymatch.com. </p>
                    </div>
                    <div className='mb-4 underline italic'>EMAIL OPT-OUT</div>
                    <div className='text-neutral-300'>
                        <p className='mb-4'>You can opt-out of receiving marketing or newsletter subscription related communications by email by following the instructions within the emails you receive from us or by contacting us through our customer support email address. Please note that your opt-out request is specific to the particular type of email communication you receive from us. For example, if you opt-out from a newsletter subscription, you will no longer receive newsletter email communications, but you may still receive email marketing communications from us. Further, if you opt-out from both newsletter email and marketing email communications, we may still send you transactional or operational emails. Examples of transactional or operational emails include password resets, profile updates or other account related messages.</p>
                    </div>
                </div>
                <div className='mt-6'>
                    <div className='mb-4 underline'>NATIONAL TRANSFERS </div>
                    <div className='text-neutral-300'>
                        <p className='mb-4'>We do not transfer the personal information we have about you to countries/states other than the country/state in which the information was originally collected, and your personal information may be processed and stored inside of your country/state of residence. The data protection laws of Colorado shall be applicable and your personal information will be subject to applicable state laws. </p>
                    </div>
                </div>
                <div className='mt-6'>
                    <div className='mb-4 underline'>CHILDREN'S PRIVACY </div>
                    <div className='text-neutral-300'>
                        <p className='mb-4'>This website is not intended for or directed to children under the age of eighteen (18) and we do not knowingly collect personal information from children under the age of thirteen on the website. If we become aware that a child under the age of eighteen (18) has provided us with personal information, we will delete the information from our records. Age (21+), (18+) in some jurisdictions. Cannot use services in any country, state, or providence where gaming is illegal or restricted according to local law.</p>
                    </div>
                </div>
                <div className='mt-6'>
                    <div className='mb-4 underline'>UPDATES TO OUR PRIVACY POLICY </div>
                    <div className='text-neutral-300'>
                        <p className='mb-4'>This Privacy Policy may be updated periodically and without prior notice or consent to you to reflect changes in our personal information practices. You agree that it is your responsibility to check any updates in the privacy policy from time to time. However, we might post a prominent notice on our website to notify you of any significant changes to our privacy practices and indicate at the top of the Privacy Policy when it was most recently updated.</p>
                    </div>
                </div>
                <div className='mt-6'>
                    <div className='mb-4 underline'>HOW TO CONTACT US </div>
                    <div className='text-neutral-300'>
                        <p className='mb-4'>If you have any questions or comments about this Privacy Policy, please contact us by email at support@getmoneymatch.com If we need, or are required, to contact you concerning any event that involves your personal information, we may do so by telephone or email. </p>
                        <p className='mb-4'>These disclosures take place for the following business or commercial purposes: </p>
                        <ul className='list-disc mb-4 ml-8'>
                            <li className='mb-2'>To audit our interactions with you to ensure compliance with applicable law and to measure the effectiveness of our money match gaming service, services, and advertisements;</li>
                            <li className='mb-2'>To detect, prevent, and prosecute harmful, fraudulent, or illegal activity;</li>
                            <li className='mb-2'>To identify and repair bugs on our website or mobile applications;</li>
                            <li className='mb-2'>To provide services, such as customer service, order fulfilment, and payment processing, which we either conduct or engage service providers to conduct on our behalf;</li>
                            <li className='mb-2'>For research and development;</li>
                            <li className='mb-2'>To further our business goals, including to advertise our money match gaming services; and </li>
                            <li className='mb-2'>For quality assurance.</li>
                        </ul>
                    </div>
                </div>
                <div className='mt-6'>
                    <div className='mb-4 underline'>HOW TO ENSURE PRIVACY WHILE PLAYING THIS GAME</div>
                    <div className='text-neutral-300'>
                        <ul className='list-disc mb-4 ml-8'>
                            <li className='mb-2'>Ensure security of connections; Ensure that all data transmission between the app and servers is encrypted using SSL or other secure protocols to protect user information from interception.</li>
                            <li className='mb-2'>Maintain Account Security; Ensure that the account related information is kept private and secure. Create unique passwords and enable two-factor authentication to secure your accounts.</li>
                            <li className='mb-2'>Ensure Transaction Security; keep your financial transactions secure, ensure one on one encryption and secure payment gateways, to safeguard your payment information.</li>
                            <li className='mb-2'>Fair Gaming Practices; we ensure fair gameplay and prevent cheating or fraud, demonstrating a commitment to maintaining a level playing field for all users. </li>
                            <li className='mb-2'>Keep Regular Updates; keep the app/website updated to benefit from the latest security enhancements and bug fixes, thus ensuring a safer gaming experience. </li>
                            <li className='mb-2'>Data Deletion Upon Request; your personal data will be deleted upon request, subject to legal obligations, once you decide to close their account or stop using the app. </li>
                        </ul>
                    </div>
                </div>
                <div className='mt-6'>
                    <div className='mb-4 underline'>ANY RESTRICTIONS</div>
                    <div className='text-neutral-300'>
                        <p className='mb-4'>Even though Money Match is not a gambling site or service since the company does not arbitrate matches nor do users wager against the house, Money Match is not legal or available in jurisdictions where online gambling is illegal. </p>
                    </div>
                </div>
                <div className='mt-6'>
                    <div className='mb-4 underline'>TAX INFORMATION</div>
                    <div className='text-neutral-300'>
                        <p className='mb-4'>You consent to receive your Money Match account related tax information electronically, which information will be securely hosted by Money Match and accessible by you through your player account page for at least seven (7) years following the year to which the information relates. Your electronic tax information will be furnished on a downloadable PDF file, which you should be able to access and print using a standard PDF reader. You may contact Money Match at Money Match@gmail.com . If you cannot access your electronic tax information or if you wish to request a paper copy of your tax information. If you wish to revoke your consent to receiving your tax information electronically, you may contact us at Money Match@gmail.com to arrange for all of your post revocation tax information to be provided to you in paper form. In order to update your recipient information, please use the instructions for changing your personal information set forth below. Changing or Deleting Your Personal Information: You may change any of your personal information in your Account by simply editing it within your Account or by requesting at Money Match@gmail.com.</p>
                    </div>
                </div>
                <div className='mt-6'>
                    <div className='mb-4 underline'>REFUND UNDER ANY CIRCUMSTANCES:</div>
                    <div className='text-neutral-300'>
                        <p className='mb-4'>Since we do not arbitrate matches, we do not offer any refunds relating to match conduct, i.e. for lost, disputed, or tied matches.</p>
                        <p className='mb-4'>We provide refunds under a limited number of circumstances, including: </p>
                        <p className='mb-4'>Inability to withdraw funds, double charges, inaccurate amounts of money transferred, etc, due to technological issues i.e., issues with a payment processor. This needs to be addressed to our support team. </p>
                        <p className='mb-4'>Review of extraordinary circumstances by our support staff due to users mistakenly making a transaction.</p>
                    </div>
                </div>
                <div className='mt-6'>
                    <div className='mb-4 underline'>ADDITIONAL RIGHTS AND DISCLOSURES</div>
                    <div className='text-neutral-300'>
                        <p className='mb-4'>We may disclose or may have disclosed your personal information to certain categories of third-parties, as described below.</p>
                    </div>
                    <table class="border-collapse border border-slate-400 ...">
                        <thead>
                            <tr>
                                <th class="border border-slate-300 p-3 font-bold">Category</th>
                                <th class="border border-slate-300 p-3 font-bold">Disclose to Third Parties</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="border border-slate-300 p-3">Contact information and personal identifiers</td>
                                <td class="border border-slate-300 p-3">
                                    <p className='mb-2'>We may disclose or may have disclosed this information to:</p>
                                    <ul className='list-disc ml-8'>
                                        <li className='mb-2'>our corporate brands and affiliates</li>
                                        <li className='mb-2'>service providers</li>
                                        <li className='mb-2'>fraud detection providers</li>
                                        <li className='mb-2'>law enforcement authorities or other government officials where required or permitted by law</li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 p-3">Device Identifiers</td>
                                <td class="border border-slate-300 p-3">
                                    <p className='mb-2'>We may disclose or may have disclosed this information to:</p>
                                    <ul className='list-disc ml-8'>
                                        <li className='mb-2'>our corporate brands and affiliates</li>
                                        <li className='mb-2'>service providers</li>
                                        <li className='mb-2'>fraud detection providers</li>
                                        <li className='mb-2'>law enforcement authorities or other government officials where required or permitted by law</li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 p-3">Demographic information</td>
                                <td class="border border-slate-300 p-3">
                                    <p className='mb-2'>We may disclose or may have disclosed this information to:</p>
                                    <ul className='list-disc ml-8'>
                                        <li className='mb-2'>our corporate brands and affiliates</li>
                                        <li className='mb-2'>service providers</li>
                                        <li className='mb-2'>fraud detection providers</li>
                                        <li className='mb-2'>law enforcement authorities or other government officials where required or permitted by law</li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 p-3">Physical characteristics</td>
                                <td class="border border-slate-300 p-3">
                                    <p className='mb-2'>We may disclose or may have disclosed this information to:</p>
                                    <ul className='list-disc ml-8'>
                                        <li className='mb-2'>our corporate brands and affiliates</li>
                                        <li className='mb-2'>service providers</li>
                                        <li className='mb-2'>fraud detection providers</li>
                                        <li className='mb-2'>law enforcement authorities or other government officials where required or permitted by law</li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 p-3">Commercial information</td>
                                <td class="border border-slate-300 p-3">
                                    <p className='mb-2'>We may disclose or may have disclosed this information to:</p>
                                    <ul className='list-disc ml-8'>
                                        <li className='mb-2'>our corporate brands and affiliates</li>
                                        <li className='mb-2'>service providers</li>
                                        <li className='mb-2'>fraud detection providers</li>
                                        <li className='mb-2'>law enforcement authorities or other government officials where required or permitted by law</li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 p-3">Payment information</td>
                                <td class="border border-slate-300 p-3">
                                    <p className='mb-2'>We may disclose or may have disclosed this information to:</p>
                                    <ul className='list-disc ml-8'>
                                        <li className='mb-2'>our corporate brands and affiliates</li>
                                        <li className='mb-2'>service providers</li>
                                        <li className='mb-2'>fraud detection providers</li>
                                        <li className='mb-2'>law enforcement authorities or other government officials where required or permitted by law</li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 p-3">Identity verification information</td>
                                <td class="border border-slate-300 p-3">
                                    <p className='mb-2'>We may disclose or may have disclosed this information to:</p>
                                    <ul className='list-disc ml-8'>
                                        <li className='mb-2'>our corporate brands and affiliates</li>
                                        <li className='mb-2'>service providers</li>
                                        <li className='mb-2'>fraud detection providers</li>
                                        <li className='mb-2'>law enforcement authorities or other government officials where required or permitted by law</li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 p-3">Online or network activity information</td>
                                <td class="border border-slate-300 p-3">
                                    <p className='mb-2'>We may disclose or may have disclosed this information to:</p>
                                    <ul className='list-disc ml-8'>
                                        <li className='mb-2'>our corporate brands and affiliates</li>
                                        <li className='mb-2'>service providers</li>
                                        <li className='mb-2'>fraud detection providers</li>
                                        <li className='mb-2'>law enforcement authorities or other government officials where required or permitted by law</li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 p-3">Geolocation information</td>
                                <td class="border border-slate-300 p-3">
                                    <p className='mb-2'>We may disclose or may have disclosed this information to:</p>
                                    <ul className='list-disc ml-8'>
                                        <li className='mb-2'>our corporate brands and affiliates</li>
                                        <li className='mb-2'>service providers</li>
                                        <li className='mb-2'>fraud detection providers</li>
                                        <li className='mb-2'>law enforcement authorities or other government officials where required or permitted by law</li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 p-3">Audio and visual information</td>
                                <td class="border border-slate-300 p-3">
                                    <p className='mb-2'>We may disclose or may have disclosed this information to:</p>
                                    <ul className='list-disc ml-8'>
                                        <li className='mb-2'>our corporate brands and affiliates</li>
                                        <li className='mb-2'>service providers</li>
                                        <li className='mb-2'>law enforcement authorities or other government officials where required or permitted by law</li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 p-3">Professional or employment related information</td>
                                <td class="border border-slate-300 p-3">
                                    <p className='mb-2'>We may disclose or may have disclosed this information to:</p>
                                    <ul className='list-disc ml-8'>
                                        <li className='mb-2'>our corporate brands and affiliates</li>
                                        <li className='mb-2'>service providers</li>
                                        <li className='mb-2'>law enforcement authorities or other government officials where required or permitted by law</li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 p-3">Health and medical information</td>
                                <td class="border border-slate-300 p-3">
                                    <p className='mb-2'>We may disclose or may have disclosed this information to:</p>
                                    <ul className='list-disc ml-8'>
                                        <li className='mb-2'>our corporate brands and affiliates</li>
                                        <li className='mb-2'>service providers</li>
                                        <li className='mb-2'>law enforcement authorities or other government officials where required or permitted by law</li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 p-3">User Content</td>
                                <td class="border border-slate-300 p-3">
                                    <p className='mb-2'>We may disclose or may have disclosed this information to:</p>
                                    <ul className='list-disc ml-8'>
                                        <li className='mb-2'>our corporate brands and affiliates</li>
                                        <li className='mb-2'>service providers</li>
                                        <li className='mb-2'>law enforcement authorities or other government officials where required or permitted by law</li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 p-3">Inferences</td>
                                <td class="border border-slate-300 p-3">
                                    <p className='mb-2'>We may disclose or may have disclosed this information to:</p>
                                    <ul className='list-disc ml-8'>
                                        <li className='mb-2'>our corporate brands and affiliates</li>
                                        <li className='mb-2'>service providers</li>
                                        <li className='mb-2'>law enforcement authorities or other government officials where required or permitted by law</li>
                                    </ul>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className='mt-6'>
                    <div className='mb-4 underline'>DISCLAIMER OF WARRANTIES:</div>
                    <div className='text-neutral-300'>
                        <p className='mb-4'>We do not tolerate any form of abuse, harassment, or discrimination. This includes, but is not limited to, derogatory comments based on race, ethnicity, religion, sexual orientation, gender identity, or disability.</p>
                        <p className='mb-4'>No illegal activity. Money Match is not a platform for illegal activity of any kind. This includes, but is not limited to, the buying or selling of illegal goods or services, the sharing of copyrighted material, or the distribution of illegal drugs.</p>
                        <p className='mb-4'>Do not spam or engage in any deceptive or misleading practices. Money Match is not a platform for spamming or any other deceptive or misleading practices. This includes, but is not limited to, the sharing of links to inappropriate or malicious websites, or the sending of unsolicited messages.</p>
                        <p className='mb-4'>Do not engage in any activity that could compromise the security or integrity of the platform. Money Match is committed to maintaining the security and integrity of our platform. Any activity that could compromise this, such as hacking or tampering with the platform, is strictly prohibited.</p>
                        <p className='mb-4'>Specific (to Money Match): </p>
                        <ul className='list-disc mb-4 ml-8'>
                            <li className='mb-2'>Falsification of any legal disclosures as to age, name, location, especially in jurisdictions that prohibit online gambling.</li>
                            <li className='mb-2'>Falsification of any information during KYC process or any other process to any of our approved payment providers or other vendors is also not allowed.</li>
                            <li className='mb-2'>Cheating and any other forms of purposeful dishonesty. Users that are proven or suspected of disputing matches fraudulently, meaning that they select an outcome they knowingly have reason to believe did not occur should be banned.</li>
                            <li className='mb-2'>Fixing matches and collusion. In tournament play, users are prohibited from fixing matches. This means that users are not allowed to collude with other users to advance in matches.</li>
                            <li className='mb-2'>Disclosure of personal information to other users. Users are not allowed to disclose personal information to other users on Money Match that is outside the scope of engaging in approved activities on the app.</li>
                            <li className='mb-2'>Unreachability and disconnecting: Users that do not submit a match result after certain lengths of time (1 week) and after being reminded to submit a result by other participant(s). </li>
                            <li className='mb-2'>Any of these behaviors may get a user a lifetime ban.</li>
                        </ul>
                    </div>
                </div>
                <div className='mt-6'>
                    <div className='mb-4 underline'>ARBITRATION:</div>
                    <div className='text-neutral-300'>
                        <p className='mb-4'>Any and all disputes, claims or controversies arising out of or relating to this Agreement or terms, the breach thereof, or any use of the Website (including all commercial transactions conducted through the Website) ("Claims"), except for claims filed in a small claims court that proceed on an individual (non-class, nonrepresentative) basis, shall be settled by binding arbitration before a single arbitrator. In agreeing to arbitrate all Claims, you and money match waive all rights to a trial by jury in any action or proceeding involving any Claim. The arbitration shall be held in Colorado, and judgment on the award rendered by the arbitrator may be entered by any court having jurisdiction thereof. This arbitration undertaking is made pursuant to and in connection with a transaction involving interstate commerce, and shall be governed by and construed and interpreted in accordance with the USA arbitration . This arbitration provision shall survive termination of this Agreement. Subject to the limitations set forth below, the arbitrator shall have authority to award legal and equitable relief available in the courts of the State of Colorado, provided that:</p>
                        <p className='mb-4'>The arbitrator shall not have authority to award punitive damages; and</p>
                        <p className='mb-4'>Any and all claims shall be arbitrated on an individual basis only, and shall not be consolidated or joined with or in any  or other proceeding involving a Claim of any other party. You and Colorado agree that the arbitrator shall have no authority to arbitrate any Claim as a class action or in any other form other than on an individual basis.</p>
                        <p className='mb-4'>For any Claims that are not subject to arbitration:</p>
                        <p className='mb-4'>The exclusive jurisdiction and venue for proceedings involving Claims shall be the courts of competent jurisdiction sitting within Colorado and the parties hereby waive any argument that any such court does not have personal jurisdiction or that the Forum is not appropriate or convenient. </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PrivacyPolicy