import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { ReactComponent as SearchIcon } from '../assets/icons/icon-search.svg'
import Friend from '../components/Friend';
import { useStateContext } from '../contexts/ContextProvider';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { ToastContainer, Bounce, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';

const Friends = () => {

    let navigate = useNavigate();

    const { authToken } = useStateContext();
    if(!authToken) navigate('/login'); 
    const apiUrl = process.env.REACT_APP_API_URL;
    const user = jwtDecode(authToken);

    const [searchData, setSearchData] = useState([]); 
    const [requestData, setRequestData] = useState([]); 
    const [recentlyData, setRecentlyData] = useState([]); 
    const [allData, setAllData] = useState([]); 

    const [searchTerm, setSearchTerm] = useState(''); 
    const [searchedList, setSearchedList] = useState([]); 

    useEffect(() => {
        
        if(authToken) {
            axios.defaults.headers.common["Authorization"] = "Bearer " + authToken;
        } else {
            delete axios.defaults.headers.common["Authorization"];
        }

        initData();
    }, [])

    
    function initData() {
        axios.get(apiUrl + '/friends_all_users?user_id='+user.id)
        .then((response) => {
            if(response.data && response.data.success == true) {
                setSearchData(response.data.data);
            }
        })
        .catch((error) => console.log('API error - ', error))

        axios.get(apiUrl + '/friends_all?user_id='+user.id)
        .then((response) => {
            if(response.data && response.data.success == true) {
                setAllData(response.data.data);
            }
        })
        .catch((error) => console.log('API error - ', error))

        axios.get(apiUrl + '/friends_request?user_id='+user.id)
        .then((response) => {
            if(response.data && response.data.success == true) {
                setRequestData(response.data.data);
            }
        })
        .catch((error) => console.log('API error - ', error))

        axios.get(apiUrl + '/friends_recently_played?user_id='+user.id)
        .then((response) => {
            if(response.data && response.data.success == true) {
                setRecentlyData(response.data.data);
            }
        })
        .catch((error) => console.log('API error - ', error))

    }

    useEffect(() => {
		var filtered = searchData.filter(x => x.full_name.toLowerCase().includes(searchTerm.toLowerCase()));
		setSearchedList(filtered);
	}, [searchTerm]);

    const handleChallenge = (friend_user_id) => {
        navigate('/new-match/'+friend_user_id);
    }

    const handleAddFriend = (friend_user_id) => {
        if(confirm("Are you sure you want to send a friend request to this friend?") == true) {
            axios.post(apiUrl + '/friends', {from_user_id: user.id, to_user_id: friend_user_id, status: 0, created: moment()})
            .then((response) => {
                if(response.data) {
                    toast(response.data.msg, {
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        transition: Bounce
                    });

                    if(response.data.success == true) {
                        initData();
                    }
                }
            })
            .catch((error) => console.log('API error - ', error));
        }
    }

    const handleFriendAccept = (friend_user_id) => {
        if(confirm("Are you sure you want to accept this friend request?") == true) {
            axios.put(apiUrl + '/friends_by_user_id/'+user.id+'/'+friend_user_id, {status: 1, accepted: moment()})
            .then((response) => {
                if(response.data) {
                    toast(response.data.msg, {
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        transition: Bounce
                    });

                    if(response.data.success == true) {
                        initData();
                    }
                }
            })
            .catch((error) => console.log('API error - ', error));
        }
    }

    const handleFriendDecline = (friend_user_id) => {
        if(confirm("Are you sure you want to decline this friend request?") == true) {
            axios.put(apiUrl + '/friends_by_user_id/'+user.id+'/'+friend_user_id, {status: 2, rejected: moment()})
            .then((response) => {
                if(response.data) {
                    toast(response.data.msg, {
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        transition: Bounce
                    });

                    if(response.data.success == true) {
                        initData();
                    }
                }
            })
            .catch((error) => console.log('API error - ', error));
        }
    }

    return (
        <div className='m-2 mt-20 md:m-8'>
            <div className='max-w-lg m-auto text-white'>
                <div className='font-bold text-3xl mb-1'>Friends</div>
                <div className='font-bold'>Search for a user</div>
                <div className='relative mt-2'>
                    <div className='absolute top-0 left-0 p-3 h-14 flex items-center'><SearchIcon /></div>
                    <input className='text-white rounded-md border border-neutral-400 h-14 w-full p-2 pl-10 bg-transparent' name={searchTerm} onChange={(event) => setSearchTerm(event.target.value)} />
                    {
                        searchTerm && searchTerm != '' &&
                        <div className='absolute p-4 border border-white rounded-md bg-black w-full max-h-96 overflow-auto z-10'>
                            {
                                searchedList && searchedList.length > 0 &&
                                <div className=''>
                                    {searchedList.map((item, index) => (
                                        <Friend 
                                            item={item} 
                                            is_friend={((item.friend_from_user && item.friend_from_user?.status == 1) || (item.friend_to_user && item.friend_to_user?.status == 1))} 
                                            is_requested={(item.id != user.id && item.friend_from_user && item.friend_from_user?.to_user_id == user.id && item.friend_from_user?.status == 0)} 
                                            is_searched={true} 
                                            handleChallenge={handleChallenge}
                                            handleAddFriend={handleAddFriend}
                                            handleFriendAccept={handleFriendAccept}
                                            handleFriendDecline={handleFriendDecline}
                                            key={`friend-${index}`} 
                                        />
                                    ))}
                                </div>
                            }
                            {
                                searchedList && searchedList.length == 0 &&
                                <div className=''>No search results found.</div>
                            }
                        </div>
                    }
                </div>
                { 
                    requestData && requestData.length > 0 &&
                    <div className='pt-4'>
                        <div className='font-bold uppercase'>Friend Requests</div>
                        <div>
                            {requestData.map((item, index) => (
                                <Friend 
                                    item={item} 
                                    is_friend={false} 
                                    is_requested={true} 
                                    is_searched={false} 
                                    handleChallenge={handleChallenge} 
                                    handleAddFriend={handleAddFriend}
                                    handleFriendAccept={handleFriendAccept}
                                    handleFriendDecline={handleFriendDecline}
                                    key={`friend-${index}`} 
                                />
                            ))}
                        </div>
                    </div>
                }
                { /*
                    recentlyData && recentlyData.length > 0 &&
                    <div className='pt-4'>
                        <div className='font-bold uppercase'>Recently Played Against</div>
                        <div>
                            {recentlyData.map((item, index) => (
                                <Friend 
                                    item={item} 
                                    is_friend={true} 
                                    is_requested={false} 
                                    is_searched={false} 
                                    handleChallenge={handleChallenge} 
                                    handleAddFriend={handleAddFriend}
                                    handleFriendAccept={handleFriendAccept}
                                    handleFriendDecline={handleFriendDecline}
                                    key={`friend-${index}`} 
                                />
                            ))}
                        </div>
                    </div>
                */ }
                { 
                    allData && allData.length > 0 &&
                    <div className='pt-4'>
                        <div className='font-bold uppercase'>All</div>
                        <div>
                            {allData.map((item, index) => (
                                <Friend 
                                    item={item} 
                                    is_friend={true} 
                                    is_requested={false} 
                                    is_searched={false} 
                                    handleChallenge={handleChallenge} 
                                    handleAddFriend={handleAddFriend}
                                    handleFriendAccept={handleFriendAccept}
                                    handleFriendDecline={handleFriendDecline}
                                    key={`friend-${index}`} 
                                />
                            ))}
                        </div>
                    </div>
                }
                <ToastContainer />
            </div>
        </div>
    )
}

export default Friends