import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useStateContext } from '../contexts/ContextProvider';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { ToastContainer, Bounce, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CirclesWithBar } from 'react-loader-spinner'

import { numberWithCommas, handle7DecimalsOnValue } from '../config/common'
import moment from 'moment';
import Web3 from 'web3';

import { ReactComponent as TransferIcon } from '../assets/icons/icon-transfer.svg'
import { ReactComponent as BackIcon } from '../assets/icons/icon-back.svg'
import { ReactComponent as PencilIcon } from '../assets/icons/icon-pencil.svg'
import { ReactComponent as CheckIcon } from '../assets/icons/icon-checkmark.svg'
import { ReactComponent as ArrowBottomIcon } from '../assets/icons/icon-arrow-bottom.svg'

const Deposit = () => {

    
    let navigate = useNavigate();

    const { authToken } = useStateContext();
    if(!authToken) navigate('/login'); 
    const apiUrl = process.env.REACT_APP_API_URL;
    const centralWalletAddress = process.env.REACT_APP_CENTRAL_WALLET_ADDRESS;
    const chainId = process.env.REACT_APP_ETH_CHAIN_ID;
    const user = jwtDecode(authToken);

    const [walletData, setWalletData] = useState({}); 
    const [step, setStep] = useState(1); 
    const [depositAmount, setDepositAmount] = useState(''); 

    const [amountError, setAmountError] = useState(false); 
    const [isSending, setSending] = useState(false); 
    const [ethPrice, setEthPrice] = useState(0);  

    useEffect(() => {
        
        if(authToken) {
            axios.defaults.headers.common["Authorization"] = "Bearer " + authToken;
        } else {
            delete axios.defaults.headers.common["Authorization"];
        }

        axios.get(apiUrl + '/wallets/'+user.id)
        .then((response) => {
            if(response.data && response.data.success == true && response.data.data) {
                setWalletData(response.data.data);
            } else {
                axios.post(apiUrl + '/wallets/', {"user_id": user.id})
                .then((response) => {
                    if(response.data && response.data.success == true && response.data.data) {
                        setWalletData(response.data.data);
                    }
                })
            }
        })

        axios.get('https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd')
        .then((response) => {
            setEthPrice(response.data.ethereum.usd);
        })

    }, [])

    const handleGoToConfirmation = () => {
        if (!depositAmount || depositAmount == '') {
            setAmountError(true);
            return false;
        } else {
            setAmountError(false);
        }

        setStep(2);
    }

    async function handleDeposit() {
        
        let web3;
        if (window.ethereum) {
            try { 
                //await window.ethereum.request({ method: 'eth_requestAccounts' });
                await window.ethereum.request({ method: 'wallet_switchEthereumChain', params: [{ chainId: chainId }] });
                web3 = new Web3(window.ethereum);
            } catch(e) {
                console.log(e);
            }
        } else if (window.web3) {
            web3 = new Web3(window.web3.currentProvider);
        } else {
            toast('You have to install MetaMask!', {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce
            });
        }

        if (web3) {
            setSending(true);
            web3.eth.sendTransaction({
                from: (await web3.eth.getCoinbase()),
                to: centralWalletAddress,
                value: web3.utils.toWei(depositAmount.toString(), "ether"),
                gasLimit: 40000,
            })
            .on('receipt', function(receipt){
                var updatedAvailable = walletData.available*1 + depositAmount*1;
                axios.put(apiUrl + '/wallets/'+user.id, {"available": updatedAvailable, "last_deposited": moment()})
                .then((response) => {
                    if(response.data && response.data.success == true) {
                        axios.post(apiUrl + '/transactions', {"user_id": user.id, "amount": depositAmount, "reason": 0})
                        .then((response) => {})
                        .catch((error) => console.log('API error - ', error))

                        setSending(false);
                        setStep(3);
                    }
                })
                .catch((error) => console.log('API error - ', error))
            })
            .on('error', console.error); // If a out of gas error, the second parameter is the receipt.
        }
    }

    return (
        <div className='m-2 mt-20 md:m-8'>
            <div className='max-w-lg m-auto text-white'>
                <button onClick={()=>navigate(-1)} className='mb-3'>
                    <BackIcon />
                </button>
                {
                    step == 1 &&
                    <>
                        <div className="font-bold text-3xl">Deposit funds</div>
                        <div className='mt-3'>
                            <div className='flex items-center justify-between gap-3 rounded-lg bg-neutral-700 p-3'>
                                <div className='w-1/3'>
                                    <div className='text-3xl font-bold'>{walletData?.available > 0 ? numberWithCommas(walletData?.available) : 0} ETH</div>
                                    <div className='text-sm text-neutral-400 uppercase'>Available Balance</div>
                                    { ethPrice && <div className='text-sm text-neutral-400 uppercase'>(~{Math.round(walletData?.available * ethPrice * 100)/100} USD)</div> }
                                </div>
                                <div className='w-1/3'>
                                    <TransferIcon className='m-auto' />
                                </div>
                                <div className='w-1/3'>
                                    <div className='text-3xl font-bold'>{walletData?.escrow > 0 ? numberWithCommas(walletData?.escrow) : 0} ETH</div>
                                    <div className='text-sm text-neutral-400 uppercase'>Escrow Balance</div>
                                    { ethPrice && <div className='text-sm text-neutral-400 uppercase'>(~{Math.round(walletData?.escrow * ethPrice * 100)/100} USD)</div> }
                                </div>
                            </div>
                        </div>
                        <div className='mt-6'>
                            <div className='font-bold'>Amount to deposit</div>
                            <div className='relative mt-2'>
                                <div className='absolute top-0 left-0 p-3 h-14 flex items-center text-neutral-300'>ETH</div>
                                <input className={`text-white text-right rounded-md border-2 border-neutral-300 h-14 w-full p-2 pl-8 pr-4 bg-transparent ${amountError?'border-red-600':''}`} value={depositAmount} onChange={(event)=>setDepositAmount(handle7DecimalsOnValue(event.target.value))} placeholder='0.00' />
                                { depositAmount && <div className='mt-2 text-right text-sm text-neutral-400 uppercase'>(~{Math.round(depositAmount * ethPrice * 100)/100} USD)</div> }
                                <div className={`text-red-600 text-sm mt-2 ${amountError?'':'hidden'}`}>This field is required.</div>
                            </div>
                        </div>
                        <div className='mt-24'>
                            <div className="flex items-center gap-3">
                                <button onClick={() => handleGoToConfirmation()}
                                className="bg-white text-sm text-black p-3 w-full rounded-md border-2 border-white uppercase hover:drop-shadow-xl text-center"
                                >
                                    <div className='font-bold'>GO TO CONFIRMATION</div>
                                </button>
                            </div>
                        </div>
                    </>
                }
                {
                    step == 2 &&
                    <>
                        <div className="font-bold text-3xl">Confirm Funds Deposit</div>
                        <div className='mt-3 rounded-lg bg-neutral-700 p-4'>
                            <div className='flex items-center justify-between gap-3'>
                                <div className=''>
                                    <div className='text-sm text-neutral-400'>Amount to deposit</div>
                                    <div className='font-medium'>{numberWithCommas(depositAmount)} ETH</div>
                                </div>
                                <button onClick={()=>setStep(1)}>
                                    <PencilIcon />
                                </button>
                            </div>
                        </div>
                        <div className='mt-24'>
                            <div className="flex items-center gap-3">
                                <button onClick={handleDeposit} disabled={isSending}
                                className="bg-white text-sm text-black p-3 w-full rounded-md border-2 border-white uppercase hover:drop-shadow-xl text-center"
                                >
                                    <div className='font-bold'>CONFIRM AND DEPOSIT</div>
                                </button>
                            </div>
                        </div>
                    </>
                }
                {
                    step == 3 &&
                    <>
                        <div className="font-bold text-3xl">Your money is in!</div>
                        <div className='mt-3 rounded-lg bg-neutral-700 p-4 text-center'>
                            <div className='text-sm text-neutral-400'>You deposited</div>
                            <div className='font-bold text-3xl'>{numberWithCommas(depositAmount)} ETH</div>
                            <div className='text-sm text-neutral-400'>{ moment().format('DD MMM YYYY') }</div>
                            <div className='flex items-center justify-center gap-2 mt-3'>
                                <CheckIcon />
                                <div className='text-[#6FFF33] text-sm'>Funds received</div>
                            </div>
                        </div>
                        <div className='flex items-center justify-end gap-2 mt-3 text-right'>
                            <ArrowBottomIcon />
                            <div className='text-sm'>Receipt</div>
                        </div>
                        <div className='mt-24'>
                            <div className="flex items-center gap-3">
                                <Link
                                to='/wallet'
                                className="bg-white text-sm text-black p-3 w-full rounded-md border-2 border-white uppercase hover:drop-shadow-xl text-center"
                                >
                                    <div className='font-bold'>Wallet</div>
                                </Link>
                            </div>
                        </div>
                    </>
                }
                <ToastContainer />
                <div className='flex align-center justify-center z-100 mt-4'>
                    <CirclesWithBar
                        height={100}
                        width={100}
                        color="#ffffff"
                        outerCircleColor="#ffffff"
                        innerCircleColor="#ffffff"
                        barColor="#ffffff"
                        visible={isSending}
                    />
                </div>
            </div>
        </div>
    )
}

export default Deposit