import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useStateContext } from '../contexts/ContextProvider';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { ToastContainer, Bounce, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.module.css"

import { ReactComponent as BackIcon } from '../assets/icons/icon-back.svg'
import { ReactComponent as PhotoIcon } from '../assets/icons/icon-photo.svg'
import { ReactComponent as UserIcon } from '../assets/icons/icon-user.svg'

const Profile = () => {

    let navigate = useNavigate();

    const { authToken } = useStateContext();
    if(!authToken) navigate('/login'); 
    const apiUrl = process.env.REACT_APP_API_URL;
    const user = jwtDecode(authToken);
    
    const [userPhotoURL, setUserPhotoURL] = useState(''); 
    const [userPhoto, setUserPhoto] = useState(null); 
    const [fullName, setFullName] = useState(''); 
    const [username, setUsername] = useState(''); 
    const [email, setEmail] = useState(''); 
    const [dob, setDOB] = useState(''); 

    const [fullNameError, setFullNameError] = useState(''); 
    const [usernameError, setUsernameError] = useState(''); 
    const [emailError, setEmailError] = useState(''); 
    const [dobError, setdobError] = useState(''); 

    useEffect(() => {
        
        if(authToken) {
            axios.defaults.headers.common["Authorization"] = "Bearer " + authToken;
        } else {
            delete axios.defaults.headers.common["Authorization"];
        }

        axios.get(apiUrl + '/users/'+user.id)
        .then((response) => {
            if(response.data && response.data.success == true && response.data.data) {
                setUserPhotoURL(response.data.data.picture);
                setFullName(response.data.data.full_name);
                setUsername(response.data.data.username);
                setEmail(response.data.data.email);
                setDOB(new Date(response.data.data.birth_date));
            }
        })

	}, []);

    const handleSave = () => {
        if (!fullName || fullName == '') {
            setFullNameError(true);
            return false;
        } else {
            setFullNameError(false);
        }

        if (!username || username == '') {
            setUsernameError(true);
            return false;
        } else {
            setUsernameError(false);
        }

        if (!email || email == '') {
            setEmailError(true);
            return false;
        } else {
            setEmailError(false);
        }

        if (!dob || dob == '') {
            setdobError(true);
            return false;
        } else {
            setdobError(false);
        }

        var formData = new FormData();
        formData.append('full_name', fullName);
        formData.append('username', username);
        formData.append('email', email);
        formData.append('birth_date', dob);
        if(userPhoto) formData.append('file', userPhoto);

        axios.put(apiUrl + '/users/'+user.id, formData)
        .then((response) => {
            if(response.data) {
                toast(response.data.msg, {
                    position: "top-right",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce
                });
                console.log(response.data);
            }
        })
        .catch((error) => console.log('API error - ', error));
    }
    
    const setUserPhotoInfo = (newFile) => {
        setUserPhotoURL(URL.createObjectURL(newFile));
        console.log(URL.createObjectURL(newFile));
        setUserPhoto(newFile);
    }

    return (
        <div className='m-2 mt-20 md:m-8'>
            <div className='max-w-lg m-auto text-white'>
                <button onClick={()=>navigate(-1)} className='mb-3'>
                    <BackIcon />
                </button>
                <div className="font-bold text-3xl">Edit Personal details</div>
                <div className='text-center my-6'>
                    <div className="m-auto relative w-32">
                        { userPhotoURL ? <img className="border-4 rounded-md w-32 h-32 object-cover" src={userPhotoURL} /> : <UserIcon className="w-32 h-32" /> }
                        <label htmlFor='userPhoto' className="absolute top-6 right-[-18px]">
                            <PhotoIcon />
                        </label>
                        <input type="file" id="userPhoto" className='invisible' onChange={(event) => setUserPhotoInfo(event.target.files[0])} accept='image/*' />
                    </div>
                </div>
                <div className='mt-6'>
                    <div className='flex items-center justify-between'>
                        <div className='font-bold'>Full Name</div>
                    </div>
                    <div className='relative mt-2'>
                        <input className={`text-white rounded-md border border-neutral-400 h-14 w-full p-3 bg-zinc-800 ${fullNameError?'border-red-600':''}`} value={fullName} onChange={(event) => setFullName(event.target.value)} readonly="true" />
                        <div className={`text-red-600 text-sm mt-2 ${fullNameError?'':'hidden'}`}>This field is required.</div>
                    </div>
                </div>
                <div className='mt-6'>
                    <div className='flex items-center justify-between'>
                        <div className='font-bold'>Username</div>
                    </div>
                    <div className='relative mt-2'>
                        <input className={`text-white rounded-md border border-neutral-400 h-14 w-full p-3 bg-zinc-800 ${usernameError?'border-red-600':''}`} value={username} onChange={(event) => setUsername(event.target.value)} readonly="true" />
                        <div className={`text-red-600 text-sm mt-2 ${usernameError?'':'hidden'}`}>This field is required.</div>
                    </div>
                </div>
                <div className='mt-6'>
                    <div className='flex items-center justify-between'>
                        <div className='font-bold'>Email</div>
                    </div>
                    <div className='relative mt-2'>
                        <input className={`text-white rounded-md border border-neutral-400 h-14 w-full p-3 bg-transparent ${emailError?'border-red-600':''}`} type="email" value={email} onChange={(event) => setEmail(event.target.value)} />
                        <div className={`text-red-600 text-sm mt-2 ${emailError?'':'hidden'}`}>This field is required.</div>
                    </div>
                </div>
                <div className='mt-6'>
                    <div className='flex items-center justify-between'>
                        <div className='font-bold'>Date of Birth (21+)</div>
                    </div>
                    <div className='relative mt-2'>
                        { /*
                        <input className='text-white rounded-md border border-neutral-400 h-14 w-full p-3 bg-transparent' value={dob} onChange={(event) => setDOB(event.target.value)} />
                        */ }
                        <DatePicker placeholderText="MM / DD / YYYY" dateFormat={'MM / dd / yyyy'} selected={dob} onChange={(date) => setDOB(date)} className={`text-white rounded-md border border-neutral-400 h-14 w-full p-3 bg-transparent ${dobError?'border-red-600':''}`} />
                        <div className={`text-red-600 text-sm mt-2 ${dobError?'':'hidden'}`}>This field is required.</div>
                    </div>
                </div>
                <div className='mt-8'>
                    <div className="flex items-center gap-3">
                        <button onClick={() => handleSave() }
                        className="bg-white text-sm text-black p-3 w-full rounded-md border-2 border-white uppercase hover:drop-shadow-xl text-center"
                        >
                            <div className='font-bold'>Save Changes</div>
                        </button>
                    </div>
                </div>
                <ToastContainer />
            </div>
        </div>
    )
}

export default Profile