import { Outlet, Navigate, useNavigate } from "react-router-dom";
import { useStateContext } from '../contexts/ContextProvider'
import { useEffect } from "react";

const PrivateRoute = () => {
    const { authToken } = useStateContext()
    const navigate = useNavigate();

    useEffect(() => {
        authToken ? null : navigate('/');
    }, [authToken]);

    return <Outlet />;
}

export default PrivateRoute